import { Alert, Form, InputNumber, Radio, Row } from "antd";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { useUserType } from "features/auth";
import { useFetchDebtorAccountsQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import { selectPaymentsSlice, setPaymentIntentType } from "features/payments/paymentsSlice";
import { useFetchAccountSettlementPaymentRulesQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledHeader = styled.h4`
  font-weight: 500;
`;

function PaymentConfiguration() {
  const { isAgencyUserType } = useUserType();

  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const { debtorId } = useParams();
  const { data: agencyDebtorAccounts } = useFetchDebtorAccountsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDebtorAccounts } = useFetchCreditorPortalDebtorAccountsQuery(
    { debtorId },
    { skip: isAgencyUserType },
  );
  const debtorAccounts = isAgencyUserType ? agencyDebtorAccounts : creditorPortalDebtorAccounts;

  const paymentsSlice = useSelector(selectPaymentsSlice);
  const debtTotal = Object.values(paymentsSlice.selectedAccounts).reduce(
    (accumulator, selectedAccountId) => {
      const selectedAccount = debtorAccounts?.find(
        (debtorAccount) => debtorAccount.id === selectedAccountId,
      );
      return currency(accumulator, { precision: 4 }).add(selectedAccount?.totalBalance ?? 0);
    },
    0,
  );

  const { data: settlementPaymentRules } = useFetchAccountSettlementPaymentRulesQuery(
    {
      accountIds: paymentsSlice.selectedAccounts,
    },
    {
      skip: !paymentsSlice.selectedAccounts || paymentsSlice.selectedAccounts.length === 0,
    },
  );

  const minimumPaymentAmount = useMemo(
    () =>
      settlementPaymentRules?.reduce((max, rule) => {
        if (!rule.paymentRule?.minimumPaymentAmount) {
          return max;
        }
        const amount = currency(rule.paymentRule.minimumPaymentAmount, { precision: 4 }).value;
        return Math.max(max, amount);
      }, 0),
    [settlementPaymentRules],
  );

  const thresholdPercentage = useMemo(
    () =>
      settlementPaymentRules?.reduce((max, rule) => {
        if (!rule.settlementRule?.thresholdPercentage) {
          return max;
        }
        const amount = currency(rule.settlementRule.thresholdPercentage, { precision: 4 }).value;
        return Math.max(max, amount);
      }, 0),
    [settlementPaymentRules],
  );

  const onPaymentIntentTypeChange = (e) => {
    dispatch(setPaymentIntentType(e.target.value));
  };

  useEffect(() => {
    if (form) {
      // re-validate form if one of 3 things changes
      // 1. payment intent type
      // 2. minimum payment amount
      // 3. threshold percentage
      form.validateFields();
    }
  }, [form, paymentsSlice.paymentIntentType, minimumPaymentAmount, thresholdPercentage]);

  return (
    <>
      <Row>
        <Form.Item>
          <StyledHeader>Payment</StyledHeader>
          <Radio.Group onChange={onPaymentIntentTypeChange} value={paymentsSlice.paymentIntentType}>
            <Radio value="partial">Partial Payment</Radio>
            <Radio value="settlement">Settlement in Full</Radio>
            <Radio value="full">Pay in Full - {formatCurrency(debtTotal)}</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      {paymentsSlice.isPartialPayment || paymentsSlice.isSettlement ? (
        <>
          <Form.Item
            name="partialPaymentAmount"
            label={paymentsSlice.isPartialPayment ? "Partial Amount:" : "Settlement Amount:"}
            key="partialPaymentAmount"
            rules={[
              {
                required: paymentsSlice.isPartialPayment || paymentsSlice.isSettlement,
              },
              {
                validator: async (_, value) => {
                  // validate partial payment amount
                  if (paymentsSlice.isPartialPayment && value < minimumPaymentAmount) {
                    return Promise.reject(
                      new Error(
                        `Partial amount must be greater than $${
                          currency(minimumPaymentAmount, { precision: 2 }).value
                        }`,
                      ),
                    );
                  }
                  // validate minimum settlement threshold
                  if (paymentsSlice.isSettlement && value < thresholdPercentage * debtTotal) {
                    return Promise.reject(
                      new Error(
                        `Settlement amount must be greater than ${
                          thresholdPercentage * 100
                        }% treshold`,
                      ),
                    );
                  }
                  return Promise.resolve(true);
                },
              },
            ]}
          >
            <InputNumber prefix="$" controls={false} precision={2} min={0} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(previousValues, currentValues) =>
              previousValues.partialPaymentAmount !== currentValues.partialPaymentAmount
            }
          >
            {({ getFieldValue }) =>
              (paymentsSlice.isPartialPayment || paymentsSlice.isSettlement) &&
              getFieldValue("partialPaymentAmount") > debtTotal && (
                <>
                  <Alert
                    showIcon
                    type="warning"
                    message={`The payment amount $${
                      currency(getFieldValue("partialPaymentAmount"), { precision: 2 }).value
                    } is more than the total amount due of ${formatCurrency(debtTotal)}`}
                  />
                  <br />
                </>
              )
            }
          </Form.Item>
        </>
      ) : null}
    </>
  );
}

export default PaymentConfiguration;
