import { InputNumber } from "antd";
import currency from "currency.js";

export default function AktPercentageInput(props) {
  const { precision = 4 } = props;
  return (
    <InputNumber
      {...props}
      min="0"
      max="1" // 100%
      step="0.0001" // 0.01%
      addonAfter="%"
      stringMode
      // multiply by 100 to convert to percentage
      formatter={(value) => `${currency(value, { precision }).multiply(100).value}`}
      // divide by 100 to convert to decimal
      parser={(value) => currency(value, { precision }).divide(100).value}
    />
  );
}
