import { Input, Select, Tag } from "antd";

import {
  capitalizeFirstLetter,
  customFieldTypeFormatFunctions,
  getCustomFieldsItems,
} from "common/utils";
import { EditableCardComponent } from "components/editableCardComponent";
import CreditorCustomFields from "features/addCreditorWizard/wizardSteps/creditorCustomFields";
import {
  useFetchCreditorSummariesQuery,
  useFetchCreditorTagsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import {
  activeCollectorsWithRolesSelector,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchCreditorGroupQuery } from "features/mergeGroups/debtorMergeGroupAPI";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";
import { useFetchAllCreditorUdfCustomFieldsQuery } from "features/userDefinedFields/agencyPortal/creditorUserDefinedFieldsAPI";

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label ?? "";
  return fullOptionText.toLowerCase().includes(inputValue.toLowerCase());
};

export default function CreditorBasicInfoCard({ currentCreditor, onSave }) {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: creditorCustomFields } = useFetchAllCreditorUdfCustomFieldsQuery();
  const { data: creditorTags } = useFetchCreditorTagsQuery();
  const { data: creditorGroups } = useFetchCreditorGroupQuery();
  const { activeCollectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeCollectorsWithRoles: activeCollectorsWithRolesSelector(result),
    }),
  });
  const { data: productOfferings, isLoading: isProductOfferingsLoading } =
    useFetchProductOfferingsQuery();

  const rows = [
    {
      label: "Name",
      name: "name",
      formElement: <Input placeholder="Enter name ..." />,
      formItemProps: {
        rules: [{ required: true, message: "Name is required" }],
        maxLength: 50,
      },
    },
    {
      label: "Client Code",
      name: "code",
      formElement: <Input placeholder="Enter code (e.g 'CANTE')" />,
      formItemProps: {
        rules: [{ required: true, message: "Code is required" }],
      },
    },
    {
      label: "Referring Entity",
      name: "referringEntityName",
      formElement: <Input placeholder="Enter Referring Entity Name..." />,
      formItemProps: {},
    },
    {
      label: "CBR Class Codes",
      name: "cbrClassCodes",
      formElement: (
        <Select
          mode="multiple"
          placeholder="Select a Type..."
          options={constants?.cbrClassCodes.map((cbrClassCodeOption) => ({
            label: cbrClassCodeOption.display,
            value: cbrClassCodeOption.value,
          }))}
        />
      ),
      formItemProps: {
        tooltip: "The cbr class codes this client handles",
        rules: [{ required: true, message: "Please select cbr class code" }],
      },
      render: (value) => {
        const cbrClassCodes = constants?.cbrClassCodes.filter((x) => value?.includes(x.value));
        return cbrClassCodes?.map((cbrClassCode) => cbrClassCode.display).join(", ");
      },
    },
    {
      label: "Client Status",
      name: "status",
      formElement: (
        <Select
          placeholder="Select a status..."
          options={[
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
            {
              label: "Prospect",
              value: "prospect",
            },
          ]}
        />
      ),
      formItemProps: {
        rules: [{ required: true, message: "Please select a status" }],
        tooltip: "Status of the current creditor",
      },
      render: (value) => capitalizeFirstLetter(value),
    },
    {
      label: "Parent Company",
      name: "parentId",
      formElement: (
        <Select
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a Company..."
          options={creditors
            ?.filter((parent) => parent.id !== currentCreditor?.id)
            .map((creditor) => ({
              label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
              value: creditor.id,
            }))}
        />
      ),
      formItemProps: {
        tooltip: "Parent Company",
      },
      render: (value, record) => {
        const creditor = creditors?.find((x) => x.id === record.parentId);
        return creditor?.code ? `${creditor.name} (${creditor.code})` : creditor?.name;
      },
    },
    {
      label: "Sales Representative",
      name: "salesRepId",
      formElement: (
        <Select
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a sales rep..."
          options={collectors?.map((user) => ({
            label: collectorFullName(user),
            value: user.id,
          }))}
        />
      ),
      formItemProps: {},
      render: (value) => {
        const collector = collectors?.find((x) => x.id === value);
        return collectorFullName(collector);
      },
    },
    {
      label: "Relationship Start Date",
      name: "relationshipStartDate",
      formElement: <Input type="date" />,
      formItemProps: {
        tooltip: "Relationship start date",
      },
    },
    {
      label: "Client Group",
      name: "debtorMergeGroupId",
      formElement: (
        <Select
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a Client Group..."
          options={creditorGroups?.map((creditorGroup) => ({
            label: creditorGroup.groupName,
            value: creditorGroup.id,
          }))}
        />
      ),
      formItemProps: {
        tooltip:
          "Client group is used to merge debtor accounts with other clients from the same group.",
      },
      render: (value) => {
        const creditorGroup = creditorGroups?.find((x) => x.id === value);
        return creditorGroup?.groupName;
      },
    },
    {
      label: "User Defined Fields (Client)",
      name: "creditorCustomFieldIds",
      formElement: <CreditorCustomFields />,
      render: () => {
        const customFieldsValueFieldPair = getCustomFieldsItems(
          currentCreditor?.customFields,
          creditorCustomFields,
        );
        return customFieldsValueFieldPair
          .map((valueAndField) => {
            const { value, field } = valueAndField;
            const formatFunction = customFieldTypeFormatFunctions[field.type];
            if (value === null || value === undefined) {
              return `${field.name} (no value)`;
            }
            const creditorCustomFieldValue = formatFunction ? formatFunction(value) : value;
            return `${field.name}: (${creditorCustomFieldValue})`;
          })
          .join(", ");
      },
    },
    {
      label: "Tags",
      name: "tagIds",
      formElement: (
        <Select
          mode="multiple"
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select tags..."
          options={creditorTags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
          }))}
        />
      ),
      render: (value) => {
        const tags = creditorTags?.filter((x) => value?.includes(x.id));
        return tags?.map((tag) => <Tag>{tag.name}</Tag>);
      },
    },
    {
      label: "Product",
      name: "productOfferingIds",
      render: (value) => {
        const offerings = productOfferings?.filter((x) => value?.includes(x.id));
        return offerings?.map((productOffering) => <Tag>{productOffering.name}</Tag>);
      },
      formElement: (
        <Select
          placeholder="Select products..."
          mode="multiple"
          options={productOfferings?.map((product) => ({
            value: product.id,
            label: product.name,
          }))}
          loading={isProductOfferingsLoading}
        />
      ),
      formItemProps: {
        rules: [{ required: true, message: "Please select products" }],
      },
    },
    {
      label: "Notes",
      name: "notes",
      formElement: <Input.TextArea placeholder="Enter Notes..." />,
    },
  ];

  return (
    <EditableCardComponent
      title="Basic Info"
      record={currentCreditor}
      rows={rows}
      onSave={onSave}
    />
  );
}
