import {
  FileSearchOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlusOutlined,
  SendOutlined,
  SettingOutlined,
  NotificationOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Layout, Modal, Row, Skeleton, Spin, Tooltip } from "antd";
import { TriggerModal } from "components/triggerModal";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { SIDER_ITEMS } from "features/appearance/constants";
import { useFetchMeQuery } from "features/auth/authAPI";
import DebtorNavigationArrow from "features/debtorProfile/components/debtorNavigationArrow";
import {
  useFetchDebtorAddressesQuery,
  useFetchDebtorContactsQuery,
  useFetchDebtorEmailsQuery,
  useFetchDebtorEmploymentsQuery,
  useFetchDebtorPhoneNumbersQuery,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { DebtorAddressInfoCard } from "features/debtorSider/components/debtorAddressInfoCard";
import { DebtorContactInfoCard } from "features/debtorSider/components/debtorContactInfoCard";
import { DebtorEmailInfoCard } from "features/debtorSider/components/debtorEmailInfoCard";
import { DebtorEmploymentInfoCard } from "features/debtorSider/components/debtorEmploymentInfoCard";
import { DebtorInfoCard } from "features/debtorSider/components/debtorInfoCard";
import { DebtorNotesCard } from "features/debtorSider/components/debtorNotesCard";
import { DebtorPhoneInfoCard } from "features/debtorSider/components/debtorPhoneInfoCard";
import { DebtorTagsCard } from "features/debtorSider/components/debtorTagsCard";
import PerformSearchModal from "features/debtorSider/components/performSearchModal";
import SendEmailModal from "features/debtorSider/components/sendEmailModal";
import SendTextModal from "features/debtorSider/components/sendTextModal";
import SendVoicemailModal from "features/debtorSider/components/sendVoicemailModal";
import SendLetterSequenceModal from "features/debtorSider/components/sendLetterSequenceModal";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useAppearance } from "providers/appearanceProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SimilarDebtorsCard from "features/debtorSider/components/similarDebtorsCard";
import CallAiModal from "features/debtorSider/components/callAiModal";

const StyledSider = styled(Layout.Sider)`
  padding: 4px;
  overflow: scroll;
  left: 0px;
  top: 196px;
  bottom: 0px;
  &&& {
    position: fixed;
    background: #fff;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: #0b3948;
`;

const StyledDebtorName = styled.h2`
  text-align: center;
  text-transform: capitalize;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 220px;
`;

const StyledNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
  width: 260px;
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-left: 8px;
`;

const StyledHeaderName = styled.h4`
  margin-left: 8px;
`;

const StyledContainer = styled.div`
  position: fixed;
  width: 400px;
  left: 0;
  padding-top: 0px;
  background: #fff;
  min-height: 124px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0;
  margin-top: 11px;
`;

const StyledInfoContainer = styled.div``;

export const SIDER_ID = "debtorSider";

function DebtorSider() {
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const { debtorId } = useParams();
  const [newPhoneForm, setNewPhoneForm] = useState(null);
  const [newAddressForm, setNewAddressForm] = useState(null);
  const [newEmploymentForm, setNewEmploymentForm] = useState(null);
  const [newEmailForm, setNewEmailForm] = useState(null);
  const [newDebtorContactForm, setNewDebtorContactForm] = useState(null);
  const { isLoading: isAppearanceLoading, appearance } = useAppearance();
  const { data: me } = useFetchMeQuery();

  const {
    data: debtorPersonalInfo,
    isLoading: isDebtorPersonalInfoLoading,
    isFetching: isDebtorPersonalInfoFetching,
    isError: isDebtorPersonalInfoError,
  } = useFetchDebtorProfileQuery({ debtorId });
  const { data: debtorAddresses, isLoading: isDebtorAddressesLoading } =
    useFetchDebtorAddressesQuery(
      { debtorId },
      {
        skip:
          isDebtorPersonalInfoLoading || isDebtorPersonalInfoError || isDebtorPersonalInfoFetching,
      },
    );
  const { data: debtorContacts, isLoading: isDebtorContactsLoading } = useFetchDebtorContactsQuery(
    {
      debtorId,
    },
    {
      skip:
        isDebtorPersonalInfoLoading || isDebtorPersonalInfoError || isDebtorPersonalInfoFetching,
    },
  );
  const { data: debtorEmails, isLoading: isDebtorEmailsLoading } = useFetchDebtorEmailsQuery(
    {
      debtorId,
    },
    {
      skip:
        isDebtorPersonalInfoLoading || isDebtorPersonalInfoError || isDebtorPersonalInfoFetching,
    },
  );
  const { data: debtorPhoneNumbers, isLoading: isDebtorPhoneNumbersLoading } =
    useFetchDebtorPhoneNumbersQuery(
      { debtorId },
      {
        skip:
          isDebtorPersonalInfoLoading || isDebtorPersonalInfoError || isDebtorPersonalInfoFetching,
      },
    );
  const { data: debtorEmployments, isLoading: isDebtorEmploymentsLoading } =
    useFetchDebtorEmploymentsQuery(
      { debtorId },
      {
        skip:
          isDebtorPersonalInfoLoading || isDebtorPersonalInfoError || isDebtorPersonalInfoFetching,
      },
    );

  const {
    data: {
      [PERMISSIONS.PHONE__CALL_DEBTOR]: isPhoneAuthorized,

      [PERMISSIONS.PHONE__CALL_CREDITOR]: isContactCreditorAuthorized,
      [PERMISSIONS.SKIP_TRACE__START]: isSkipTraceAuthorized,
      [PERMISSIONS.DEBTOR_ADDRESS__CREATE]: isAddDebtorAddressAuthorized,
      [PERMISSIONS.DEBTOR_CONTACT__CREATE]: isAddDebtorContactAuthorized,
      [PERMISSIONS.DEBTOR_EMPLOYMENT__CREATE]: isAddDebtorEmploymentAuthorized,
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__CREATE]: isAddDebtorPhoneNumberAuthorized,
      [PERMISSIONS.DEBTOR_EMAIL__CREATE]: isAddDebtorEmailAuthorized,
      [PERMISSIONS.DEBTOR_ADDRESS__GET]: isGetDebtorAddressAuthorized,
      [PERMISSIONS.DEBTOR_CONTACT__GET]: isGetDebtorContactAuthorized,
      [PERMISSIONS.DEBTOR_EMPLOYMENT__GET]: isGetDebtorEmploymentAuthorized,
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__GET]: isGetDebtorPhoneNumberAuthorized,
      [PERMISSIONS.DEBTOR_EMAIL__GET]: isGetDebtorEmailAuthorized,
      [PERMISSIONS.LETTER__SEND]: isLetterAuthorized,
      [PERMISSIONS.EMAIL__SEND]: isEmailAuthorized,
      [PERMISSIONS.TEXT_MESSAGE__SEND]: isTextAuthorized,
      [PERMISSIONS.TEXT_MESSAGE__SEND]: isVoiceMailAuthorized, // TODO: Add specific permission for voicemail
      [PERMISSIONS.APPEARANCE__UPDATE]: isAppearanceUpdateAuthorized,
      isLoadingPermissionSummary,
    },
  } = useAuthorizations();

  const isLoading =
    isDebtorPersonalInfoLoading ||
    isDebtorAddressesLoading ||
    isDebtorContactsLoading ||
    isDebtorPhoneNumbersLoading ||
    isDebtorEmploymentsLoading ||
    isDebtorEmailsLoading ||
    isAppearanceLoading ||
    isLoadingPermissionSummary;

  const debtorDisplayName = [
    debtorPersonalInfo?.firstName,
    debtorPersonalInfo?.middleName,
    debtorPersonalInfo?.lastName,
  ]
    .filter((x) => x)
    .join(" ");

  const handleCreatePhone = async () => {
    setNewPhoneForm(null);
  };
  const onCancelCreatePhone = () => {
    setNewPhoneForm(null);
  };
  const onAddPhoneClick = () => {
    setNewPhoneForm(
      <DebtorPhoneInfoCard isNew onCreate={handleCreatePhone} onCancel={onCancelCreatePhone} />,
    );
  };

  const handleCreateAddress = async () => {
    setNewAddressForm(null);
  };
  const onCancelCreateAddress = () => {
    setNewAddressForm(null);
  };
  const onAddAddressClick = () => {
    setNewAddressForm(
      <DebtorAddressInfoCard
        isNew
        onCreate={handleCreateAddress}
        onCancel={onCancelCreateAddress}
      />,
    );
  };

  const handleCreateEmail = async () => {
    setNewEmailForm(null);
  };
  const onCancelCreateEmail = () => {
    setNewEmailForm(null);
  };
  const onAddEmailClick = () => {
    setNewEmailForm(
      <DebtorEmailInfoCard isNew onCreate={handleCreateEmail} onCancel={onCancelCreateEmail} />,
    );
  };

  const handleCreateEmployment = async () => {
    setNewEmploymentForm(null);
  };
  const onCancelCreateEmployment = () => setNewEmploymentForm(null);
  const onAddEmploymentClick = () =>
    setNewEmploymentForm(
      <DebtorEmploymentInfoCard
        isNew
        onCreate={handleCreateEmployment}
        onCancel={onCancelCreateEmployment}
      />,
    );

  const handleCreateDebtorContact = async () => {
    setNewDebtorContactForm(null);
  };
  const onCancelCreateDebtorContact = () => setNewDebtorContactForm(null);
  const onAddDebtorContactClick = () => {
    setNewDebtorContactForm(
      <DebtorContactInfoCard
        isNew
        profileType={debtorPersonalInfo?.type}
        onCreate={handleCreateDebtorContact}
        onCancel={onCancelCreateDebtorContact}
      />,
    );
  };

  const emailOnOk = async () => {
    setModalContent(null);
    setModalTitle("");
  };

  const aiDialerOnOk = async () => {
    setModalContent(null);
    setModalTitle("");
  };

  const letterOnOk = async () => {
    setModalContent(null);
    setModalTitle("");
  };

  const voicemailOnOk = async () => {
    setModalContent(null);
    setModalTitle("");
  };

  const onCancel = () => {
    setModalContent(null);
    setModalTitle("");
  };

  const showModalContentFor = (key, title) => {
    setModalTitle(title);
    if (key === "letter") {
      setModalContent(
        <SendLetterSequenceModal
          title="Send Letter"
          open={modalContent || key}
          onOk={letterOnOk}
          onCancel={onCancel}
        />,
      );
    } else if (key === "phone") {
      setModalContent(
        <Modal
          title="Phone"
          open={modalContent || key}
          onOk={() => {
            setModalContent(null);
            setModalTitle("");
          }}
          onCancel={onCancel}
        >
          Coming Soon
        </Modal>,
      );
    } else if (key === "text") {
      setModalContent(
        <Modal
          title="Text Message"
          open={modalContent || key}
          onOk={() => {
            setModalContent(null);
            setModalTitle("");
          }}
          onCancel={onCancel}
        >
          <SendTextModal
            title="Send Text Message"
            open={modalContent || key}
            onOk={emailOnOk}
            onCancel={onCancel}
          />
          ,
        </Modal>,
      );
    } else if (key === "voicemail") {
      setModalContent(
        <Modal
          title="Voicemail"
          open={modalContent || key}
          onOk={() => {
            setModalContent(null);
            setModalTitle("");
          }}
          onCancel={onCancel}
        >
          <SendVoicemailModal
            title="Send Voicemail"
            open={modalContent || key}
            onOk={voicemailOnOk}
            onCancel={onCancel}
          />
          ,
        </Modal>,
      );
    } else if (key === "email") {
      setModalContent(
        <SendEmailModal
          title="Send Email"
          open={modalContent || key}
          onOk={emailOnOk}
          onCancel={onCancel}
        />,
      );
    } else if (key === "aiDialer") {
      setModalContent(
        <CallAiModal
          title="Call With Aktos AI"
          open={modalContent || key}
          onOk={aiDialerOnOk}
          onCancel={onCancel}
        />,
      );
    } else if (key === "creditor") {
      setModalContent(
        <Modal
          title="Phone"
          open={modalContent || key}
          onOk={() => {
            setModalContent(null);
            setModalTitle("");
          }}
          onCancel={onCancel}
        >
          Coming Soon
        </Modal>,
      );
    } else if (key === "search") {
      setModalContent(
        <PerformSearchModal
          title={modalTitle}
          open={modalContent || key}
          onOk={() => {
            setModalContent(null);
            setModalTitle("");
          }}
          onCancel={onCancel}
        />,
      );
    }
  };

  const defaultItems = [
    {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_NOTES,
      visible: true,
      title: "Notes",
      render: () => <DebtorNotesCard />,
    },
    {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_TAGS,
      visible: true,
      title: "Tags",
      render: () => <DebtorTagsCard />,
    },

    {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_INFO,
      visible: true,
      title: "Debtor Info",
      render: () => <DebtorInfoCard profileType={debtorPersonalInfo?.type} />,
    },
    isGetDebtorPhoneNumberAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__PHONE_NUMBERS,
      visible: true,
      title: "Phone Numbers",
      render: (item) => (
        <span key={item.key}>
          {debtorPhoneNumbers?.map((phone, index) => (
            <DebtorPhoneInfoCard
              key={index}
              phone={phone}
              profileType={debtorPersonalInfo?.type}
              title={index === 0 ? "Phone" : `Phone (${index + 1})`}
            />
          ))}
          {newPhoneForm || (
            <>
              {debtorPhoneNumbers?.length === 0 && <StyledHeaderName>Phones</StyledHeaderName>}
              {isAddDebtorPhoneNumberAuthorized && (
                <StyledButton type="primary" icon={<PlusOutlined />} onClick={onAddPhoneClick}>
                  Add Phone number
                </StyledButton>
              )}
            </>
          )}
        </span>
      ),
    },
    isGetDebtorEmailAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_EMAILS,
      visible: true,
      title: "Emails",
      render: (item) => (
        <span key={item.key}>
          {debtorEmails?.map((email, index) => (
            <DebtorEmailInfoCard
              key={email.id}
              profileType={debtorPersonalInfo?.type}
              email={email}
              title={index === 0 ? "Email" : `Email (${index + 1})`}
            />
          ))}
          {newEmailForm || (
            <>
              {debtorEmails?.length === 0 && <StyledHeaderName>Email</StyledHeaderName>}
              {isAddDebtorEmailAuthorized && (
                <StyledButton type="primary" icon={<PlusOutlined />} onClick={onAddEmailClick}>
                  Add Email
                </StyledButton>
              )}
            </>
          )}
        </span>
      ),
    },
    isGetDebtorAddressAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_ADDRESSES,
      visible: true,
      title: "Addresses",
      render: (item) => (
        <span key={item.key}>
          {debtorAddresses?.map((address, index) => (
            <DebtorAddressInfoCard
              key={index}
              profileType={debtorPersonalInfo?.type}
              address={address}
              title={index === 0 ? "Address" : `Address (${index + 1})`}
            />
          ))}
          {newAddressForm || (
            <>
              {debtorAddresses?.length === 0 && <StyledHeaderName>Address</StyledHeaderName>}
              {isAddDebtorAddressAuthorized && (
                <StyledButton type="primary" icon={<PlusOutlined />} onClick={onAddAddressClick}>
                  Add Address
                </StyledButton>
              )}
            </>
          )}
        </span>
      ),
    },
    isGetDebtorContactAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_CONTACTS,
      visible: true,
      title: "Contacts",
      render: (item) => (
        <span key={item.key}>
          {debtorContacts
            ?.filter((contact) => contact.relationshipToDebtor !== "debtor" && !contact.isArchived)
            ?.map((debtorContact, index) => (
              <DebtorContactInfoCard
                key={index}
                profileType={debtorPersonalInfo?.type}
                debtorContact={debtorContact}
                title={
                  index === 0
                    ? `${
                        debtorPersonalInfo?.type === "consumer"
                          ? "Debtor Contact"
                          : "Contact Person"
                      }`
                    : `${
                        debtorPersonalInfo?.type === "consumer"
                          ? `Debtor Contact (${index + 1})`
                          : `Contact Person (${index + 1})`
                      }`
                }
              />
            ))}
          {newDebtorContactForm || (
            <>
              {debtorContacts?.filter(
                (contact) => contact.relationshipToDebtor !== "debtor" && !contact.isArchived,
              )?.length === 0 && (
                <StyledHeaderName>
                  {debtorPersonalInfo?.type === "consumer" ? "Debtor Contact" : "Contact Person"}
                </StyledHeaderName>
              )}
              {isAddDebtorContactAuthorized && (
                <StyledButton
                  type="primary"
                  onClick={onAddDebtorContactClick}
                  icon={<PlusOutlined />}
                >
                  Add{" "}
                  {debtorPersonalInfo?.type === "consumer" ? "Debtor Contact" : "Contact Person"}
                </StyledButton>
              )}
            </>
          )}
        </span>
      ),
    },
    isGetDebtorEmploymentAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_EMPLOYMENTS,
      visible: true,
      title: "Employments",
      render: (item) => (
        <span key={item.key}>
          {debtorPersonalInfo?.type === "consumer" &&
            debtorEmployments?.map((employment, index) => (
              <DebtorEmploymentInfoCard
                key={index}
                employment={employment}
                title={index === 0 ? "Employment" : `Employment (${index + 1})`}
              />
            ))}
          {newEmploymentForm || (
            <>
              {debtorPersonalInfo?.type === "consumer" && debtorEmployments?.length === 0 && (
                <StyledHeaderName>Employment</StyledHeaderName>
              )}
              {debtorPersonalInfo?.type === "consumer" && isAddDebtorEmploymentAuthorized && (
                <StyledButton type="primary" icon={<PlusOutlined />} onClick={onAddEmploymentClick}>
                  Add Employment
                </StyledButton>
              )}
            </>
          )}
        </span>
      ),
    },
  ].filter(Boolean); // .filter(Boolean) is a shortcut for .filter(item => !item)

  const reconciledItems = reconcileColumns(defaultItems, appearance?.items?.[SIDER_ID]).filter(
    (column) => column.visible,
  );

  if (
    appearance?.items?.[SIDER_ID]?.hidePanel || // Hiding panel in appearances
    isAppearanceLoading || // Appearance is still loading
    isDebtorPersonalInfoError // Debtor personal info failed to load
  ) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        {!isDebtorPersonalInfoLoading && !isDebtorPersonalInfoError && (
          <StyledNavigationContainer>
            <DebtorNavigationArrow direction="left" disabled={isLoading} />
            <StyledProfileContainer>
              <Row align="middle" wrap={false}>
                <StyledAvatar size={40}>
                  {(debtorDisplayName || debtorPersonalInfo?.companyName)
                    ?.split(" ")
                    .map((c) => c[0])}
                </StyledAvatar>
                <Tooltip
                  title={debtorDisplayName || debtorPersonalInfo?.companyName}
                  placement="right"
                >
                  <StyledDebtorName>
                    {(debtorDisplayName || debtorPersonalInfo?.companyName)?.toLowerCase()}
                  </StyledDebtorName>
                </Tooltip>
                {isAppearanceUpdateAuthorized && (
                  <TriggerModal
                    modal={TableAppearanceModal}
                    tableId={SIDER_ID}
                    defaultColumns={defaultItems}
                    mode="sider"
                  >
                    <Tooltip title="Customize Appearance">
                      <Button shape="circle" icon={<SettingOutlined />} type="text" />
                    </Tooltip>
                  </TriggerModal>
                )}
              </Row>
              <StyledRow align="middle" justify="space-around">
                {isPhoneAuthorized && (
                  <Tooltip title="Phone">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PhoneOutlined />}
                      onClick={() => showModalContentFor("phone")}
                    />
                  </Tooltip>
                )}
                {isEmailAuthorized && (
                  <Tooltip title="Email">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<MailOutlined />}
                      onClick={() => showModalContentFor("email")}
                    />
                  </Tooltip>
                )}
                {isLetterAuthorized && (
                  <Tooltip title="Letter">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<SendOutlined />}
                      onClick={() => showModalContentFor("letter")}
                    />
                  </Tooltip>
                )}
                {isTextAuthorized && (
                  <Tooltip title="Text Message">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<MessageOutlined />}
                      onClick={() => showModalContentFor("text")}
                    />
                  </Tooltip>
                )}
                {isVoiceMailAuthorized && (
                  <Tooltip title="Voicemail">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<NotificationOutlined />}
                      onClick={() => showModalContentFor("voicemail")}
                    />
                  </Tooltip>
                )}
                {isPhoneAuthorized && (
                  <Tooltip title="AI Phone Agent">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<RobotOutlined />}
                      onClick={() => showModalContentFor("aiDialer")}
                    />
                  </Tooltip>
                )}
                {isSkipTraceAuthorized && (
                  <Tooltip title="Search on debtor">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<FileSearchOutlined />}
                      onClick={() => showModalContentFor("search")}
                    />
                  </Tooltip>
                )}
              </StyledRow>
            </StyledProfileContainer>
            <DebtorNavigationArrow direction="right" disabled={isLoading} />
          </StyledNavigationContainer>
        )}
        <StyledDivider />
      </StyledContainer>
      <StyledSider width={400} theme="light">
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Spin spinning={isDebtorPersonalInfoFetching}>
            <StyledInfoContainer>
              <SimilarDebtorsCard currentDebtor={debtorPersonalInfo} />
              {reconciledItems.map((item, idx) => (
                <div key={idx}>
                  {item.render(item)}
                  <StyledDivider />
                </div>
              ))}
              {modalContent}
              {/* <Sider width={400} style={{ padding: "15px", color: "#fff" }}>
          <Form
            //   style={{ background: "#fff" }}
            form={form}
            {...formItemLayout}
            labelAlign="left"
            validateMessages={{ required: "This is a required field" }}
          >
            <Form.Item name="cbrClassCodes" label="CBR Class Codes">
              <Select mode="multiple">
                {constants?.cbrClassCodes.map((d) => (
                  <Option key={d.value} value={d.value}>
                    {d.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="debtorStates" label="States">
              <Select mode="multiple">
                {constants?.states.map((state) => (
                  <Option key={state.value} value={state.value}>
                    {state.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="alpha1"
              label="Alphabetical (From)"
              rules={[
                {
                  pattern: /[A-Z]{1}/,
                  message: "Must be a single character from A-Z",
                },
              ]}
            >
              <Input maxLength={1} />
            </Form.Item>
            <Form.Item
              name="alpha2"
              label="Alphabetical (To)"
              rules={[
                {
                  pattern: /[A-Z]{1}/,
                  message: "Must be a single character from A-Z",
                },
              ]}
            >
              <Input maxLength={1} />
            </Form.Item>
            <Form.Item name="dollars1" label="Account Balance (From)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="dollars2" label="Account Balance (To)">
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="age1"
              label="Days Since Upload (From)"
              rules={[
                {
                  pattern: /[0-9]{2}/,
                  message: "Enter a valid age",
                },
              ]}
            >
              <InputNumber maxLength={2} />
            </Form.Item>
            <Form.Item
              name="age2"
              label="Days Since Upload (To)"
              rules={[
                {
                  pattern: /[0-9]{2}/,
                  message: "Enter a valid age",
                },
              ]}
            >
              <InputNumber maxLength={2} />
            </Form.Item>
            <Form.Item name="creditorIds" label="Clients">
              <Select mode="multiple">
                {creditorsSlice.creditors.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="languages" label="Languages">
              <Select mode="multiple">
                {constants?.languages.map((l) => (
                  <Option key={l.value} value={l.value}>
                    {l.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Sider> */}
            </StyledInfoContainer>
          </Spin>
        )}
      </StyledSider>
    </>
  );
}

export default DebtorSider;
