import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, message, Popconfirm } from "antd";
import styled from "styled-components";
import { toTitleCase } from "common/utils";
import {
  useGetPaymentMethodOptionsQuery,
  useLazyGetPaymentIntentsQuery,
  useArchivePaymentMethodMutation,
} from "features/payments/paymentsAPI";
import { PAYMENT_INTENT_STATUS } from "common/constants";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

const { confirm } = Modal;

const StyledTable = styled(Table)`
  white-space: pre;

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }
`;

const ExpiredTag = styled(Tag)`
  margin-left: 8px;
`;

export default function PaymentMethodsTable() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { debtorId } = useParams();
  const {
    data: paymentMethods,
    isLoading: isLoadingPaymentMethods,
    refetch,
  } = useGetPaymentMethodOptionsQuery({ debtorId });
  const [checkPaymentIntents, { isLoading: isFetchingPaymentIntents }] =
    useLazyGetPaymentIntentsQuery();
  const [archivePaymentMethod, { isLoading: isArchivePaymentMethodLoading }] =
    useArchivePaymentMethodMutation();

  const handleArchivePaymentMethod = async (
    paymentMethodId,
    deleteScheduledPaymentIntents = true,
  ) => {
    const result = await archivePaymentMethod({
      debtorId,
      paymentMethodId,
      deleteScheduledPaymentIntents,
    });

    if ("data" in result) {
      message.success("Payment method archived successfully");
      refetch();
    }
  };

  // Check if a card is expired
  const isCardExpired = (cardExpMonth, cardExpYear) => {
    if (!cardExpMonth || !cardExpYear) return false;

    const currentDate = moment();
    const expiryDate = moment(`${cardExpYear}-${cardExpMonth}-01`, "YYYY-MM-DD").endOf("month");

    return currentDate.isAfter(expiryDate);
  };

  const columns = [
    {
      title: "Payment Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        if (text === "process_card") return "Card";
        if (text === "process_ach") return "ACH";
        return toTitleCase({ str: text, delimiter: "_" });
      },
    },
    {
      title: "Details",
      key: "details",
      render: (_, record) => {
        if (record.type === "process_card") {
          const expired = isCardExpired(record.cardExpMonth, record.cardExpYear);
          return (
            <span>
              Card ending in {record.cardLast4Digits}
              {record.cardExpMonth && record.cardExpYear && (
                <span>
                  {" "}
                  ({record.cardExpMonth}/{record.cardExpYear.toString().slice(-2)})
                </span>
              )}
              {expired && <ExpiredTag color="red">EXPIRED</ExpiredTag>}
            </span>
          );
        }
        if (record.type === "process_ach") {
          return `ACH ending in ${record.bankAccountLast4Digits}`;
        }
        return "-";
      },
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? moment(text).format("MM/DD/YYYY") : "-"),
      sorter: (a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
    },
    {
      title: "Data Source",
      dataIndex: "dataSource",
      render: (text, record) =>
        constants?.editableDataSourceTypes?.find((x) => x.value === record.dataSource)?.display ??
        "-",
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      render: (_, record) => {
        const handleConfirm = async () => {
          // After user confirms in Popconfirm, check for scheduled payments
          const { data: activePaymentIntents } = await checkPaymentIntents({
            debtorId,
            payment_method_ids: [record.id],
            status: PAYMENT_INTENT_STATUS.NEW,
          });

          if (activePaymentIntents?.length > 0) {
            // Show warning modal for scheduled payments
            confirm({
              title: "Warning: Scheduled Payments Found",
              content:
                "You are deleting a payment method that is being used in future payments. Deleting this method will also cancel those future payments which will need to be recreated with a valid payment method. Are you sure you want to proceed?",
              okText: "Proceed to delete",
              okType: "danger",
              cancelText: "Cancel",
              onOk() {
                handleArchivePaymentMethod(record.id, true);
              },
            });
          } else {
            // No scheduled payments, proceed with deletion
            handleArchivePaymentMethod(record.id, false);
          }
        };

        return (
          <Popconfirm
            title="Are you sure you want to delete this payment method?"
            cancelText="Cancel"
            onConfirm={handleConfirm}
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              loading={isArchivePaymentMethodLoading || isFetchingPaymentIntents}
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <StyledTable
      loading={isLoadingPaymentMethods}
      columns={columns}
      dataSource={paymentMethods}
      rowKey="id"
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30, 100],
      }}
    />
  );
}
