import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Select,
  Space,
  Spin,
} from "antd";
import { debounce, formatCurrency, formatDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useBulkCreatePaymentIntentsMutation } from "features/payments/paymentsAPI";
import {
  getNextView,
  getPreviousView,
  selectPaymentsSlice,
  setPaymentPlanConfiguration,
} from "features/payments/paymentsSlice";
import { useFetchAccountSettlementPaymentRulesQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  // max-width: 400px;
`;

const BottomSpacedStrong = styled(Layout.Content)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledDiv = styled.div`
  margin-bottom: 16px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
`;

const StyledCheckbox = styled(Checkbox)`
  white-space: nowrap;
  margin-bottom: 16px;
`;

function SetupPaymentPlanConfiguration() {
  const { debtorId } = useParams();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const tomorrowDate = moment().startOf("day");
  const [form] = Form.useForm();
  const [bulkCreatePaymentIntents, { isLoading: isBulkCreatePaymentIntentsLoading }] =
    useBulkCreatePaymentIntentsMutation();
  const { data: settlementPaymentRules } = useFetchAccountSettlementPaymentRulesQuery(
    {
      accountIds: paymentsSlice.selectedAccounts,
    },
    {
      skip: !paymentsSlice.selectedAccounts || paymentsSlice.selectedAccounts.length === 0,
    },
  );

  const minimumPaymentAmount = useMemo(
    () =>
      settlementPaymentRules?.reduce((max, rule) => {
        if (!rule.paymentRule?.minimumPaymentAmount) {
          return max;
        }
        const amount = currency(rule.paymentRule.minimumPaymentAmount, { precision: 4 }).value;
        return Math.max(max, amount);
      }, 0),
    [settlementPaymentRules],
  );

  const maximumPlanPaymentCount = useMemo(
    () =>
      settlementPaymentRules?.reduce((min, rule) => {
        if (!rule.paymentRule?.maximumPlanPaymentCount) {
          return min;
        }
        return Math.min(min, rule.paymentRule.maximumPlanPaymentCount);
      }, Infinity),
    [settlementPaymentRules],
  );

  const handleBulkCreatePaymentIntentsResponse = async (
    result,
    isRecurringPaymentAmountOrNumberOfPayments,
  ) => {
    if ("data" in result) {
      const downPaymentAmount = result.data?.downPaymentAmount;
      const numberOfPayments = result.data?.numberOfRecurringPayments;
      const recurringPaymentAmount = result.data?.firstRecurringPaymentIntentData?.totalAmount;
      const recurringPaymentStartDate = result.data?.firstRecurringPaymentIntentData?.scheduledDate;
      const recurringPaymentInterval = result.data?.recurringPaymentInterval;
      const isIncludeRemainderToLastPayment = result?.data?.isIncludeRemainderToLastPayment;
      const isPaymentDataEditable = result?.data?.isPaymentDataEditable;
      const paymentsSchedule = result.data?.paymentIntentsData;

      await dispatch(
        setPaymentPlanConfiguration({
          downPaymentAmount,
          numberOfPayments,
          recurringPaymentAmount,
          recurringPaymentInterval,
          recurringPaymentStartDate,
          isRecurringPaymentAmountOrNumberOfPayments,
          isIncludeRemainderToLastPayment,
          isPaymentDataEditable,
          paymentsSchedule,
        }),
      );

      // NOTE: This does not trigger the on-change handlers for both the recurringPaymentAmount and numberOfPayments:
      // onChangeRecurringPaymentAmount and onNumberOfPaymentsChange
      form.setFieldsValue({
        recurringPaymentAmount,
        numberOfPayments,
      });
    }
  };

  const onDownPaymentChange = debounce(async (newDownPaymentAmount) => {
    const result = await bulkCreatePaymentIntents({
      debtorId,
      paymentIntentType: paymentsSlice.paymentIntentType,
      downPaymentAmount: newDownPaymentAmount,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      maxNumberOfPayments: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? null
        : paymentsSlice.numberOfPayments,
      recurringPaymentAmount: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? paymentsSlice.recurringPaymentAmount
        : null,
      recurringPaymentStartDate: formatDate(paymentsSlice.recurringPaymentStartDate),
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment: paymentsSlice.isIncludeRemainderToLastPayment,
    });

    await handleBulkCreatePaymentIntentsResponse(
      result,
      paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments,
    );
  }, 500);

  const onChangeRecurringPaymentAmount = debounce(async (newRecurringPaymentAmount) => {
    const result = await bulkCreatePaymentIntents({
      debtorId,
      paymentIntentType: paymentsSlice.paymentIntentType,
      downPaymentAmount: paymentsSlice.downPaymentAmount,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: newRecurringPaymentAmount,
      recurringPaymentStartDate: formatDate(paymentsSlice.recurringPaymentStartDate),
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      maxNumberOfPayments: null,
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment: paymentsSlice.isIncludeRemainderToLastPayment,
    });

    await handleBulkCreatePaymentIntentsResponse(result, true);
  }, 500);

  const onNumberOfPaymentsChange = debounce(async (newNumberOfPayments) => {
    if (!newNumberOfPayments) {
      return null;
    }

    const {
      downPaymentAmount: formDownPaymentAmount,
      recurringPaymentStartDate,
      recurringPaymentInterval,
    } = form.getFieldsValue();

    const result = await bulkCreatePaymentIntents({
      downPaymentAmount: formDownPaymentAmount,
      paymentIntentType: paymentsSlice.paymentIntentType,
      maxNumberOfPayments: newNumberOfPayments,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: null, // Backend will calculate this.
      recurringPaymentInterval,
      recurringPaymentStartDate: formatDate(recurringPaymentStartDate),
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment: paymentsSlice.isIncludeRemainderToLastPayment,
      debtorId,
    });

    await handleBulkCreatePaymentIntentsResponse(result, false);
    // TODO: Handle error case.
    // We should set the form value back to what it was before the change,
    // and show an error message.
  }, 500);

  const onRecurringPaymentStartDateChange = debounce(async (newRecurringPaymentStartDate) => {
    const result = await bulkCreatePaymentIntents({
      debtorId,
      downPaymentAmount: paymentsSlice.downPaymentAmount,
      paymentIntentType: paymentsSlice.paymentIntentType,
      maxNumberOfPayments: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? null
        : paymentsSlice.numberOfPayments,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? paymentsSlice.recurringPaymentAmount
        : null,
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      recurringPaymentStartDate: formatDate(newRecurringPaymentStartDate),
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment: paymentsSlice.isIncludeRemainderToLastPayment,
    });

    await handleBulkCreatePaymentIntentsResponse(
      result,
      paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments,
    );
  }, 500);

  const onRecurringPaymentIntervalChange = async (newRecurringPaymentInterval) => {
    const result = await bulkCreatePaymentIntents({
      debtorId,
      downPaymentAmount: paymentsSlice.downPaymentAmount,
      paymentIntentType: paymentsSlice.paymentIntentType,
      maxNumberOfPayments: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? null
        : paymentsSlice.numberOfPayments,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? paymentsSlice.recurringPaymentAmount
        : null,
      recurringPaymentInterval: newRecurringPaymentInterval,
      recurringPaymentStartDate: formatDate(paymentsSlice.recurringPaymentStartDate),
      accountIds: paymentsSlice.selectedAccounts,
    });

    await handleBulkCreatePaymentIntentsResponse(
      result,
      paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments,
    );
  };

  const onIsIncludeRemainderToLastPaymentChange = debounce(async (e) => {
    const isIncludeRemainderToLastPayment = e.target.checked;
    const result = await bulkCreatePaymentIntents({
      debtorId,
      downPaymentAmount: paymentsSlice.downPaymentAmount,
      paymentIntentType: paymentsSlice.paymentIntentType,
      maxNumberOfPayments: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? null
        : paymentsSlice.numberOfPayments,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? paymentsSlice.recurringPaymentAmount
        : null,
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      recurringPaymentStartDate: formatDate(paymentsSlice.recurringPaymentStartDate),
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment,
    });

    await handleBulkCreatePaymentIntentsResponse(
      result,
      paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments,
    );
  }, 500);

  const onFinish = async (values) => {
    const {
      downPaymentAmount: formDownPaymentAmount,
      recurringPaymentAmount: formRecurringPaymentAmount,
      numberOfPayments: formNumberOfPayments,
      recurringPaymentStartDate: formRecurringPaymentStartDate,
      recurringPaymentInterval: formRecurringPaymentInterval,
      isIncludeRemainderToLastPayment: formIsIncludeRemainderToLastPayment,
    } = values;

    const result = await bulkCreatePaymentIntents({
      debtorId,
      downPaymentAmount: formDownPaymentAmount,
      paymentIntentType: paymentsSlice.paymentIntentType,
      maxNumberOfPayments: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? null
        : formNumberOfPayments,
      // Backend will calculate the totalAmount if it is a full payment. In partial/settlement cases, this is a fixed amount to pay.
      totalAmount: !paymentsSlice.isFullPayment ? paymentsSlice.totalAmount : null,
      recurringPaymentAmount: paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments
        ? formRecurringPaymentAmount
        : null,
      recurringPaymentInterval: formRecurringPaymentInterval,
      recurringPaymentStartDate: formatDate(formRecurringPaymentStartDate),
      accountIds: paymentsSlice.selectedAccounts,
      isIncludeRemainderToLastPayment: formIsIncludeRemainderToLastPayment,
    });

    await handleBulkCreatePaymentIntentsResponse(
      result,
      paymentsSlice.isRecurringPaymentAmountOrNumberOfPayments,
    );
    dispatch(getNextView());
  };

  const disabledDate = (current) => {
    // Can not select days before tomorrow, as today should only be the down payment.
    return current && current < tomorrowDate;
  };

  const maxNumberOfInstallments = useMemo(
    () =>
      maximumPlanPaymentCount -
      (!paymentsSlice.isIncludeRemainderToLastPayment ? 1 : 0) -
      (paymentsSlice.downPaymentAmount === 0 ||
      paymentsSlice.downPaymentAmount === undefined ||
      paymentsSlice.downPaymentAmount === null
        ? 0
        : 1),
    [
      maximumPlanPaymentCount,
      paymentsSlice.downPaymentAmount,
      paymentsSlice.isIncludeRemainderToLastPayment,
    ],
  );

  return (
    <Spin spinning={isBulkCreatePaymentIntentsLoading}>
      <BottomSpacedStrong>Setup a Payment Plan</BottomSpacedStrong>
      <StyledForm
        layout="vertical"
        form={form}
        initialValues={{
          downPaymentAmount: paymentsSlice.downPaymentAmount,
          amount: paymentsSlice.totalAmount,
          recurringPaymentAmount: paymentsSlice.recurringPaymentAmount ?? paymentsSlice.totalAmount,
          numberOfPayments: paymentsSlice.numberOfPayments,
          recurringPaymentStartDate: tomorrowDate,
          recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
          isIncludeRemainderToLastPayment: paymentsSlice.isIncludeRemainderToLastPayment,
        }}
        validateMessages={{ required: "This is a required field" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="downPaymentAmount"
          label="Down Payment"
          rules={[
            { required: false },
            {
              validator: (_, value) => {
                // If the down payment is not set, we can proceed.
                if (value === undefined || value === null) {
                  return Promise.resolve(true);
                }
                if (value === 0) {
                  return Promise.reject(new Error("The entered amount must be greater than 0"));
                }
                if (value < minimumPaymentAmount) {
                  return Promise.reject(
                    new Error(`Down payment must be greater than $${minimumPaymentAmount}`),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          validateTrigger="onBlur" // Setting Form.Item's prop validateTrigger="onBlur" ensures that validation only happens when the field loses the focus.
        >
          <StyledInputNumber prefix="$" controls={false} min={0} onChange={onDownPaymentChange} />
        </Form.Item>
        <Form.Item label="Payment Interval" rules={[{ required: true }]} required>
          <Input.Group compact>
            <Form.Item
              noStyle
              name="numberOfPayments"
              label="Number of Payments"
              rules={[
                { required: true },
                {
                  type: "number",
                  max: maxNumberOfInstallments,
                  message: `Number of payments must be less than or equal to ${maxNumberOfInstallments}`,
                },
              ]}
            >
              <StyledInputNumber
                placeholder="Enter a number..."
                onChange={onNumberOfPaymentsChange}
                controls={false}
              />
            </Form.Item>
            <Form.Item
              noStyle
              name="recurringPaymentInterval"
              label="Payment Interval"
              rules={[{ required: true }]}
            >
              <Select
                disabled={isBulkCreatePaymentIntentsLoading}
                options={[
                  { label: "Monthly", value: "monthly" },
                  { label: "Bi-monthly", value: "bimonthly" },
                  { label: "Weekly", value: "weekly" },
                  { label: "Bi-weekly", value: "biweekly" },
                ]}
                popupMatchSelectWidth={false}
                onChange={onRecurringPaymentIntervalChange}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          name="recurringPaymentAmount"
          label="Recurring Amount"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value <= 0) {
                  return Promise.reject(new Error("The entered amount must be greater than 0"));
                }
                if (value < minimumPaymentAmount) {
                  return Promise.reject(
                    new Error(`Recurring amount must be greater than $${minimumPaymentAmount}`),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          required
        >
          <StyledInputNumber
            disabled={isBulkCreatePaymentIntentsLoading}
            onChange={onChangeRecurringPaymentAmount}
            prefix="$"
            precision={2}
            controls={false}
            min={0}
          />
        </Form.Item>
        <Form.Item noStyle name="isIncludeRemainderToLastPayment" valuePropName="checked">
          <StyledCheckbox
            onChange={
              !paymentsSlice.isFullPayment ? onIsIncludeRemainderToLastPaymentChange : undefined
            }
          >
            Include remainder with last payment
          </StyledCheckbox>
        </Form.Item>
        <Form.Item
          name="recurringPaymentStartDate"
          label="Payment Start Date"
          rules={[{ required: true }]}
        >
          <AktDatePicker disabledDate={disabledDate} onChange={onRecurringPaymentStartDateChange} />
        </Form.Item>
        <Divider />
        {paymentsSlice.isFullPayment ? (
          <StyledDiv>
            <span>Amount Due Across Selected Accounts Prior To Interest: </span>
            <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
          </StyledDiv>
        ) : (
          <StyledDiv>
            <span>Amount Due Across Selected Accounts: </span>
            <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
            <div>
              <span>Payment Amount: </span>
              <strong>{formatCurrency(paymentsSlice.totalAmount)}</strong>
            </div>
          </StyledDiv>
        )}
        <Form.Item>
          <Space>
            <Button
              onClick={() => {
                dispatch(getPreviousView());
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                isBulkCreatePaymentIntentsLoading ||
                !paymentsSlice.numberOfPayments ||
                !paymentsSlice.recurringPaymentAmount
              }
            >
              Preview Plan
            </Button>
          </Space>
        </Form.Item>
      </StyledForm>
    </Spin>
  );
}

export default SetupPaymentPlanConfiguration;
