import CreditorProfileDocumentsTable from "features/creditorProfileDocumentsTable/creditorPortal/creditorProfileDocumentsTable";
import Content from "layouts/creditorPortal/dashboard/content";
import PageLayout from "layouts/creditorPortal/dashboard/pageLayout";

function DocumentsPage() {
  return (
    <PageLayout>
      <Content>
        <CreditorProfileDocumentsTable />
      </Content>
    </PageLayout>
  );
}

export default DocumentsPage;
