import { Form, Modal, Select, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { selectAccountsTableSlice } from "features/accountsTable/accountsTableSlice";
import { useAssignAccountsMutation } from "features/accountsTable/agencyPortal/accountsTableAPI";
import {
  activeCollectorsWithRolesSelector,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function AssignAgentModal({ title, open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const [assignAccount, { isLoading }] = useAssignAccountsMutation();
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const { activeCollectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeCollectorsWithRoles: activeCollectorsWithRolesSelector(result),
    }),
  });
  const onSubmit = async () => {
    const values = await form.validateFields();

    const selectedAccountIds = accountsTableSlice.selectedRows.map((row) => row.id);

    const result = await assignAccount({
      accountIds: selectedAccountIds,
      agentId: values.assignToAgentId,
    });
    if ("data" in result) {
      const assignee = collectors?.find((collector) => collector.id === values.assignToAgentId);
      message.success(`Accounts successfully assigned to ${collectorFullName(assignee)}`);
      onOk();
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      message.error("Failed to assign accounts. Please try again later.");
    }

    onOk();
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={false}
      okButtonProps={{ loading: isLoading, disabled: isLoading }}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm
        form={form}
        validateMessages={{ required: "This is a required field" }}
        layout="vertical"
      >
        <Form.Item name="assignToAgentId" label="Select an agent to assign">
          <Select
            options={[
              {
                label: "Unassigned",
                value: null,
              },
              ...(collectors?.map((c) => ({
                label: collectorFullName(c),
                value: c.id,
              })) ?? []),
            ]}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default AssignAgentModal;
