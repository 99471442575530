import { Form, Modal, Select } from "antd";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  max-width: 400px;
  padding-top: 8px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function ConfigureVoicemailCampaignModal({
  open,
  onSubmit,
  onCancel,
  title,
  provider,
}) {
  const { data: communicationMethods = [] } = useFetchCommunicationMethodsQuery(
    {
      provider,
    },
    {
      skip: !provider,
    },
  );
  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();

  const onDelete = (record) => {
    setDataSource((prev) => {
      return prev.filter((d) => {
        return d.key !== record.key;
      });
    });
  };

  const onSave = ({ index, ...record }) => {
    if (index === -1) {
      setDataSource((prev) => [...prev, { ...record, key: Math.random() }]);
    } else {
      setDataSource((prev) => {
        const copy = [...prev];
        copy[index] = record;
        return copy;
      });
    }
    return { data: true };
  };

  const communicationMethodOptions = communicationMethods
    .filter((communicationMethod) => {
      return (
        !communicationMethod.isArchived &&
        communicationMethod.provider === provider &&
        communicationMethod.medium === "voicemail"
      );
    })
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const onOk = async () => {
    const values = await form.validateFields();
    onSubmit({ ...values, phoneConfigs: dataSource });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      onOk={onOk}
      width={900}
      title={title}
    >
      <StyledForm labelAlign="left" layout="vertical" form={form}>
        <Form.Item
          label="Communication Method"
          name="template"
          rules={[
            {
              required: true,
              message: "Please select a communication method",
            },
          ]}
        >
          <Select
            showSearch
            filterOption={filterOption}
            popupMatchSelectWidth={false}
            options={communicationMethodOptions}
            placeholder="Select one..."
            allowClear
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
