import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Popconfirm, Row, Space, Table, message } from "antd";
import { getColumnSearchProps } from "common/antd-utils";
import { snakeToCamelCase } from "common/utils";
import currency from "currency.js";
import EditFeePlanModal from "features/feePlans/components/editFeePlanModal";
import { useDeleteFeePlanMutation, useFetchFeePlansQuery } from "features/feePlans/feePlansAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useRef, useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  margin-top: 1em;
`;

export default function FeePlanTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: feePlans, isLoading } = useFetchFeePlansQuery();
  const [activeRecord, setActiveRecord] = useState(null);
  const [deleteFeePlan] = useDeleteFeePlanMutation();
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const {
    data: {
      [PERMISSIONS.FEE_PLAN__GET]: isFeePlanGetAuthorized,
      [PERMISSIONS.FEE_PLAN__CREATE]: isFeePlanCreateAuthorized,
      [PERMISSIONS.FEE_PLAN__UPDATE]: isFeePlanUpdateAuthorized,
      [PERMISSIONS.FEE_PLAN__DELETE]: isFeePlanDeleteAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = async (record) => {
    const result = await deleteFeePlan({ id: record.id });
    if ("data" in result) {
      message.success("Fee plan removed successfully!");
    }
  };

  const onUpdateAccountClick = (record) => {
    setModalVisible(true);
    setActiveRecord(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Flat Fee Per Account Upload($)",
      dataIndex: "flatFeePerAccountUpload",
      key: "flatFeePerAccountUpload",
    },
    {
      title: "Percent Contingency",
      children: constants?.paymentCategories?.invoiceable.map((category) => ({
        title: `${category.display} (%)`,
        dataIndex: snakeToCamelCase(category.value),
        render: (text) => currency(text, { precision: 6 }).multiply(100).value || "",
        key: category.display,
      })),
      key: "percentContingecy",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isFeePlanUpdateAuthorized && !isFeePlanDeleteAuthorized,
      render: (text, record) => (
        <Space size="middle">
          {isFeePlanUpdateAuthorized && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => onUpdateAccountClick(record)} />
            </AntDToolTip>
          )}
          {isFeePlanDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this fee plan?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete-fee-plan" />
            </Popconfirm>
          )}
        </Space>
      ),
      fixed: "right",
    },
    // {
    //   title: "Debt Balance",
    //   children: [
    //     {
    //       title: "Min ($)",
    //       dataIndex: "minDebtBalance",
    //     },
    //     {
    //       title: "Max ($)",
    //       dataIndex: "maxDebtBalance",
    //     },
    //   ],
    // },
    // {
    //   title: "Age of Account",
    //   children: [
    //     {
    //       title: "Min (years)",
    //       dataIndex: "minAgeOfAccount",
    //     },
    //     {
    //       title: "Max (years)",
    //       dataIndex: "maxAgeOfAccount",
    //     },
    //   ],
    // },
  ].filter((column) => !column.hidden);

  const onNewRuleOk = () => {
    setModalVisible(false);
  };

  return isFeePlanGetAuthorized ? (
    <>
      <h4>Fee Plans</h4>
      <Row align="middle">
        <span>Configure client contingency fee plans.</span>
        {isFeePlanCreateAuthorized && (
          <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
            Add Fee Plan
          </Button>
        )}
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={feePlans}
      />
      {isModalVisible && (
        <EditFeePlanModal
          title={activeRecord ? "Edit Rule" : "Add Rule"}
          open={isModalVisible}
          onOk={onNewRuleOk}
          onCancel={() => {
            setModalVisible(false);
            setActiveRecord(null);
          }}
          feePlan={activeRecord}
        />
      )}
    </>
  ) : null;
}
