import { ApiOutlined, DeleteOutlined, DollarOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Table, Tag, Tooltip, message } from "antd";
import { ALL_PAYMENT_METHOD_VALUES, DATE_FORMAT, PAYMENT_INTENT_STATUS } from "common/constants";
import { formatCurrency, toTitleCase } from "common/utils";
import {
  useBulkDeleteScheduledPaymentIntentsMutation,
  useExecutePaymentIntentMutation,
  useGetPaymentIntentsQuery,
  useVoidPaymentIntentMutation,
} from "features/payments/paymentsAPI";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledActionContainer = styled(Row)`
  margin-top: 16px;
  background: #fff;
  height: 48px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;
const StyledActions = styled(Row)`
  margin-left: 47px;
  border-left: 1px solid #f0f0f0;
  height: 100%;
  align-items: center;
`;

const StyledTable = styled(Table)`
  white-space: pre;
  cursor: pointer;

  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }
`;

const ButtonWithMargin = styled(Button)`
  margin: 0px 5px;
  color: #444;
  &:hover {
    background: #f0f0f0;
  }
`;

export default function ScheduledPayments() {
  const { debtorId } = useParams();
  const { data: paymentIntents, isLoading } = useGetPaymentIntentsQuery({ debtorId });
  const [voidPaymentIntent] = useVoidPaymentIntentMutation();
  const [executePaymentIntent, { isLoading: isExecuteLoading }] = useExecutePaymentIntentMutation();
  const [removePaymentIntents] = useBulkDeleteScheduledPaymentIntentsMutation();
  const [selectedRows, setSelectedRows] = useState([]);

  const rowSelection = {
    onChange: (keys, rows) => {
      setSelectedRows(rows);
    },
  };

  const preventPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const deselectRows = () => {
    setSelectedRows([]);
  };

  const dataSource = paymentIntents
    ?.filter(
      (paymentIntent) =>
        ALL_PAYMENT_METHOD_VALUES.includes(paymentIntent.paymentMethod.type) &&
        paymentIntent.status === PAYMENT_INTENT_STATUS.NEW &&
        !paymentIntent.isLastTransactionDeclined,
    )
    .sort((a, b) => moment(a.scheduledDate).unix() - moment(b.scheduledDate).unix());

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "id",
      key: "id",
      width: 150,
      render: (text, record) =>
        record.isSettlement ? (
          <>
            <Tag color="green">SETTLEMENT</Tag>
            {text}
          </>
        ) : (
          text
        ),
    },
    {
      title: "Account IDs",
      dataIndex: "externalAccountIds",
      key: "externalAccountIds",
      render: (text, record) => record.externalAccountIds?.join(", ")?.toUpperCase() ?? "-",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      render: (text, record) =>
        record.scheduledDate && moment(record.scheduledDate).format(DATE_FORMAT),
      sorter: (a, b) => moment(a.scheduledDate).unix() - moment(b.scheduledDate).unix(),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => `$${record.totalAmount}`,
    },
    {
      title: "Convenience Fee",
      dataIndex: "convenienceFeeAmount",
      render: (text) => (text ? formatCurrency(text) : "-"),
    },
    {
      title: "Payment Method",
      key: "paymentMethod",
      render: (text, record) => {
        const { type, cardLast4Digits, bankAccountLast4Digits } = record.paymentMethod ?? {};
        if (type === "process_card") {
          return `Card ending in ${cardLast4Digits}`;
        }
        if (type === "process_ach") {
          return `ACH ending in ${bankAccountLast4Digits}`;
        }
        if (type === "paid_to_creditor_legal") {
          return `Paid to Creditor (Legal Payment)`;
        }
        if (type === "forwarding_entity_legal") {
          return `Paid to Forwarding Entity (Legal Payment)`;
        }
        return toTitleCase({ str: type, delimiter: "_" });
      },
    },
    {
      title: "Logging Only",
      dataIndex: "isLoggingOnly",
      key: "isLoggingOnly",
      render: (text, record) => (record.isLoggingOnly ? "Yes" : "No"),
    },
  ];

  const handleDelete = async () => {
    const nonNewIntents = selectedRows.filter(
      (record) => record.status !== PAYMENT_INTENT_STATUS.NEW,
    );
    if (nonNewIntents.length > 0) {
      return message.error("Only scheduled payments can be deleted");
    }
    const result = await removePaymentIntents({
      debtorId,
      paymentIntentIds: selectedRows?.map((record) => record.id),
    });
    if ("error" in result) {
      return message.error("Failed to delete scheduled payments");
    }
    message.success("Scheduled payments were deleted successfully");
    deselectRows();
  };

  const handleVoid = async () => {
    if (selectedRows?.length !== 1) {
      return message.error("Only one payment can be voided at a time");
    }

    const nonExecutedIntents = selectedRows?.filter(
      (record) => record.status !== PAYMENT_INTENT_STATUS.EXECUTED,
    );
    if (nonExecutedIntents?.length > 0) {
      return message.error("Only executed payments can be voided");
    }
    const result = await voidPaymentIntent({ debtorId, paymentIntentId: selectedRows?.at(0)?.id });
    if ("error" in result) {
      return message.error("Failed to void payment");
    }
    message.success("Payment was voided successfully");
    deselectRows();
  };

  const handleExecute = async () => {
    if (selectedRows.length !== 1) {
      return message.error("Only one payment can be executed at a time");
    }

    const nonNewIntents = selectedRows.filter(
      (record) => record.status !== PAYMENT_INTENT_STATUS.NEW,
    );
    if (nonNewIntents.length > 0) {
      return message.error("Only scheduled payments can be executed");
    }
    const result = await executePaymentIntent({
      debtorId,
      paymentIntentId: selectedRows?.at(0)?.id,
    });
    if ("data" in result) {
      message.success("Payment was executed successfully");
    }
    deselectRows();
  };

  const ACTIONS = [
    {
      key: "delete",
      action: handleDelete,
      icon: DeleteOutlined,
      isEnabled: () =>
        // Only if all selected rows are NEW status
        selectedRows?.length > 0 &&
        selectedRows?.filter((record) => record.status !== PAYMENT_INTENT_STATUS.NEW)?.length === 0,
      actionConfirmationText: `Are you sure you want to delete ${selectedRows?.length} scheduled payment(s)?`,
      tooltipText: "Select one or more scheduled payments to delete",
    },
    {
      key: "execute",
      action: handleExecute,
      icon: DollarOutlined,
      isEnabled: () =>
        // Only if all selected rows are NEW status and it's logging only payment and there's only one selected row
        selectedRows?.length === 1 &&
        selectedRows[0].isLoggingOnly &&
        selectedRows[0].status === PAYMENT_INTENT_STATUS.NEW,
      actionConfirmationText: `Are you sure you want to execute payment for the selected payment ID: ${
        selectedRows?.at(0)?.id
      }?`,
      tooltipText: "Select a payment to execute",
    },
    {
      key: "void",
      action: handleVoid,
      icon: ApiOutlined,
      isEnabled: () =>
        // Only if all selected rows are EXECUTED status and there's only one selected row
        selectedRows?.length === 1 && selectedRows[0].status === PAYMENT_INTENT_STATUS.EXECUTED,
      actionConfirmationText: `Are you sure you want to void the selected payment ID: ${
        selectedRows?.at(0)?.id
      }?`,
      tooltipText: "Select an already executed payment to void",
    },
  ];

  return (
    <>
      <StyledActionContainer>
        <StyledActions wrap={false}>
          {ACTIONS.map((action) => (
            <Popconfirm
              key={action.key}
              placement="topRight"
              okText="Yes"
              title={action.actionConfirmationText}
              onCancel={(e) => {
                preventPropagation(e);
              }}
              onConfirm={(e) => {
                preventPropagation(e);
                action.action();
              }}
            >
              <Tooltip title={action.tooltipText}>
                <ButtonWithMargin
                  disabled={!action.isEnabled()}
                  shape="circle"
                  icon={<action.icon />}
                  type="text"
                />
              </Tooltip>
            </Popconfirm>
          ))}
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        loading={isLoading || isExecuteLoading}
        rowSelection={rowSelection}
        bordered
        rowClassName={() => "editable-row"}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 100],
        }}
        columns={columns}
        dataSource={dataSource}
      />
    </>
  );
}
