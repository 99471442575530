import { TeamOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const getItem = (label, key, icon, children, type) => {
  return {
    key,
    // icon,
    children,
    label,
    type,
  };
};

function AppMenu() {
  const items = [
    getItem(<Link to="/accounts">Accounts</Link>, "accounts"),
    getItem("Admin", "admin", <TeamOutlined />, [
      getItem(<Link to="/data-import">Data Import</Link>, "data-import"),
      getItem(<Link to="/reports">Reports</Link>, "reports"),
    ]),
    getItem(<Link to="/documents">Documents</Link>, "documents"),
  ];

  return <Menu theme="dark" mode="horizontal" items={items} />;
}

export default AppMenu;
