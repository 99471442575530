import { EditOutlined, FilterFilled } from "@ant-design/icons";
import { Button, Checkbox, Input, List, Row, Space, Table, Tag, Tooltip } from "antd";
import { camelToWords, formatDate, snakeToCamelCase } from "common/utils";
import { PageHeader } from "components/pageHeader";
import { useFetchAccountsQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchDisputeCategoriesQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import ManageDisputeModal from "features/debtorAccountsTable/components/manageDisputeModal";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import moment from "moment-timezone";
import VirtualList from "rc-virtual-list";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

const StyledContainer = styled.div`
  padding: 8px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 8px;
  display: block;
`;

const StyledRowCheckbox = styled(Checkbox)`
  margin-left: 5px;
`;

const StyledFilterFilled = styled(FilterFilled)`
  color: ${(props) =>
    // @ts-ignore
    props.filtered ? "#1677ff" : undefined};
`;

const StyledList = styled(List)`
  .ant-list-item {
    padding: 6px 0;
  }
`;

function FilterIcon(filtered) {
  // @ts-ignore
  return <StyledFilterFilled $filtered={filtered} />;
}

function FilterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) {
  const [filterText, setFilterText] = useState("");
  const { data: creditors } = useFetchCreditorSummariesQuery();

  const searchInput = useRef(null);
  const filteredDataSource = useMemo(
    () => creditors?.filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase())),
    [creditors, filterText],
  );

  const onRowCheckboxChange = (e) => {
    const { checked } = e.target;
    const { id } = e.target;
    if (checked) {
      setSelectedKeys([...selectedKeys, id]);
    } else {
      setSelectedKeys(selectedKeys.filter((item) => item !== id));
    }
  };

  const height = Math.min((filteredDataSource ?? []).length * 40, 400);

  return (
    <StyledContainer onKeyDown={(e) => e.stopPropagation()}>
      <StyledInput
        ref={searchInput}
        placeholder="Search clients"
        value={filterText}
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
        onPressEnter={() => confirm()}
      />
      <StyledList>
        <VirtualList data={filteredDataSource} height={height} itemHeight={40} itemKey="id">
          {(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  <StyledRowCheckbox
                    id={item.id}
                    onChange={onRowCheckboxChange}
                    checked={selectedKeys.includes(item.id)}
                  />
                }
                title={item.name}
              />
            </List.Item>
          )}
        </VirtualList>
      </StyledList>
      <Row justify="space-between">
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
            }
            setFilterText("");
            setSelectedKeys([]);
          }}
          type="link"
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: true,
            });
          }}
        >
          OK
        </Button>
      </Row>
    </StyledContainer>
  );
}

export default function DisputeManagementTable() {
  const {
    data: accountsWithDisputes,
    isLoading: isTableLoading,
    isFetching: isTableFetching,
  } = useFetchAccountsQuery({ filters: { isDisputed: true }, pageSize: 1000 });
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: categories } = useFetchDisputeCategoriesQuery();
  const [isDisputeModalVisible, setIsDisputeModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: isDisputeUpdateAuthorized } = useAuthorization(PERMISSIONS.DISPUTE__UPDATE);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Dispute ID",
      render: (text, record) => record.dispute?.id ?? "-",
    },
    {
      title: "Date Received",
      render: (text, record) =>
        record.dispute?.receivedDate ? formatDate(record.dispute?.receivedDate) : "-",
      sorter: (a, b) => moment(a.dispute?.receivedDate).diff(moment(b.dispute?.receivedDate)),
    },
    {
      title: "Account ID (external)",
      dataIndex: "externalId",
      key: "externalId",
    },
    {
      title: "Debtor Name",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            const newWindow = window.open(`/debtors/${record.debtorId}`, "_blank");
            if (newWindow) newWindow.opener = null;
          }}
        >
          {record.debtorName}
        </Button>
      ),
    },
    {
      title: "Category",
      render: (text, record) => {
        const tags = categories?.filter((category) =>
          record.dispute?.categoryTagIds.includes(category.id),
        );
        return tags ? tags.map((tag) => <Tag key={tag.id}>{tag.name}</Tag>) : "-";
      },
      filters: categories?.map((each) => ({
        text: each.name,
        value: each.id,
      })),
      onFilter: (value, record) =>
        record.dispute?.categoryTagIds?.includes(value) || record.dispute?.categoryTagIds === value,
    },
    {
      title: "Amount",
      render: (text, record) => record.dispute?.amount ?? "-",
    },
    {
      title: "Time Since Dispute",
      render: (text, record) =>
        record.dispute?.receivedDate
          ? `${moment().diff(moment(record.dispute?.receivedDate), "days")} days`
          : "-",
      sorter: (a, b) =>
        moment(a.dispute?.receivedDate).diff(moment(b.dispute?.receivedDate), "days"),
    },
    {
      title: "Status",
      render: (text, record) =>
        record.dispute?.status ? camelToWords(snakeToCamelCase(record.dispute?.status)) : "-",
      filters: [
        {
          text: "Submitted",
          value: "submitted",
        },
        {
          text: "In Review",
          value: "in_review",
        },
        {
          text: "Awaiting Client Response",
          value: "awaiting_creditor_response",
        },
        {
          text: "Gathering Evidence From Client",
          value: "gathering_evidence_from_client",
        },
        {
          text: "Investigated, But Debtor Disagrees",
          value: "investigated_but_debtor_disagrees",
        },
        {
          text: "Resolved",
          value: "resolved",
        },
      ],
      onFilter: (value, record) => record.dispute?.status === value,
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (text, record) => creditors?.find((each) => each.id === record.creditorId)?.name,
      filterDropdown: FilterDropdown,
      filterIcon: FilterIcon,
      onFilter: (value, record) => {
        return record.creditorId === value;
      },
    },
    {
      title: "Evidence of Debt",
      dataIndex: "evidenceOfDebt",
      key: "evidenceOfDebt",
      render: (text, record) => (record.dispute?.documents?.length > 0 ? "Yes" : "No"),
      filters: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      onFilter: (value, record) =>
        record.dispute?.documents?.length > 0 ? value === "yes" : value === "no",
    },
    {
      title: "Actions",
      key: "actions",
      hidden: !isDisputeUpdateAuthorized,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip placement="bottom" title="Edit" key="edit">
            <EditOutlined key="edit" onClick={() => onEditDispute(record)} />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
    },
  ].filter((column) => !column.hidden);

  const onEditDispute = (record) => {
    setIsDisputeModalVisible(true);
    setActiveRecord(record);
  };

  const onManageDisputeModalCancel = () => {
    setIsDisputeModalVisible(false);
    setActiveRecord(null);
  };

  return (
    <>
      <StyledHeader>Disputes</StyledHeader>
      <Table
        loading={isTableLoading || isTableFetching}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={accountsWithDisputes?.results}
      />
      {isDisputeModalVisible && (
        <ManageDisputeModal
          account={activeRecord}
          title={`Dispute Account (ID: ${activeRecord?.id})`}
          open={isDisputeModalVisible}
          onOk={onManageDisputeModalCancel}
          onCancel={onManageDisputeModalCancel}
          debtorId={activeRecord?.debtorId}
        />
      )}
    </>
  );
}
