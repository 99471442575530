import { Checkbox, Form, Input, InputNumber, Modal, Row, Select, message } from "antd";

import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import {
  useCreateCommunicationLimitMutation,
  useUpdateCommunicationLimitMutation,
} from "features/communicationLimitsTable/communicationLimitsApi";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-right: 12px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default function CommunicationLimitsModal({ record, title, open, onCancel }) {
  const [form] = Form.useForm();
  const { data: constants } = useFetchBackendConstantsQuery();
  const [createCommunicationLimit, { isLoading: createLoading }] =
    useCreateCommunicationLimitMutation();
  const [updateCommunicationLimit, { isLoading: updateLoading }] =
    useUpdateCommunicationLimitMutation();

  const onModalCancel = () => {
    onCancel();
    form.resetFields();
  };

  const onModalOk = async () => {
    const values = await form.validateFields();
    const payload = {
      ...values,
      id: record?.id,
      states: values.isAllStates ? [] : values.states,
      callResults: constants?.callResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      textResults: constants?.textResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      emailResults: constants?.emailResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      voicemailResults: constants?.voicemailResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      mailResults: constants?.mailResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
      communicationResults: constants.communicationResultsForLimitRules
        ?.map((each) => each.value)
        .filter((each) => values.resultTags.includes(each)),
    };
    const result = record
      ? await updateCommunicationLimit(payload)
      : await createCommunicationLimit({
          id: record?.id,
          ...payload,
        });

    if ("data" in result) {
      message.success(`Communication limit rule ${record ? "updated" : "created"} successfully`);
      onModalCancel();
    }
  };

  const initialValues = {
    ...record,
    states: record?.isAllStates ? [] : record?.states,
    resultTags: [
      ...(record?.callResults ?? []),
      ...(record?.textResults ?? []),
      ...(record?.emailResults ?? []),
      ...(record?.voicemailResults ?? []),
      ...(record?.mailResults ?? []),
      ...(record?.communicationResults ?? []),
    ],
  };

  return (
    <Modal
      confirmLoading={createLoading || updateLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onModalOk}
      onCancel={onModalCancel}
    >
      <StyledForm
        initialValues={initialValues}
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item rules={[{ required: true }]} label="Name" name="name">
          <Input />
        </Form.Item>
        <Row align="bottom">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.isAllStates !== currentValues?.isAllStates
            }
          >
            {({ getFieldValue }) => {
              const checked = getFieldValue("isAllStates");
              return (
                <StyledFormItem
                  rules={[{ required: !checked }]}
                  name="states"
                  label="Debtor Location(s)"
                >
                  <Select
                    maxTagCount={1}
                    popupMatchSelectWidth={false}
                    disabled={checked}
                    mode="multiple"
                    options={constants?.states.map((state) => ({
                      value: state.value,
                      label: state.display,
                    }))}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
          <Form.Item valuePropName="checked" name="isAllStates">
            <Checkbox>All</Checkbox>
          </Form.Item>
        </Row>
        <Form.Item rules={[{ required: true }]} name="maxAllowed" label="# Contacts Allowed">
          <StyledInputNumber min="0" step="1" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="contactMethods" label="Contact Methods">
          <Select
            mode="multiple"
            popupMatchSelectWidth={false}
            options={constants?.contactMethods.map((method) => ({
              label: method.display,
              value: method.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, resultTags) {
                const contactMethods = getFieldValue("contactMethods") || [];

                // Get all result tags by type from constants
                const callResults = constants?.callResultsForLimitRules?.map((r) => r.value) || [];
                const textResults = constants?.textResultsForLimitRules?.map((r) => r.value) || [];
                const emailResults =
                  constants?.emailResultsForLimitRules?.map((r) => r.value) || [];
                const voicemailResults =
                  constants?.voicemailResultsForLimitRules?.map((r) => r.value) || [];
                const mailResults = constants?.mailResultsForLimitRules?.map((r) => r.value) || [];
                const communicationResults =
                  constants?.communicationResultsForLimitRules?.map((r) => r.value) || [];

                // Check if any selected result tags are of each type
                const hasCallResults = resultTags?.some((tag) => callResults.includes(tag));
                const hasTextResults = resultTags?.some((tag) => textResults.includes(tag));
                const hasEmailResults = resultTags?.some((tag) => emailResults.includes(tag));
                const hasVoicemailResults = resultTags?.some((tag) =>
                  voicemailResults.includes(tag),
                );
                const hasMailResults = resultTags?.some((tag) => mailResults.includes(tag));
                const hasCommunicationResults = resultTags?.some((tag) =>
                  communicationResults.includes(tag),
                );

                const missingCategories = [];
                if (contactMethods.includes("phone") && !hasCallResults) {
                  missingCategories.push("Call Results");
                }
                if (contactMethods.includes("text") && !hasTextResults) {
                  missingCategories.push("Text Results");
                }
                if (contactMethods.includes("email") && !hasEmailResults) {
                  missingCategories.push("Email Results");
                }
                if (contactMethods.includes("voicemail") && !hasVoicemailResults) {
                  missingCategories.push("Voicemail Results");
                }
                if (contactMethods.includes("mail") && !hasMailResults) {
                  missingCategories.push("Mail Results");
                }
                if (!hasCommunicationResults) {
                  missingCategories.push("Communication Results");
                }

                if (missingCategories.length && resultTags.length) {
                  // We only want to display this message if the field already has values set but it's incomplete
                  return Promise.reject(
                    new Error(`Missing tags for: ${missingCategories.join(", ")}`),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          dependencies={["contactMethods"]}
          name="resultTags"
          label="Result Tags"
        >
          <Select
            mode="multiple"
            popupMatchSelectWidth={false}
            options={[
              {
                label: "Call Results",
                options:
                  constants?.callResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Text Results",
                options:
                  constants?.textResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Email Results",
                options:
                  constants?.emailResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Voicemail Results",
                options:
                  constants?.voicemailResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Mail Results",
                options:
                  constants?.mailResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
              {
                label: "Communication Results",
                options:
                  constants?.communicationResultsForLimitRules?.map((resultType) => ({
                    label: resultType.display,
                    value: resultType.value,
                  })) ?? [],
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="rollingPeriod"
          label="Rolling Time Period (days)"
        >
          <StyledInputNumber min="0" step="1" />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
