import { Button, Col, Form, Row, Select } from "antd";
import ConfigureVoicemailCampaignModal from "features/bulkActions/components/configureVoicemailCampaignModal";
import { useState } from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  max-width: 400px !important;
  margin-right: 4px;
`;

export default function SendVoicemailSubForm({
  setHideParentModal,
  operationType,
  initialProvider,
}) {
  const form = Form.useFormInstance();
  const [voicemailOptionsModalOpen, setVoicemailOptionsModalOpen] = useState(false);

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onConfigureVoicemailCampaignClick = () => {
    const provider = form.getFieldValue(["operations", "sendVoicemail", "provider"]);
    if (provider) {
      setVoicemailOptionsModalOpen(true);
      setHideParentModal(true);
    }
  };

  return (
    <>
      <Row align="middle" gutter={12}>
        <Col span={12}>
          <Form.Item
            name={["operations", "sendVoicemail", "provider"]}
            label="Provider"
            initialValue={initialProvider}
            rules={[
              {
                required: true,
                message: "Please select a provider",
              },
            ]}
          >
            <StyledSelect
              showSearch
              filterOption={filterOption}
              popupMatchSelectWidth={false}
              options={[
                {
                  label: "TCN",
                  value: "tcn",
                },
              ]}
              placeholder="Select one..."
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item hidden name={["operations", "sendVoicemail", "phoneConfigs"]} />
          <Form.Item hidden name={["operations", "sendVoicemail", "communicationMethodId"]} />
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev?.operations?.sendVoicemail?.provider !==
              curr?.operations?.sendVoicemail?.provider
            }
          >
            {({ getFieldValue }) => {
              const provider = getFieldValue(["operations", "sendVoicemail", "provider"]);
              const options = getFieldValue([
                "operations",
                "sendVoicemail",
                "communicationMethodId",
              ]);
              if (!provider) {
                return null;
              }
              if (!options) {
                return (
                  <Button type="link" onClick={onConfigureVoicemailCampaignClick}>
                    Configure provider
                  </Button>
                );
              }
              return (
                <Button type="link" onClick={onConfigureVoicemailCampaignClick}>
                  Re-configure provider
                </Button>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <ConfigureVoicemailCampaignModal
        operationType={operationType}
        provider={form.getFieldValue(["operations", "sendVoicemail", "provider"])}
        open={voicemailOptionsModalOpen}
        onCancel={() => {
          setVoicemailOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        onSubmit={(data) => {
          form.setFieldValue(
            ["operations", "sendVoicemail", "communicationMethodId"],
            data.template,
          );
          setVoicemailOptionsModalOpen(false);
          setHideParentModal(false);
        }}
        title="Configure Voicemail Campaign"
      />
    </>
  );
}
