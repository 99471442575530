import { Form, Modal, Select, Tag, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import {
  useCreateTaskMutation,
  useFetchDebtorAccountsQuery,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchDebtorPhoneNumbersQuery } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function SendTextModal({ title, open, onOk, onCancel }) {
  const [form] = useForm();
  const { debtorId } = useParams();
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const { data: communicationMethods, isLoading: isCommunicationMethodsLoading } =
    useFetchCommunicationMethodsQuery();
  const { data: accounts, isLoading: isAccountsLoading } = useFetchDebtorAccountsQuery({
    debtorId,
  });

  const { data: debtorPhones } = useFetchDebtorPhoneNumbersQuery(
    {
      debtorId,
    },
    { skip: !debtorId },
  );

  const textOptions = communicationMethods?.reduce((acc, method) => {
    if (method.medium !== "text" || method.isArchived) {
      return acc;
    }
    return [
      ...acc,
      {
        label: method.externalSmsTemplateId
          ? `${method.name} (${method.provider} ID: ${method.externalSmsTemplateId})`
          : method.name,
        value: method.id,
      },
    ];
  }, []);

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = await createTask({ ...values, debtorId });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(`Text scheduled to send on ${values.scheduledDate}`);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <StyledForm layout="vertical" form={form}>
        <Form.Item
          label="SMS Template"
          name="communicationMethodId"
          rules={[{ required: true, message: "Select an SMS template" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            loading={isCommunicationMethodsLoading}
            options={textOptions}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.communicationMethodId !== currentValues.communicationMethodId
          }
        >
          {({ getFieldValue }) => {
            const communicationMethodId = getFieldValue("communicationMethodId");
            const selectedCommunicationMethod = communicationMethods?.find(
              (each) => each.id === communicationMethodId,
            );
            const allowedContactPermissionStatuses =
              selectedCommunicationMethod?.smsContactPermissionStatuses ??
              (selectedCommunicationMethod ? ["consent", "unknown"] : []);

            return (
              <Form.Item
                label="Cell Phone Number(s)"
                name="debtorContactPhoneIds"
                rules={[{ required: true, message: "Select at least one cell number" }]}
              >
                <Select
                  allowClear
                  mode="multiple"
                  popupMatchSelectWidth={false}
                  placeholder="Select one..."
                  options={debtorPhones
                    ?.filter(
                      (each) =>
                        each.isActive &&
                        allowedContactPermissionStatuses.includes(each.permissionToTextStatus),
                    )
                    ?.map((each) => ({
                      value: each.id,
                      label: (
                        <span>
                          {each.number} {each.isPrimary ? <Tag color="yellow">PRIMARY</Tag> : ""}
                          <Tag
                            color={
                              { cell: "blue", work: "purple", home: "green" }[each.type] ||
                              "defaultColor"
                            }
                          >
                            {each.type?.toUpperCase()}
                          </Tag>
                          <Tag>{each.permissionToTextStatus?.toUpperCase()} CONSENT</Tag>
                        </span>
                      ),
                    }))}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="Account(s)"
          name="accountIds"
          rules={[{ required: true, message: "Select an account" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select one..."
            loading={isAccountsLoading}
            options={accounts?.map((account) => ({
              value: account.id,
              label: `ID: ${account.id}`,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="scheduledDate"
          label="Date to Send"
          rules={[{ required: true, message: "Select Date" }]}
          initialValue={moment().format(DATE_FORMAT)}
        >
          <AktDatePicker disabledDate={disabledDate} />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default SendTextModal;
