import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { selectAccountsTableSlice } from "features/accountsTable/accountsTableSlice";
import { resetMakeAPayment } from "features/payments/paymentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const StyledArrowButton = styled(Button)`
  height: 80px;
  width: 20px;
  padding: 10px;
  margin: 10px;
  padding: 0;
  font-size: 20px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  font-weight: lighter;
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border: none;
    cursor: not-allowed;
    background-color: transparent;
  }
`;

export default function DebtorNavigationArrow({ direction, disabled }) {
  const dispatch = useDispatch();
  const { debtorId: debtorIdParam } = useParams();
  const navigate = useNavigate();
  const { uniqueDebtors } = useSelector(selectAccountsTableSlice);

  const currentPosition = uniqueDebtors.findIndex(
    (debtorId) => debtorId?.toString() === debtorIdParam,
  );

  const handlePrevPage = () => {
    const debtorId = uniqueDebtors[currentPosition - 1];
    dispatch(resetMakeAPayment());
    navigate(`/debtors/${debtorId}`);
  };

  const handleNextPage = () => {
    const debtorId = uniqueDebtors[currentPosition + 1];
    dispatch(resetMakeAPayment());
    navigate(`/debtors/${debtorId}`);
  };

  const isPreviousPageDisabled = currentPosition <= 0;

  const isNextPageDisabled = currentPosition >= uniqueDebtors.length - 1;

  if (direction === "left") {
    return (
      <StyledArrowButton
        tabIndex={-1}
        onClick={handlePrevPage}
        disabled={isPreviousPageDisabled || disabled}
      >
        <LeftOutlined />
      </StyledArrowButton>
    );
  }

  if (direction === "right") {
    return (
      <StyledArrowButton
        tabIndex={-1}
        onClick={handleNextPage}
        disabled={isNextPageDisabled || disabled}
      >
        <RightOutlined />
      </StyledArrowButton>
    );
  }

  return null;
}
