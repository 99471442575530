import { Button, Table } from "antd";
import { useUserType } from "features/auth";
import { useFetchMetabaseReportsQuery as useFetchAgencyMetabaseReportsQuery } from "features/metabase/agencyPortal/metabaseAPI";
import MetabaseModal from "features/metabase/components/metabaseModal";
import { useFetchCreditorMetabaseReportsQuery } from "features/metabase/creditorPortal/metabaseAPI";
import { useRef, useState } from "react";
import { getColumnSearchProps } from "common/antd-utils";

export default function MetabaseReportsTable() {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { isLoading: isCreditorReportsLoading, data: creditorReports } =
    useFetchCreditorMetabaseReportsQuery(
      {},
      {
        skip: !isCreditorUserType,
      },
    );
  const { isLoading: isAgencyReportsLoading, data: agencyReports } =
    useFetchAgencyMetabaseReportsQuery(
      {},
      {
        skip: !isAgencyUserType,
      },
    );

  const isLoading = isCreditorReportsLoading || isAgencyReportsLoading;
  const reports = isCreditorUserType ? creditorReports : agencyReports;

  const [currentReportId, setCurrentReportId] = useState(null);
  const [open, setOpen] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState({});
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
      // @ts-ignore
      filteredValue: filteredInfo?.name ?? null,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              setCurrentReportId(record?.id);
              setOpen(true);
            }}
            type="link"
          >
            View Report
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        loading={isLoading}
        style={{ cursor: "pointer" }}
        // @ts-ignore
        columns={columns}
        dataSource={reports}
        scroll={{ x: "max-content" }}
      />
      {currentReportId && (
        <MetabaseModal
          reportId={currentReportId}
          open={open}
          onCancel={() => {
            setCurrentReportId(null);
            setOpen(true);
          }}
        />
      )}
    </>
  );
}
