import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Form, Popconfirm, Space, Table } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";
import EditableCell from "./components/editableCell";

const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;

export default function TableFormList(props) {
  const {
    disableEdit = false,
    disableDelete = false,
    disabled = false,
    columns,
    data = [],
    onSave,
    onDelete,
    loading,
    defaultValues = undefined,
    addText = "Add Row",
    // Example of this hook is FeePlanRuleset modal
    // We call it on "Add a row" button click
    preAddRowHook = undefined,
  } = props;

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [unsavedRow, setUnsavedRow] = useState(null);

  // Cycles through columns and populates with record values or default values
  const getValues = (record) => {
    return columns.reduce(
      (acc, column) => {
        const { inputType = "text", dataIndex } = column;

        const renderedInputType = typeof inputType === "function" ? inputType(record) : inputType;

        const isMultiSelect =
          renderedInputType === "select" && column.inputProps?.mode === "multiple";
        const defaultSelectValue = isMultiSelect ? [] : undefined;

        return {
          ...acc,
          ...{
            [dataIndex]:
              {
                text: record[dataIndex] ?? "",
                textArea: record[dataIndex] ?? "",
                number: record[dataIndex],
                date: record[dataIndex] ? moment(record[dataIndex]) : null,
                select: record[dataIndex] ?? defaultSelectValue,
                checkbox: record[dataIndex],
              }[renderedInputType] ?? null,
          },
        };
      },
      { key: record.key },
    );
  };

  const isEditing = (record) => record.key === editingKey;

  const handleEdit = (record) => {
    form.setFieldsValue(getValues(record));
    setEditingKey(record.key);
  };

  const handleDelete = async (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.key === item.key);
    if (index > -1) {
      await onDelete(record);
    }
  };

  const handleCancel = () => {
    // if key is unsaved, simply remove the row
    setEditingKey("");
    setUnsavedRow(null);
  };

  const handleAdd = async () => {
    if (preAddRowHook) {
      const result = await preAddRowHook();
      if (result === false) {
        return;
      }
    }
    // add a row and mark it as unsaved
    const key = Math.random().toString();
    const values = getValues({ ...defaultValues, key });
    setUnsavedRow(values);
    form.setFieldsValue(values);
    setEditingKey(key);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const row = await form.validateFields();
      if (row) {
        let payload;
        const index = data.findIndex((item) => editingKey === item.key);
        if (index > -1) {
          payload = data[index];
        }
        const result = await onSave({ ...payload, ...row, index });
        if ("data" in result) {
          setUnsavedRow(null);
          setEditingKey("");
        }
        if ("error" in result) {
          form.setFields(formatError(result.error));
        }
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    } finally {
      setIsSaving(false);
    }
  };

  const ActionsColumn = {
    title: "Actions",
    fixed: "right",
    hidden: disableEdit && disableDelete && editingKey === "",
    dataIndex: "actions",
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => handleSave()}
            style={{ marginRight: 8 }}
            loading={isSaving}
          >
            Save
          </Button>
          <Popconfirm title="Are you sure you want to cancel?" onConfirm={() => handleCancel()}>
            <a href="#cancel">Cancel</a>
          </Popconfirm>
        </Space>
      ) : (
        <Space size="middle">
          {!disableEdit && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined
                key="edit"
                disabled={editingKey !== ""}
                onClick={() => handleEdit(record)}
              />
            </AntDToolTip>
          )}
          {!disableDelete && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this?"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined key="delete" disabled={editingKey !== ""} />
            </Popconfirm>
          )}
        </Space>
      );
    },
  };

  const mergedColumns = [
    ...columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          title: col.title,
          dataIndex: col.dataIndex,
          inputType: typeof col.inputType === "function" ? col.inputType(record) : col.inputType,
          rules: typeof col.rules === "function" ? col.rules(record) : col.rules,
          options: typeof col.options === "function" ? col.options(record) : col.options,
          valuePropName: col.valuePropName,
          inputProps: col.inputProps,
          onChange: col.onChange,
          editing: isEditing(record),
        }),
      };
    }),
    ActionsColumn,
  ];

  return (
    <div style={{ paddingBottom: "16px" }}>
      <StyledButton
        icon={<PlusOutlined />}
        onClick={handleAdd}
        disabled={editingKey !== "" || disabled}
      >
        {addText}
      </StyledButton>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: "max-content" }}
          bordered
          dataSource={unsavedRow ? [...data, unsavedRow] : data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </div>
  );
}
