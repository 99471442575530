import { Col, Row } from "antd";
import { CursorTable } from "components/cursorTable";
import { formatDate, toTitleCase } from "common/utils";
import { useGetPaymentIntentsListQuery } from "features/payments/paymentsAPI";
import { useState } from "react";
import PaymentIntentActions from "pages/agencyPortal/payments/paymentIntentActions";
import styled from "styled-components";
import { PaymentIntentBulkActions } from "pages/agencyPortal/payments/paymentIntentBulkActions";

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }

  & .ant-table-cell {
    padding: 4px 8px !important;
  }
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledActionContainer = styled(Row)`
  background: #fff;
  height: 48px;
  margin-top: 1px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  justify-content: space-between;
`;

const tableColumns = [
  {
    title: "Debtor",
    dataIndex: "debtor",
    key: "debtor",
    width: 150,
    render: (field) => (field ? `${field.id} - ${field.name}` : "N/A"),
  },
  {
    title: "Account(s)",
    dataIndex: "accountIds",
    key: "accountIds",
    width: 100,
    render: (text) => text.join(", "),
  },
  {
    title: "Scheduled Date",
    dataIndex: "scheduledDate",
    key: "scheduledDate",
    width: 100,
    render: (text) => formatDate(text),
  },
  {
    title: "Created at Date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 100,
    render: (text) => formatDate(text),
  },
  {
    title: "Payment Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    width: 100,
    render: (text) => `$${text}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 100,
    key: "status",
  },
  {
    title: "Is Settlement?",
    dataIndex: "isSettlement",
    key: "isSettlement",
    width: 100,
    render: (text) => (text ? "Yes" : "No"),
  },
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    width: 100,
    render: (text, record) => {
      const { type, cardLast4Digits, bankAccountLast4Digits } = record.paymentMethod ?? {};
      if (type === "process_card") {
        return `Card ending in ${cardLast4Digits}`;
      }
      if (type === "process_ach") {
        return `ACH account ending in ${bankAccountLast4Digits}`;
      }
      if (type === "paid_to_creditor_legal") {
        return `Paid to Creditor (Legal Payment)`;
      }
      if (type === "forwarding_entity_legal") {
        return `Paid to Forwarding Entity (Legal Payment)`;
      }
      return toTitleCase({ str: type, delimiter: "_" });
    },
  },
  {
    title: "Reference #",
    dataIndex: "referenceNumber",
    key: "referenceNumber",
    width: 100,
    render: (text) => text ?? "-",
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    width: 150,
    render: (text) => text ?? "-",
  },
  {
    title: "Actions",
    fixed: "right",
    align: "center",
    width: 50,
    dataIndex: "actions",
    render: (text, record) => <PaymentIntentActions record={record} />,
  },
];

const gotoDebtorProfile = (record) => {
  const url = `/debtors/${record.debtor.id}`;
  const newWindow = window.open(url, "_blank");
  if (newWindow) newWindow.opener = null;
};

function PaymentsIntentTable({ filterParams }) {
  const [pagination, setPagination] = useState({
    virtualPage: 1,
    pageSize: 100,
    prevToken: null,
    nextToken: null,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    data: paymentIntents,
    isLoading,
    isFetching,
  } = useGetPaymentIntentsListQuery({
    ...filterParams,
    nextToken: pagination.nextToken,
    prevToken: pagination.prevToken,
    pageSize: pagination.pageSize,
    includeResultCount: true,
  });

  const handleTableChange = (newPagination, _, sorter) => {
    setPagination(newPagination);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
  };

  // Needed because the actions column is fixed and virtually rendered so that pointer-events: none does not work
  const isActionsColumn = (element) => {
    return (
      element.classList.contains("ant-table-cell-fix-right") ||
      element.classList.contains("ant-dropdown-menu-item") ||
      element.classList.contains("ant-popover") ||
      element.classList.contains("ant-popconfirm") ||
      element.nextSibling === null ||
      element.tagName === "svg" ||
      element.tagName === "path"
    );
  };

  return (
    <StyledCol>
      <StyledActionContainer>
        <StyledActions>
          {/* Add button to delete */}
          <PaymentIntentBulkActions dataSource={selectedRows} />
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        bordered
        sticky
        scroll={{ x: "max-content" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        pageSize={pagination.pageSize}
        prevToken={paymentIntents?.meta?.prevToken}
        nextToken={paymentIntents?.meta?.nextToken}
        resultCount={paymentIntents?.meta?.resultCount}
        virtualPage={pagination.virtualPage}
        setVirtualPage={(page) => setPagination((prev) => ({ ...prev, virtualPage: page }))}
        columns={tableColumns}
        dataSource={paymentIntents?.results}
        loading={isLoading || isFetching}
        onRow={(record) => ({
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            // allow users to select text so it does not trigger a click into the row
            const selection = window.getSelection().toString();
            if (selection.length <= 0 && !isLoading && !isActionsColumn(e.target)) {
              gotoDebtorProfile(record);
            }
          },
        })}
        onChange={handleTableChange}
      />
    </StyledCol>
  );
}
export default PaymentsIntentTable;
