import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Popconfirm, Row, Space, Table, Tag, message } from "antd";
import { PageHeader } from "components/pageHeader";
import {
  INITIAL_WORKLIST_ID,
  selectAccountsTableSlice,
  setWorklistId,
} from "features/accountsTable/accountsTableSlice";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import {
  activeUsersSelector,
  useFetchUsersQuery,
} from "features/userManagementTable/userManagementAPI";
import AddWorklistModal from "features/workList/components/addWorklistModal";
import { useDeleteWorklistMutation, useFetchWorklistsQuery } from "features/workList/workListAPI";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  border-left: 1px solid #f0f0f0;
  height: 100%;
  align-items: center;
`;

const StyledTable = styled(Table)`
  white-space: pre;
  cursor: pointer;
  .workList-noclick {
    pointer-events: none;
  }

  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }
`;

const ButtonWithMargin = styled(Button)`
  margin: 0px 10px;
  color: #444;
  &:hover {
    background: #f0f0f0;
  }
`;

const StyledActionContainer = styled(Row)`
  margin-top: 16px;
  background: #fff;
  height: 48px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

function WorklistList() {
  // redux hooks
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const dispatch = useDispatch();

  // state hooks
  const [showAddWorklistModal, setShowAddWorklistModal] = useState(false);
  const [currentWorklist, setCurrentWorklist] = useState({});

  // data hooks
  const [deleteWorklist] = useDeleteWorklistMutation();
  const {
    data: workList = [],
    isLoading: isFetchWorklistsLoading,
    isFetching: isFetchWorklistsFetching,
    refetch: refetchWorklists,
  } = useFetchWorklistsQuery();
  const { activeUsers: users, isLoading: isUsersLoading } = useFetchUsersQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeUsers: activeUsersSelector(result),
    }),
  });

  // permission hooks
  const {
    data: {
      [PERMISSIONS.WORKLIST__GET]: isWorklistGetAuthorized,
      [PERMISSIONS.WORKLIST__DELETE]: isWorklistDeleteAuthorized,
      [PERMISSIONS.WORKLIST__UPDATE]: isWorklistUpdateAuthorized,
    },
  } = useAuthorizations();

  const handleDelete = useCallback(
    async (record) => {
      const result = await deleteWorklist({ id: record.id });
      if ("error" in result) {
        message.error("Failed to delete workList");
        return;
      }
      // We need to reset selected worklistId if it is deleted
      if (accountsTableSlice?.worklistId === record.id) {
        dispatch(setWorklistId(INITIAL_WORKLIST_ID));
      }
      message.success("Worklist was deleted successfully");
    },
    [accountsTableSlice?.worklistId, deleteWorklist, dispatch],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (text, record) => {
            const name = record.name || `Worklist ${record.id}`;
            const systemTag = record.isSystem ? <Tag color="blue">System</Tag> : null;
            return (
              <Space>
                {name}
                {systemTag}
              </Space>
            );
          },
        },
        {
          title: "Roles",
          dataIndex: "groups",
          key: "groups",
          render: (groups) => {
            return (
              <>
                {(groups ?? []).map((group) => (
                  <Tag>{group.name}</Tag>
                ))}
              </>
            );
          },
        },
        {
          title: "Users",
          dataIndex: "userIds",
          key: "userIds",
          render: (userIds) => {
            return (
              <>
                {(users ?? [])
                  .filter((user) => (userIds ?? []).includes(user.id))
                  .map((user) => (
                    <Tag>{user.name}</Tag>
                  ))}
              </>
            );
          },
        },
        (isWorklistDeleteAuthorized || isWorklistUpdateAuthorized) && {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (text, record) => (
            <Space size="middle">
              {isWorklistUpdateAuthorized && (
                <AntDToolTip placement="bottom" title="Edit" key="edit">
                  <EditOutlined
                    key="edit"
                    onClick={() => {
                      setShowAddWorklistModal(true);
                      setCurrentWorklist(record);
                    }}
                  />
                </AntDToolTip>
              )}
              {isWorklistDeleteAuthorized && !record.isSystem && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to delete this?"
                  onConfirm={() => handleDelete(record)}
                >
                  <DeleteOutlined key="delete" />
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ].filter(Boolean),
    [handleDelete, isWorklistDeleteAuthorized, isWorklistUpdateAuthorized, users],
  );

  const isLoading = isFetchWorklistsLoading || isFetchWorklistsFetching || isUsersLoading;

  return isWorklistGetAuthorized ? (
    <>
      <StyledRow align="middle">
        <PageHeader>Worklists</PageHeader>
        <Button onClick={() => setShowAddWorklistModal(true)} icon={<PlusOutlined />} type="link">
          Add Worklist
        </Button>
      </StyledRow>
      <StyledActionContainer>
        <StyledActions wrap={false}>
          <ButtonWithMargin
            shape="circle"
            icon={<ReloadOutlined />}
            type="text"
            onClick={refetchWorklists}
          />
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        pagination={{ pageSize: 100 }}
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={workList}
      />
      {showAddWorklistModal && (
        <AddWorklistModal
          title={currentWorklist ? "Edit Worklist" : "Add Worklist"}
          onCancel={() => {
            setShowAddWorklistModal(false);
            setCurrentWorklist(undefined);
          }}
          onOk={() => {
            setShowAddWorklistModal(false);
            setCurrentWorklist(undefined);
          }}
          worklist={currentWorklist}
        />
      )}
    </>
  ) : null;
}

export default WorklistList;
