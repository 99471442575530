import { Dropdown, Form, Input, Select } from "antd";
import { useState } from "react";
import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
  .ant-input-affix-wrapper {
    height: 37px;
  }

  .rc-virtual-list-holder {
    max-height: 320px; !important;
  }
`;

const StyledSearch = styled(Input.Search)`
  height: 36px;
  width: 500px;
  & .ant-input-affix-wrapper {
    border-radius: 0 !important;
  }

  & input {
    height: 27px !important;
  }

  & button {
    height: 37px !important;
  }
`;

export default function SearchWithDropdown({
  initialValues = { query: "" },
  searchHook,
  searchResultGetter = (searchResult) => ({
    label: searchResult,
  }),
  inputProps = {},
  selectProps = {},
}) {
  const [form] = Form.useForm();
  const [isOpen, setOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [request, setRequest] = useState();
  const [onSearch, { data: searchResults, isLoading, isFetching }] = searchHook();

  const onFinish = async (values) => {
    const { query, queryFieldType } = values;
    if (query && query.length > 0) {
      // Abort previous request
      if (request) {
        // @ts-ignore
        request.abort();
      }
      const req = onSearch({ query, queryFieldType });
      setRequest(req);
      await req;
      // open dropdown on search
      setOpen(true);
      // search in progress, so we are not initial anymore
      setIsInitialLoad(false);
    }
  };

  const items = (() => {
    // During initial load
    if (isInitialLoad) {
      return [
        {
          label: "Loading...",
        },
      ];
    }

    if (searchResults?.length <= 0) {
      return [
        {
          label: "No results found",
          disabled: isFetching,
        },
      ];
    }

    return (
      searchResults?.map(searchResultGetter).map((searchResult) => ({
        label: searchResult.label,
        disabled: isFetching,
      })) ?? []
    );
  })();

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <StyledDropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        open={isOpen}
      >
        <Form.Item noStyle name="query">
          <StyledSearch
            addonBefore={
              // @ts-ignore
              selectProps?.options && (
                <Form.Item noStyle name="queryFieldType">
                  <Select
                    popupMatchSelectWidth={false}
                    placeholder="Select one..."
                    onChange={() => {
                      form.setFieldsValue({ query: initialValues?.query });
                      // we start over when dropdown is changed
                      setIsInitialLoad(true);
                    }}
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        // when select is opened, close the dropdown
                        setOpen(false);
                      }
                    }}
                    {...selectProps}
                  />
                </Form.Item>
              )
            }
            onChange={(e) => {
              if (e.target.value.length > 0) {
                // @ToDo: uncomment when we confident enough
                // form.submit();
                // setOpen(true);
              } else {
                // if searchquery is empty, we start over
                setIsInitialLoad(true);
                setOpen(false);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                // close dropdown on escape
                setOpen(false);
              }
              if (e.key === "Enter") {
                // prevent second form submission
                e.preventDefault();
              }
            }}
            loading={isLoading || isFetching}
            onSearch={form.submit}
            allowClear
            enterButton
            {...inputProps}
          />
        </Form.Item>
      </StyledDropdown>
    </Form>
  );
}
