import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Popconfirm, Row, Select, Space, Tag, message } from "antd";
import { DATE_TIME_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { camelToWords, snakeToCamelCase } from "common/utils";
import { DisplayAddress, DisplayComponent } from "components/formItems";
import { useUserType } from "features/auth";
import {
  useCreateDebtorAddressesMutation,
  useDeleteDebtorAddressesMutation,
  useUpdateDebtorAddressesMutation,
} from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  margin-top: 0px;
  margin-left: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledTag = styled(Tag)`
  margin-right: 0;
  font-weight: normal;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export function DebtorAddressInfoCard({
  address = undefined,
  isNew = undefined,
  onCreate = undefined,
  onCancel = undefined,
  title = undefined,
  profileType = "consumer",
}) {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const [isEditing, setIsEditing] = useState(isNew ?? false);
  const { data: constants } = useFetchBackendConstantsQuery();
  const [form] = Form.useForm();
  const [updateDebtorAddress, { isLoading: isUpdateLoading }] = useUpdateDebtorAddressesMutation();
  const [createDebtorAddress, { isLoading: isCreateLoading }] = useCreateDebtorAddressesMutation();
  const [deleteDebtorAddress, { isLoading: isDeleteLoading }] = useDeleteDebtorAddressesMutation();

  const {
    data: {
      [PERMISSIONS.DEBTOR_ADDRESS__CREATE]: isAddDebtorAddressAuthorized,
      [PERMISSIONS.DEBTOR_ADDRESS__UPDATE]: isUpdateDebtorAddressAuthorized,
      [PERMISSIONS.DEBTOR_ADDRESS__DELETE]: isDeleteDebtorAddressAuthorized,
    },
  } = useAuthorizations();

  const editableDataSourceTypeValues = constants?.editableDataSourceTypes?.map(
    ({ value }) => value,
  );

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const deleteAddress = async () => {
    const result = await deleteDebtorAddress({ debtorId, addressId: address.id });
    if ("data" in result) {
      message.success("Address deleted successfully!");
    }
  };

  const toggleIsEditing = async () => {
    if (isEditing) {
      let values;
      try {
        values = await form.validateFields();
      } catch (e) {
        return null; // Form will handle error messages
      }

      const action = isNew
        ? createDebtorAddress({ debtorId, ...values })
        : updateDebtorAddress({
            debtorId,
            addressId: address.id,
            ...values,
          });
      const result = await action;

      if ("data" in result) {
        message.success("Saved successfully!");
        if (isNew) onCreate();
      }

      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error(`Could not ${isNew ? "create" : "update"} address`);
        return;
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
      form.setFieldsValue(address);
    }
  };

  const onPrimaryCancel = () => {
    setIsEditing(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 20 },
      sm: { span: 6 },
    },
  };

  const consumerAddressTypes = constants?.addressTypes.map(({ display, value }) => ({
    value,
    label: display,
  }));

  const commercialAddressTypes = constants?.addressTypes
    .filter((type) => type.value !== "home")
    .map(({ display, value }) => ({
      value,
      label: display,
    }));

  return (
    <StyledCard>
      <StyledHeader>
        <h4>
          <Space size="small">
            {isNew ? "New Address" : title}
            {address?.isPrimary && !isEditing && <StyledTag color="gold">PRIMARY</StyledTag>}
            {address?.dataSource === "skip_trace" && !isEditing && (
              <StyledTag color="magenta">SKIPTRACE</StyledTag>
            )}
          </Space>
        </h4>
        {!isEditing &&
          isAgencyUserType &&
          (isUpdateDebtorAddressAuthorized || isDeleteDebtorAddressAuthorized) && (
            <Row>
              {isUpdateDebtorAddressAuthorized && (
                <Button type="link" icon={<EditOutlined />} onClick={toggleIsEditing} />
              )}
              {!address?.isPrimary && isDeleteDebtorAddressAuthorized && (
                <Popconfirm
                  placement="topRight"
                  okText="Yes"
                  title="Are you sure you want to delete this address?"
                  onConfirm={deleteAddress}
                >
                  <Button loading={isDeleteLoading} type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Row>
          )}
      </StyledHeader>
      {isEditing ? (
        <>
          <Form
            labelAlign="left"
            form={form}
            {...formItemLayout}
            initialValues={{ dataSource: "" }}
          >
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="Primary"
              name="isPrimary"
              valuePropName="checked"
              hidden={address?.isPrimary}
            >
              <Checkbox />
            </DisplayComponent>
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="Address"
              name="address1"
              rules={[{ required: true }]}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="Address 2"
              name="address2"
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="City"
              name="city"
              rules={[
                { required: true },
                { pattern: /^[^\d]*$/, message: "Numbers not allowed here" },
              ]}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="State"
              name="state"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={filterOption}
                options={constants?.states.map((state) => ({
                  label: `${state.display} (${state.value})`,
                  value: state.value,
                }))}
              />
            </DisplayComponent>
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="Zip Code"
              name="zipCode"
              maxLength={5}
              rules={[
                { required: true },
                {
                  pattern: /^\d{5}(-\d{4})?$/,
                  message: "Must be a valid zip code",
                },
              ]}
            />
            <DisplayComponent
              isEditing={isEditing}
              slice={address}
              label="Type"
              name="type"
              rules={[{ required: true }]}
            >
              <Select
                options={profileType === "consumer" ? consumerAddressTypes : commercialAddressTypes}
              />
            </DisplayComponent>
            <DisplayComponent isEditing={isEditing} slice={address} label="Notes" name="notes">
              <Input.TextArea placeholder="Add Notes" />
            </DisplayComponent>
            {/* Only allow setting of data source if no data source is set, or it is editable (i.e. present in the editableDataSourceTypes) */}
            {(!address?.dataSource ||
              editableDataSourceTypeValues.includes(address?.dataSource)) && (
              <DisplayComponent
                isEditing={isEditing}
                slice={address}
                label="Data Source"
                name="dataSource"
              >
                <Select
                  options={constants?.editableDataSourceTypes?.map(({ display, value }) => ({
                    value,
                    label: display,
                  }))}
                />
              </DisplayComponent>
            )}
          </Form>
          <StyledButtonContainer>
            <Space>
              <Button onClick={isNew ? onCancel : onPrimaryCancel}>Cancel</Button>
              {(isAddDebtorAddressAuthorized || isUpdateDebtorAddressAuthorized) && (
                <Button
                  loading={isCreateLoading || isUpdateLoading}
                  onClick={toggleIsEditing}
                  type="primary"
                >
                  Save
                </Button>
              )}
            </Space>
          </StyledButtonContainer>
        </>
      ) : (
        <StyledDiv>
          <DisplayAddress
            isEditing={isEditing}
            slice={address}
            label="Address"
            rules={[{ required: true }]}
          />
          <DisplayComponent
            isEditing={isEditing}
            slice={address}
            label="Data Source"
            name="dataSource"
            outputFormatter={(value) => camelToWords(snakeToCamelCase(value))}
          />
          <DisplayComponent
            isEditing={isEditing}
            slice={address}
            label="Created At"
            name="createdAt"
            outputFormatter={(value) => moment(value).format(DATE_TIME_FORMAT)}
          />
        </StyledDiv>
      )}
    </StyledCard>
  );
}
