import { InputNumber, Radio, Select } from "antd";
import { booleanToYesNo } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { EditableCardComponent } from "components/editableCardComponent";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";

const REPORTER_TYPE_OPTIONS = [
  {
    label: "Collection Agency",
    value: "collection_agency",
  },
  {
    label: "Traditional Bank",
    value: "traditional_bank",
  },
  {
    label: "Digital Bank",
    value: "digital_bank",
  },
  {
    label: "Credit Union",
    value: "credit_union",
  },
  {
    label: "Credit Card Issuer",
    value: "credit_card_issuer",
  },
  {
    label: "Mortgage Lender",
    value: "mortgage_lender",
  },
  {
    label: "Auto Loan Lender",
    value: "auto_loan_lender",
  },
];

const REPORT_ACCOUNTS_PAID_IN_FULL_OPTIONS = [
  {
    label: "Delete Account",
    value: "DA",
  },
  {
    label: "Paid in Full",
    value: "62",
  },
];

export default function CreditorCreditReportingCard({ currentCreditor, onSave }) {
  const { data: canEditClientProfileCreditReporting } = useAuthorization(
    PERMISSIONS.CLIENT_PROFILE__CAN_EDIT_CREDIT_REPORTING,
  );
  const { data: { cbrHoldDateTypes = [] } = {} } = useFetchBackendConstantsQuery();
  const rows = [
    {
      label: "Run Credit Reporting",
      name: ["creditReportingConfig", "isEnabled"],
      formElement: (
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      formItemProps: {
        tooltip: "Does this creditor want credit reporting for their accounts?",
      },
      render: (_, record) => booleanToYesNo(record?.creditReportingConfig?.isEnabled),
    },
    {
      label: "Minimum Account Balance Required to Credit Report",
      name: ["creditReportingConfig", "minBalance"],
      formElement: <InputNumber min={0} />,
    },
    {
      label: "Maximum Account Balance Required to Send - (Coming Soon)",
      name: ["creditReportingConfig", "maxBalance"],
      formElement: <InputNumber min={0} />,
    },
    {
      label: "Report Interest to Credit Bureau",
      name: ["creditReportingConfig", "includeInterest"],
      formElement: (
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      render: (_, record) => booleanToYesNo(record?.creditReportingConfig?.includeInterest),
    },
    {
      label: "Report Fees to Credit Bureau",
      name: ["creditReportingConfig", "includeFees"],
      formElement: (
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      render: (_, record) => booleanToYesNo(record?.creditReportingConfig?.includeFees),
    },
    {
      label: "Report Accounts Start Date - (Coming Soon)",
      name: ["creditReportingConfig", "startDate"],
      formElement: <AktDatePicker />,
      formItemProps: {
        tooltip: "The date the AGENCY starts reporting accounts.",
      },
    },
    {
      label: "Credit Bureau Days To Hold Type [Primary]",
      name: ["creditReportingConfig", "daysToHoldType"],
      formElement: (
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={cbrHoldDateTypes.map((each) => ({
            label: each.display,
            value: each.value,
          }))}
        />
      ),
      render: (_, record) => {
        const daysToHoldType = cbrHoldDateTypes.find(
          (x) => x.value === record?.creditReportingConfig?.daysToHoldType,
        );
        return daysToHoldType?.display;
      },
    },
    {
      label: "# of Days to Hold From Credit Bureau [Primary]",
      name: ["creditReportingConfig", "daysToHold"],
      formElement: <InputNumber min={0} />,
      formItemProps: {
        tooltip:
          "How many days before an account can be reported (starting from the selected days to hold type)?",
      },
    },
    {
      label: "Credit Bureau Days To Hold Type [Secondary]",
      name: ["creditReportingConfig", "secondaryDaysToHoldType"],
      formElement: (
        <Select
          allowClear
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={cbrHoldDateTypes.map((each) => ({
            label: each.display,
            value: each.value,
          }))}
        />
      ),
      render: (_, record) => {
        const secondaryDaysToHoldType = cbrHoldDateTypes.find(
          (x) => x.value === record?.creditReportingConfig?.secondaryDaysToHoldType,
        );
        return secondaryDaysToHoldType?.display;
      },
    },
    {
      label: "# of Days to Hold From Credit Bureau [Secondary]",
      name: ["creditReportingConfig", "secondaryDaysToHold"],
      formElement: <InputNumber min={0} />,
      formItemProps: {
        tooltip:
          "How many days before an account can be reported (starting from the selected days to hold type)?",
      },
    },
    {
      label: "Report to Credit Bureau As",
      name: ["creditReportingConfig", "reporterType"],
      formElement: <Select placeholder="Select a type" options={REPORTER_TYPE_OPTIONS} />,
      render: (_, record) => {
        const reporterType = REPORTER_TYPE_OPTIONS.find(
          (x) => x.value === record?.creditReportingConfig?.reporterType,
        );
        return reporterType?.label;
      },
    },
    {
      label: "Report Accounts Paid in Full / Settled in Full As",
      name: ["creditReportingConfig", "paidInFullAccountStatusCode"],
      formElement: (
        <Select
          popupMatchSelectWidth={false}
          placeholder="Select a type"
          options={REPORT_ACCOUNTS_PAID_IN_FULL_OPTIONS}
        />
      ),
      render: (_, record) => {
        const paidInFullAccountStatusCode = REPORT_ACCOUNTS_PAID_IN_FULL_OPTIONS.find(
          (x) => x.value === record?.creditReportingConfig?.paidInFullAccountStatusCode,
        );
        return paidInFullAccountStatusCode?.label;
      },
    },
  ];

  return (
    <EditableCardComponent
      hasEditPermission={canEditClientProfileCreditReporting}
      title="Credit Reporting"
      record={currentCreditor}
      rows={rows}
      onSave={onSave}
    />
  );
}
