import { SettingOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Layout, Row, Skeleton, Tooltip } from "antd";
import { TriggerModal } from "components/triggerModal";
import { useFetchCreditorPortalDebtorProfileQuery } from "features/accountsTable/creditorPortal/accountsTableAPI";
import TableAppearanceModal, {
  reconcileColumns,
} from "features/appearance/components/tableAppearanceModal";
import { SIDER_ITEMS } from "features/appearance/constants";
import { DebtorAddressInfoCard } from "features/debtorSider/components/debtorAddressInfoCard";
import { DebtorContactInfoCard } from "features/debtorSider/components/debtorContactInfoCard";
import { DebtorEmailInfoCard } from "features/debtorSider/components/debtorEmailInfoCard";
import { DebtorEmploymentInfoCard } from "features/debtorSider/components/debtorEmploymentInfoCard";
import { DebtorInfoCard } from "features/debtorSider/components/debtorInfoCard";
import { DebtorPhoneInfoCard } from "features/debtorSider/components/debtorPhoneInfoCard";

import {
  useFetchCreditorPortalDebtorAddressesQuery,
  useFetchCreditorPortalDebtorContactsQuery,
  useFetchCreditorPortalDebtorEmailsQuery,
  useFetchCreditorPortalDebtorEmploymentsQuery,
  useFetchCreditorPortalDebtorPhoneNumbersQuery,
} from "features/debtorSider/creditorPortal/debtorSiderAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useAppearance } from "providers/appearanceProvider";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledSider = styled(Layout.Sider)`
  padding: 12px;
  overflow: scroll;
  left: 0px;
  top: 145px;
  bottom: 0px;
  &&& {
    position: fixed;
    background: #fff;
  }
`;

const StyledHeaderName = styled.h4`
  margin-left: 8px;
`;

const StyledContainer = styled.div`
  position: fixed;
  width: 400px;
  left: 0;
  padding-top: 12px;
  background: #fff;
`;

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  background-color: #0b3948;
`;

const StyledDebtorName = styled.h2`
  text-align: center;
  text-transform: capitalize;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
`;

const StyledInfoContainer = styled.div``;

export const SIDER_ID = "debtorSider";

function DebtorSider() {
  const { debtorId } = useParams();
  const {
    data: debtorPersonalInfo,
    isLoading: isDebtorPersonalInfoLoading,
    isError: isDebtorPersonalInfoError,
  } = useFetchCreditorPortalDebtorProfileQuery({ debtorId });
  const { data: debtorAddresses, isLoading: isDebtorAddressesLoading } =
    useFetchCreditorPortalDebtorAddressesQuery(
      { debtorId },
      { skip: isDebtorPersonalInfoLoading || isDebtorPersonalInfoError },
    );
  const { data: debtorContacts, isLoading: isDebtorContactsLoading } =
    useFetchCreditorPortalDebtorContactsQuery(
      {
        debtorId,
      },
      { skip: isDebtorPersonalInfoLoading || isDebtorPersonalInfoError },
    );
  const { data: debtorPhoneNumbers, isLoading: isDebtorPhoneNumbersLoading } =
    useFetchCreditorPortalDebtorPhoneNumbersQuery(
      { debtorId },
      { skip: isDebtorPersonalInfoLoading || isDebtorPersonalInfoError },
    );
  const { data: debtorEmails, isLoading: isDebtorEmailsLoading } =
    useFetchCreditorPortalDebtorEmailsQuery(
      {
        debtorId,
      },
      { skip: isDebtorPersonalInfoLoading || isDebtorPersonalInfoError },
    );
  const { data: debtorEmployments, isLoading: isDebtorEmploymentsLoading } =
    useFetchCreditorPortalDebtorEmploymentsQuery(
      { debtorId },
      { skip: isDebtorPersonalInfoLoading || isDebtorPersonalInfoError },
    );
  const { isLoading: isAppearanceLoading, appearance } = useAppearance();

  const {
    data: {
      [PERMISSIONS.DEBTOR_ADDRESS__GET]: isGetDebtorAddressAuthorized,
      [PERMISSIONS.DEBTOR_CONTACT__GET]: isGetDebtorContactAuthorized,
      [PERMISSIONS.DEBTOR_EMPLOYMENT__GET]: isGetDebtorEmploymentAuthorized,
      [PERMISSIONS.DEBTOR_PHONE_NUMBER__GET]: isGetDebtorPhoneNumberAuthorized,
      [PERMISSIONS.DEBTOR_EMAIL__GET]: isGetDebtorEmailAuthorized,
      [PERMISSIONS.APPEARANCE__UPDATE]: isAppearanceUpdateAuthorized,
    },
  } = useAuthorizations();

  const isLoading =
    isDebtorPersonalInfoLoading ||
    isDebtorAddressesLoading ||
    isDebtorContactsLoading ||
    isDebtorPhoneNumbersLoading ||
    isDebtorEmploymentsLoading ||
    isDebtorEmailsLoading ||
    isAppearanceLoading;

  const debtorDisplayName = [
    debtorPersonalInfo?.firstName,
    debtorPersonalInfo?.middleName,
    debtorPersonalInfo?.lastName,
  ]
    .filter((x) => x)
    .join(" ");

  const defaultItems = [
    {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_INFO,
      visible: true,
      title: "Debtor Info",
      render: () => <DebtorInfoCard profileType={debtorPersonalInfo?.type} />,
    },
    isGetDebtorPhoneNumberAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__PHONE_NUMBERS,
      visible: true,
      title: "Phone Numbers",
      render: (item) => (
        <span key={item.key}>
          {debtorPhoneNumbers?.map((phone, index) => (
            <DebtorPhoneInfoCard
              key={index}
              phone={phone}
              profileType={debtorPersonalInfo?.type}
              title={index === 0 ? "Phone" : `Phone (${index + 1})`}
            />
          ))}
          {debtorPhoneNumbers?.length === 0 && <StyledHeaderName>Phones</StyledHeaderName>}
        </span>
      ),
    },
    isGetDebtorEmailAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_EMAILS,
      visible: true,
      title: "Emails",
      render: (item) => (
        <span key={item.key}>
          {debtorEmails?.map((email, index) => (
            <DebtorEmailInfoCard
              key={email.id}
              profileType={debtorPersonalInfo?.type}
              email={email}
              title={index === 0 ? "Email" : `Email (${index + 1})`}
            />
          ))}
          {debtorEmails?.length === 0 && <StyledHeaderName>Email</StyledHeaderName>}
        </span>
      ),
    },
    isGetDebtorAddressAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_ADDRESSES,
      visible: true,
      title: "Addresses",
      render: (item) => (
        <span key={item.key}>
          {debtorAddresses?.map((address, index) => (
            <DebtorAddressInfoCard
              key={index}
              profileType={debtorPersonalInfo?.type}
              address={address}
              title={index === 0 ? "Address" : `Address (${index + 1})`}
            />
          ))}
          {debtorAddresses?.length === 0 && <StyledHeaderName>Address</StyledHeaderName>}
        </span>
      ),
    },

    isGetDebtorContactAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_CONTACTS,
      visible: true,
      title: "Contacts",
      render: (item) => (
        <span key={item.key}>
          {debtorContacts
            ?.filter((contact) => contact.relationshipToDebtor !== "debtor")
            ?.map((debtorContact, index) => (
              <DebtorContactInfoCard
                key={index}
                profileType={debtorPersonalInfo?.type}
                debtorContact={debtorContact}
                title={
                  index === 0
                    ? `${
                        debtorPersonalInfo?.type === "consumer"
                          ? "Debtor Contact"
                          : "Contact Person"
                      }`
                    : `${
                        debtorPersonalInfo?.type === "consumer"
                          ? `Debtor Contact (${index + 1})`
                          : `Contact Person (${index + 1})`
                      }`
                }
              />
            ))}
          {debtorContacts?.filter((contact) => contact.relationshipToDebtor !== "debtor")
            ?.length === 0 && (
            <StyledHeaderName>
              {debtorPersonalInfo?.type === "consumer" ? "Debtor Contact" : "Contact Person"}
            </StyledHeaderName>
          )}
        </span>
      ),
    },
    isGetDebtorEmploymentAuthorized && {
      key: SIDER_ITEMS.DEBTOR_SIDER__DEBTOR_EMPLOYMENTS,
      visible: true,
      title: "Employments",
      render: (item) => (
        <span key={item.key}>
          {debtorPersonalInfo?.type === "consumer" &&
            debtorEmployments?.map((employment, index) => (
              <DebtorEmploymentInfoCard
                key={index}
                employment={employment}
                title={index === 0 ? "Employment" : `Employment (${index + 1})`}
              />
            ))}
          {debtorPersonalInfo?.type === "consumer" && debtorEmployments?.length === 0 && (
            <StyledHeaderName>Employment</StyledHeaderName>
          )}
        </span>
      ),
    },
  ].filter(Boolean); // .filter(Boolean) is a shortcut for .filter(item => !item)

  const reconciledItems = reconcileColumns(defaultItems, appearance?.items?.[SIDER_ID]).filter(
    (column) => column.visible,
  );

  if (
    appearance?.items?.[SIDER_ID]?.hidePanel || // Hiding panel in appearances
    isAppearanceLoading || // Appearance is still loading
    isDebtorPersonalInfoError // Debtor personal info failed to load
  ) {
    return null;
  }

  return (
    <>
      <StyledContainer>
        <StyledProfileContainer>
          <Row align="middle">
            <StyledAvatar size={40}>
              {(debtorDisplayName || debtorPersonalInfo?.companyName)?.split(" ").map((c) => c[0])}
            </StyledAvatar>
            <Tooltip title={debtorDisplayName || debtorPersonalInfo?.companyName} placement="right">
              <StyledDebtorName>
                {(debtorDisplayName || debtorPersonalInfo?.companyName)?.toLowerCase()}
              </StyledDebtorName>
            </Tooltip>
          </Row>
        </StyledProfileContainer>
        {isAppearanceUpdateAuthorized && (
          <TriggerModal
            modal={TableAppearanceModal}
            tableId={SIDER_ID}
            defaultColumns={defaultItems}
            mode="sider"
          >
            <Tooltip title="Customize Appearance">
              <Button shape="circle" icon={<SettingOutlined />} type="text" />
            </Tooltip>
          </TriggerModal>
        )}
      </StyledContainer>
      <StyledSider width={400} theme="light">
        {isLoading ? (
          <Skeleton active />
        ) : (
          <StyledInfoContainer>
            {reconciledItems.map((item) => (
              <>
                {item.render(item)}
                <Divider />
              </>
            ))}
            {/* <Sider width={400} style={{ padding: "15px", color: "#fff" }}>
          <Form
            //   style={{ background: "#fff" }}
            form={form}
            {...formItemLayout}
            labelAlign="left"
            validateMessages={{ required: "This is a required field" }}
          >
            <Form.Item name="cbrClassCodes" label="CBR Class Codes">
              <Select mode="multiple">
                {constants?.cbrClassCodes.map((d) => (
                  <Option key={d.value} value={d.value}>
                    {d.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="debtorStates" label="States">
              <Select mode="multiple">
                {constants?.states.map((state) => (
                  <Option key={state.value} value={state.value}>
                    {state.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="alpha1"
              label="Alphabetical (From)"
              rules={[
                {
                  pattern: /[A-Z]{1}/,
                  message: "Must be a single character from A-Z",
                },
              ]}
            >
              <Input maxLength={1} />
            </Form.Item>
            <Form.Item
              name="alpha2"
              label="Alphabetical (To)"
              rules={[
                {
                  pattern: /[A-Z]{1}/,
                  message: "Must be a single character from A-Z",
                },
              ]}
            >
              <Input maxLength={1} />
            </Form.Item>
            <Form.Item name="dollars1" label="Account Balance (From)">
              <InputNumber />
            </Form.Item>
            <Form.Item name="dollars2" label="Account Balance (To)">
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="age1"
              label="Days Since Upload (From)"
              rules={[
                {
                  pattern: /[0-9]{2}/,
                  message: "Enter a valid age",
                },
              ]}
            >
              <InputNumber maxLength={2} />
            </Form.Item>
            <Form.Item
              name="age2"
              label="Days Since Upload (To)"
              rules={[
                {
                  pattern: /[0-9]{2}/,
                  message: "Enter a valid age",
                },
              ]}
            >
              <InputNumber maxLength={2} />
            </Form.Item>
            <Form.Item name="creditorIds" label="Clients">
              <Select mode="multiple">
                {creditorsSlice.creditors.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="languages" label="Languages">
              <Select mode="multiple">
                {constants?.languages.map((l) => (
                  <Option key={l.value} value={l.value}>
                    {l.display}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Sider> */}
          </StyledInfoContainer>
        )}
      </StyledSider>
    </>
  );
}

export default DebtorSider;
