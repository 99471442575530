import { Form, Input, Modal, Radio, Select, Skeleton, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import { useFetchSmsTemplatesQuery } from "features/bulkActions/textTemplatesAPI";
import {
  useCreateCommunicationMethodMutation,
  useUpdateCommunicationMethodMutation,
} from "features/communicationMethodsTable/communicationMethodsAPI";
import { EMAIL_PROVIDERS } from "features/emailProviders/constants";
import { useGetEmailTemplatesQuery } from "features/emailTemplates/emailTemplatesAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchLetterTemplatesQuery } from "features/letterTemplates/letterTemplatesAPI";
import { useFetchAiDialerTemplatesQuery } from "features/aiDialerTemplates/aiDialerTemplatesAPI";
import { useEffect } from "react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const SMS_PROVIDERS = [
  {
    label: "Solutions By Text",
    value: "solutions_by_text",
  },
  {
    label: "TCN",
    value: "tcn",
  },
];

const VOICEMAIL_PROVIDERS = [
  {
    label: "TCN",
    value: "tcn",
  },
];

const LETTER_PROVIDERS = [
  {
    label: "Hatteras",
    value: "hatteras",
  },
  {
    label: "Revspring",
    value: "revspring",
  },
  {
    label: "DMA",
    value: "dma",
  },
  {
    label: "ICGroup",
    value: "icgroup",
  },
  {
    label: "Renkim",
    value: "renkim",
  },
];

const AI_DIALER_PROVIDERS = [
  {
    // This is intentionally done since we don't want to show the actual provider name.
    // Refer to the backend to infer the provider name.
    label: "Aktos (Default)",
    value: "aktos_ai_dialer_model_1",
  },
];

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export default function AddMethodModal({
  title,
  open,
  onOk,
  onCancel,
  selectedCommunicationMethod = undefined,
}) {
  const [form] = Form.useForm();
  const isNew = !selectedCommunicationMethod;

  const { data: constants = {} } = useFetchBackendConstantsQuery();
  const [createCommunicationMethod, { isLoading: isCreateMethodLoading }] =
    useCreateCommunicationMethodMutation();
  const [updateCommunicationMethod, { isLoading: isUpdateMethodLoading }] =
    useUpdateCommunicationMethodMutation();
  const { data: agency = {}, isLoading: isAgencyLoading } = useFetchAgencyQuery();
  const { data: emailTemplates = [] } = useGetEmailTemplatesQuery(
    {},
    {
      skip: !agency.isEmailEnabled,
    },
  );
  const { data: smsTemplates = [] } = useFetchSmsTemplatesQuery(
    {},
    {
      skip: !agency.primarySmsProvider,
    },
  );
  const { data: aiDialerTemplates = [] } = useFetchAiDialerTemplatesQuery(
    {},
    {
      skip: !agency.isAiDialerEnabled,
    },
  );
  const { data: letterTemplates = [] } = useFetchLetterTemplatesQuery();

  const isActionInProgress = isCreateMethodLoading || isUpdateMethodLoading;

  const emailTemplateOptions = emailTemplates.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const letterTemplateOptions = letterTemplates.map((template) => ({
    label: template.filename,
    value: template.id,
  }));

  // Filter out invoice_check, invoice, legal_filings, dispute letter templates from in-house letter templates
  const inHouseLetterTemplateOptions = letterTemplates
    .filter(
      (template) =>
        !["invoice_check", "invoice", "legal_filings", "dispute"].includes(template.type),
    )
    .map((template) => ({
      label: template.filename,
      value: template.id,
    }));

  const letterOptions = (agency.letterCodes ?? []).map((code) => ({
    label: code,
    value: code,
  }));

  const save = async () => {
    const fields = await form.validateFields();

    const action = isNew
      ? createCommunicationMethod({ ...fields })
      : updateCommunicationMethod({
          ...fields,
          communicationMethodId: selectedCommunicationMethod.id,
        });
    const result = await action;

    if ("error" in result) {
      form.setFields(formatError(result.error));
    }

    return result;
  };

  const onSubmit = async () => {
    const result = await save();
    if ("data" in result) {
      const successMessage = isNew
        ? "Communication method added successfully!"
        : "Communication method updated successfully!";
      message.success(successMessage);
    }

    if ("data" in result) {
      form.resetFields();
      onOk();
    }
  };

  useEffect(() => {
    if (selectedCommunicationMethod) {
      form.setFieldsValue(selectedCommunicationMethod);
    } else {
      form.resetFields();
    }
  }, [form, selectedCommunicationMethod]);

  const initialValues = {
    medium: "third_party_letter",
    letterTemplateIds:
      selectedCommunicationMethod?.medium === "in_house_letter"
        ? selectedCommunicationMethod?.letterTemplateIds?.[0]
        : null,
    provider: agency.letterProvider,
    smsPhoneTypes: selectedCommunicationMethod?.smsPhoneTypes ?? [],
    smsPhoneStatuses: selectedCommunicationMethod?.smsPhoneStatuses ?? [],
    smsContactPermissionStatuses: selectedCommunicationMethod?.smsContactPermissionStatuses ?? [],
    voicemailPhoneTypes: selectedCommunicationMethod?.voicemailPhoneTypes ?? [],
    voicemailPhoneStatuses: selectedCommunicationMethod?.voicemailPhoneStatuses ?? [],
    voicemailContactPermissionStatuses:
      selectedCommunicationMethod?.voicemailContactPermissionStatuses ?? [],
  };

  const onCommunicationMediumChange = (value) => {
    form.resetFields(["externalEmailTemplateId", "letterCode"]);
    form.setFieldValue(
      "provider",
      {
        third_party_letter: agency.letterProvider,
        email: agency.emailProvider,
      }[value] ?? null,
    );
  };

  const onProviderChange = () => {
    form.resetFields(["externalEmailTemplateId", "letterCode"]);
  };

  const emailTemplateFields = (provider) => {
    return (
      <div>
        <Form.Item
          name="externalEmailTemplateId"
          label="Body Template"
          rules={[{ required: true, message: "Select Email Template" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            options={emailTemplateOptions}
            disabled={!provider}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name="letterTemplateIds"
          label="Attachment Templates"
          rules={[{ required: false, message: "Select Letter Templates" }]}
        >
          <Select
            mode="multiple"
            popupMatchSelectWidth={false}
            placeholder="Select..."
            options={letterTemplateOptions}
            disabled={!provider}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
      </div>
    );
  };

  const thirdPartyLetterFields = (provider) => {
    return (
      <Form.Item
        name="letterCode"
        label="Letter Code"
        rules={[{ required: true, message: "Select Letter Code" }]}
      >
        <Select
          loading={isAgencyLoading}
          popupMatchSelectWidth={false}
          placeholder="Select one..."
          options={letterOptions}
          disabled={!provider}
          showSearch
          filterOption={filterOption}
        />
      </Form.Item>
    );
  };

  const voiceMailFields = () => {
    return (
      <>
        <Form.Item
          name="externalVoicemailTemplateId"
          label="Template"
          rules={[
            {
              required: true,
              message: "Provide the external template id",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="voicemailPhoneTypes"
          label="Phone Types"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            popupMatchSelectWidth={false}
            options={constants.phoneTypes?.map(({ display, value }) => ({
              value,
              label: display,
            }))}
            showSearch
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.Item
          name="voicemailPhoneStatuses"
          label="Phone Status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            popupMatchSelectWidth={false}
            options={constants.phoneStatuses?.map(({ display, value }) => ({
              value,
              label: display,
            }))}
            showSearch
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.Item
          name="voicemailContactPermissionStatuses"
          label="Contact Permission Status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            mode="multiple"
            popupMatchSelectWidth={false}
            options={constants.contactPermissionStatuses?.map(({ display, value }) => ({
              value,
              label: display,
            }))}
            showSearch
            filterOption={filterOption}
          />
        </Form.Item>
      </>
    );
  };

  const aiDialerFields = () => {
    return (
      <>
        <Form.Item
          name="externalAiDialerTemplateId"
          label="Template"
          rules={[{ required: true, message: "Select AI Dialer Template" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            options={aiDialerTemplates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
            filterOption={filterOption}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name="fromPhoneNumbers"
          label="From Phone Numbers"
          tooltip="One of these phone numbers will be used to place the call. 
          Please ensure the numbers are verified and allowed to place calls from your account. 
          If no number is provided, a random number will be used to place the call."
        >
          <Select
            mode="tags"
            popupMatchSelectWidth={false}
            options={selectedCommunicationMethod?.fromPhoneNumbers?.map(({ value }) => ({
              label: value,
              value,
            }))}
          />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okButtonProps={{
        loading: isActionInProgress,
      }}
    >
      {isAgencyLoading ? (
        <Skeleton active />
      ) : (
        <StyledForm
          form={form}
          layout="vertical"
          validateMessages={{ required: "This is a required field" }}
          initialValues={initialValues}
        >
          <Form.Item name="id" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="name"
            label="Communication Name"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["medium"]} label="Communication Medium">
            <Select
              allowClear
              popupMatchSelectWidth={false}
              onChange={onCommunicationMediumChange}
              placeholder="Select communication medium..."
              options={constants?.communicationMediums?.map((medium) => ({
                label: medium.display,
                value: medium.value,
              }))}
              filterOption={filterOption}
              showSearch
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.medium !== currentValues.medium}
          >
            {({ getFieldValue }) => {
              const medium = getFieldValue("medium");
              if (!medium) return null;
              if (medium !== "in_house_letter") {
                const options = {
                  third_party_letter: LETTER_PROVIDERS,
                  email: EMAIL_PROVIDERS,
                  text: SMS_PROVIDERS,
                  voicemail: VOICEMAIL_PROVIDERS,
                  ai_dialer: AI_DIALER_PROVIDERS,
                }[medium];

                const provider = {
                  third_party_letter: agency.letterProvider,
                  email: agency.emailProvider,
                }[medium];

                return (
                  <>
                    <Form.Item
                      name="provider"
                      label="Provider"
                      rules={[{ required: !provider, message: "Select Provider" }]}
                    >
                      {provider || (
                        <Select
                          popupMatchSelectWidth={false}
                          placeholder="Select one..."
                          options={options}
                          onChange={onProviderChange}
                          showSearch
                          filterOption={filterOption}
                        />
                      )}
                    </Form.Item>
                    {medium === "email" && (
                      <>
                        <Form.Item
                          name="sendFromEmailType"
                          label="Sender"
                          initialValue=""
                          tooltip="This sender will be used to send the email"
                        >
                          <Select
                            popupMatchSelectWidth={false}
                            placeholder="Select one..."
                            options={constants.sendFromEmailTypes.map((type) => ({
                              label: type.display,
                              value: type.value,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.sendFromEmailType !== currentValues.sendFromEmailType
                          }
                        >
                          {({ getFieldValue: getEmailType }) => {
                            const emailType = getEmailType("sendFromEmailType");
                            if (emailType === "custom") {
                              return (
                                <Form.Item
                                  name="fromEmailAddress"
                                  label="Custom Sender Email"
                                  rules={[{ required: true }]}
                                  help="Please ensure this email address is allowed to send an email from your domain that’s verified with the provider"
                                >
                                  <Input />
                                </Form.Item>
                              );
                            }
                          }}
                        </Form.Item>
                      </>
                    )}
                    {medium === "text" && (
                      <>
                        <Form.Item
                          name="smsMessageType"
                          label="SMS Type"
                          rules={[{ required: true, message: "Select an SMS Type" }]}
                        >
                          <Select
                            popupMatchSelectWidth={false}
                            placeholder="Select one..."
                            options={constants.smsMessageTypes?.map((type) => ({
                              label: type.display,
                              value: type.value,
                            }))}
                            showSearch
                            filterOption={filterOption}
                          />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.smsMessageType !== currentValues.smsMessageType
                          }
                        >
                          {() => {
                            const smsMessageType = getFieldValue("smsMessageType");
                            if (smsMessageType === "send_message") {
                              return (
                                <>
                                  {smsTemplates.length > 0 ? (
                                    <Form.Item
                                      name="externalSmsTemplateId"
                                      label="Template"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Select a template",
                                        },
                                      ]}
                                    >
                                      <Select
                                        allowClear
                                        popupMatchSelectWidth={false}
                                        options={smsTemplates.map((template) => ({
                                          label: template.name,
                                          value: template.id,
                                        }))}
                                        showSearch
                                        filterOption={filterOption}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      name="externalSmsTemplateId"
                                      label="Template"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Provide the external template id",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  )}
                                  <Form.Item
                                    name="smsPhoneTypes"
                                    label="Phone Types"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      mode="multiple"
                                      popupMatchSelectWidth={false}
                                      options={constants.phoneTypes?.map(({ display, value }) => ({
                                        value,
                                        label: display,
                                      }))}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="smsPhoneStatuses"
                                    label="Phone Status"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      mode="multiple"
                                      popupMatchSelectWidth={false}
                                      options={constants.phoneStatuses?.map(
                                        ({ display, value }) => ({
                                          value,
                                          label: display,
                                        }),
                                      )}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="smsContactPermissionStatuses"
                                    label="Contact Permission Status"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      mode="multiple"
                                      popupMatchSelectWidth={false}
                                      options={constants.contactPermissionStatuses?.map(
                                        ({ display, value }) => ({
                                          value,
                                          label: display,
                                        }),
                                      )}
                                      showSearch
                                      filterOption={filterOption}
                                    />
                                  </Form.Item>
                                </>
                              );
                            }

                            return (
                              <>
                                <Form.Item
                                  name="smsPhoneTypes"
                                  label="Phone Types"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    popupMatchSelectWidth={false}
                                    options={constants.phoneTypes?.map(({ display, value }) => ({
                                      value,
                                      label: display,
                                    }))}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name="smsPhoneStatuses"
                                  label="Phone Status"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    popupMatchSelectWidth={false}
                                    options={constants.phoneStatuses.map(({ display, value }) => ({
                                      value,
                                      label: display,
                                    }))}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                </Form.Item>
                                <Form.Item
                                  name="smsContactPermissionStatuses"
                                  label="Contact Permission Status"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    popupMatchSelectWidth={false}
                                    options={constants.contactPermissionStatuses?.map(
                                      ({ display, value }) => ({
                                        value,
                                        label: display,
                                      }),
                                    )}
                                    showSearch
                                    filterOption={filterOption}
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>
                      </>
                    )}
                    {medium === "voicemail" && voiceMailFields()}
                    {medium === "ai_dialer" && aiDialerFields()}
                  </>
                );
              }
              if (medium === "in_house_letter") {
                return (
                  <Form.Item
                    name="letterTemplateIds"
                    label="Template"
                    rules={[{ required: true, message: "Select Letter Template" }]}
                  >
                    <Select
                      mode="multiple"
                      popupMatchSelectWidth={false}
                      placeholder="Select one..."
                      options={inHouseLetterTemplateOptions}
                      showSearch
                      filterOption={filterOption}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.medium !== currentValues.medium ||
              prevValues.provider !== currentValues.provider
            }
          >
            {({ getFieldValue }) => {
              const medium = getFieldValue("medium");
              const provider = getFieldValue("provider");

              if (medium === "third_party_letter") {
                return thirdPartyLetterFields(provider);
              }

              if (medium === "email") {
                return emailTemplateFields(provider);
              }

              // default case
              return null;
            }}
          </Form.Item>
          <Form.Item
            name="allowCommunicationInUnknownOperatingStates"
            label="Allow communication in unknown operating states"
            initialValue={false}
          >
            <Radio.Group>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input />
          </Form.Item>

          <Form.Item
            name="useMultipleAccountsPerTask"
            label="Use Multiple Accounts Per Task"
            initialValue={false}
          >
            <Radio.Group>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </StyledForm>
      )}
    </Modal>
  );
}
