import api, { transformResponse } from "services/api";

export const templatesApi = api
  .enhanceEndpoints({ addTagTypes: ["AiDialerTemplates"] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAiDialerTemplates: build.query({
        query: () => "/api/v1/core/ai-dialer-templates/",
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "AiDialerTemplates", id })),
                { type: "AiDialerTemplates", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'AiDialerTemplates', id: 'LIST' }` is invalidated
              [{ type: "AiDialerTemplates", id: "LIST" }],
      }),
    }),
  });

export const { useFetchAiDialerTemplatesQuery } = templatesApi;
