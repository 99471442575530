import { Form, Input, Select } from "antd";
import CreditorCustomFields from "features/addCreditorWizard/wizardSteps/creditorCustomFields";
import {
  useFetchCreditorSummariesQuery,
  useFetchCreditorTagsQuery,
} from "features/creditors/agencyPortal/creditorsAPI";
import {
  activeCollectorsWithRolesSelector,
  useFetchBackendConstantsQuery,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchCreditorGroupQuery } from "features/mergeGroups/debtorMergeGroupAPI";
import { useFetchProductOfferingsQuery } from "features/productOfferings/productOfferingsAPI";

export default function BaseStep() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: creditorTags } = useFetchCreditorTagsQuery();
  const { data: creditorGroups } = useFetchCreditorGroupQuery();
  const { activeCollectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeCollectorsWithRoles: activeCollectorsWithRolesSelector(result),
    }),
  });
  const { data: productOfferings } = useFetchProductOfferingsQuery();
  const { data: creditors, isLoading } = useFetchCreditorSummariesQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label ?? "";
    return fullOptionText.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <>
      <Form.Item name="id" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: "Name is required" },
          {
            max: 50,
            message: "Name cannot be longer than 50 characters",
          },
        ]}
      >
        <Input placeholder="Enter Name..." maxLength={50} />
      </Form.Item>
      <Form.Item
        name="code"
        label="Client Code"
        rules={[{ required: true, message: "Code is required" }]}
      >
        <Input placeholder="Enter code (e.g 'CANTE')" />
      </Form.Item>
      <Form.Item name={["referringEntityName"]} label="Referring Entity">
        <Input placeholder="Enter Referring Entity Name..." />
      </Form.Item>
      <Form.Item
        name={["cbrClassCodes"]}
        label="CBR Class Codes"
        tooltip="The cbr class codes this client handles"
        rules={[{ required: true, message: "Please select cbr class code" }]}
      >
        <Select
          mode="multiple"
          placeholder="Select a Type..."
          options={constants?.cbrClassCodes.map((cbrClassCodeOption) => ({
            label: cbrClassCodeOption.display,
            value: cbrClassCodeOption.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={["status"]}
        label="Client Status"
        tooltip="Status of the current client"
        rules={[{ required: true, message: "Please select a status" }]}
      >
        <Select
          placeholder="Select a status..."
          options={[
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
            {
              label: "Prospect",
              value: "prospect",
            },
          ]}
        />
      </Form.Item>
      <Form.Item name={["parentId"]} label="Parent Company" tooltip="Parent Company">
        <Select
          loading={isLoading}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a Company..."
          options={creditors?.map((creditor) => ({
            label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
            value: creditor.id,
          }))}
        />
      </Form.Item>
      <Form.Item name={["salesRepId"]} label="Sales Representative">
        <Select
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a sales rep..."
          options={collectors?.map((user) => ({
            label: collectorFullName(user),
            value: user.id,
          }))}
        />
      </Form.Item>
      <Form.Item name={["relationshipStartDate"]} label="Relationship Start Date">
        <Input type="date" />
      </Form.Item>
      <Form.Item
        name={["debtorMergeGroupId"]}
        label="Client Group"
        tooltip="Client group is used to merge debtor accounts with other clients from the same group."
      >
        <Select
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select a Client Group..."
          options={creditorGroups?.map((creditorGroup) => ({
            label: creditorGroup.groupName,
            value: creditorGroup.id,
          }))}
        />
      </Form.Item>
      <CreditorCustomFields />
      <Form.Item name={["tagIds"]} label="Tags">
        <Select
          mode="multiple"
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select tags..."
          options={creditorTags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={["productOfferingIds"]}
        label="Products"
        rules={[{ required: true, message: "Please select products" }]}
      >
        <Select
          mode="multiple"
          popupMatchSelectWidth={false}
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select products..."
          options={productOfferings?.map((product) => ({
            label: product.name,
            value: product.id,
          }))}
        />
      </Form.Item>
      <Form.Item name="notes" label="Notes">
        <Input.TextArea placeholder="Enter Notes..." />
      </Form.Item>
    </>
  );
}
