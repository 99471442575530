import { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Popover,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { AktDateRangePicker } from "components/aktDatePicker";
import { TriggerModal } from "components/triggerModal";
import {
  resetFilters,
  selectAccountsTableSlice,
  setCollectionDebtTypes,
  setCreditorIds,
  setDebtorStates,
  setFilterExcludeLastCallDates,
  setFilterExcludeStatusExternalNames,
  setFilterExcludeTurnoverDates,
  setFilterFollowUpDates,
  setFilterIncludeLastCallDates,
  setFilterIncludeStatusExternalNames,
  setFilterIncludeTurnoverDates,
  setFilterMaxAccountBalance,
  setFilterMinAccountBalance,
  setFilterUploadDates,
  setHasLawsuit,
} from "features/accountsTable/accountsTableSlice";
import TableAppearanceModal, {
  reconcileColumns,
} from "features/appearance/components/tableAppearanceModal";
import { SIDER_ITEMS } from "features/appearance/constants";
import { useFetchCreditorPortalCreditorSummariesQuery } from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchCreditorPortalWorkflowStates } from "features/home/creditorPortal/homeAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import moment from "moment-timezone";
import { useAppearance } from "providers/appearanceProvider";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledFilterHeader = styled.h4`
  font-weight: 600;
  color: #1677ff;
`;

const StyledSubHeader = styled.h5`
  margin-top: 0;
`;

const StyledRow = styled(Row)`
  align-items: baseline;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  min-width: 250px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0;
`;

const StyledContainer = styled.div`
  height: calc(100vh - 230px);
  overflow: scroll;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  margin-bottom: 12px;
  min-width: 250px;
`;

const StyledSwitchContainer = styled(Row)`
  margin-top: 8px;
`;

const StyledMaxBalance = styled(Form.Item)`
  margin-left: 4px;
`;

const prepareDate = (date) => {
  if (!date) return null;
  return moment(date);
};

const SIDER_ID = "accountFilters";

function CreditorAccountsTableFilters() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const { data: defaultWorkflowStates } = useFetchCreditorPortalWorkflowStates();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: creditors } = useFetchCreditorPortalCreditorSummariesQuery();
  const { appearance, isLoading: isAppearanceLoading } = useAppearance();
  const {
    data: { [PERMISSIONS.APPEARANCE__UPDATE]: isAppearanceUpdateAuthorized },
  } = useAuthorizations();

  const initialValues = {
    ...accountsTableSlice.filters,
    followUpDate: [
      prepareDate(accountsTableSlice.filters?.followUpStartDate),
      prepareDate(accountsTableSlice.filters?.followUpEndDate),
    ],
    uploadDate: [
      prepareDate(accountsTableSlice.filters?.uploadDateStart),
      prepareDate(accountsTableSlice.filters?.uploadDateEnd),
    ],
    includeTurnoverDate: [
      prepareDate(accountsTableSlice.filters?.includeTurnoverDateStart),
      prepareDate(accountsTableSlice.filters?.includeTurnoverDateEnd),
    ],
    excludeTurnoverDate: [
      prepareDate(accountsTableSlice.filters?.excludeTurnoverDateStart),
      prepareDate(accountsTableSlice.filters?.excludeTurnoverDateEnd),
    ],
    includeLastCallDate: [
      prepareDate(accountsTableSlice.filters?.includeLastCallDateStart),
      prepareDate(accountsTableSlice.filters?.includeLastCallDateEnd),
    ],
    excludeLastCallDate: [
      prepareDate(accountsTableSlice.filters?.excludeLastCallDateStart),
      prepareDate(accountsTableSlice.filters?.excludeLastCallDateEnd),
    ],
  };

  const onIncludeStatusChange = async (values) => {
    dispatch(setFilterIncludeStatusExternalNames(values));
  };

  const onExcludeStatusChange = async (values) => {
    dispatch(setFilterExcludeStatusExternalNames(values));
  };

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onMinBalanceChange = async (value) => {
    await dispatch(setFilterMinAccountBalance(value));
  };

  const onMaxBalanceChange = async (value) => {
    await dispatch(setFilterMaxAccountBalance(value));
  };

  const onFollowUpChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterFollowUpDates([]));
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterFollowUpDates([start, end]));
  };

  const onLawsuitFiledChange = async (value) => {
    await dispatch(setHasLawsuit(value));
  };

  const onUploadDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterUploadDates([]));
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterUploadDates([start, end]));
  };

  const onIncludeTurnoverDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterIncludeTurnoverDates([]));
      return;
    }
    await dispatch(setFilterIncludeTurnoverDates([...value]));
  };

  const onExcludeTurnoverDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterExcludeTurnoverDates([]));
      return;
    }
    await dispatch(setFilterExcludeTurnoverDates([...value]));
  };

  const onIncludeLastCallDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterIncludeLastCallDates([]));
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterIncludeLastCallDates([start, end]));
  };

  const onExcludeLastCallDateChange = async (value) => {
    if (value === null) {
      await dispatch(setFilterExcludeLastCallDates([]));
      return;
    }
    const [start, end] = value;
    await dispatch(setFilterExcludeLastCallDates([start, end]));
  };

  const onCollectionTypeChange = async (value) => {
    if (value === null || value.length === 0) {
      await dispatch(setCollectionDebtTypes(null));
      return;
    }
    await dispatch(setCollectionDebtTypes(value));
  };

  const onDebtorStatesChange = async (values) => {
    dispatch(setDebtorStates(values));
  };

  const onCreditorIdsChange = async (values) => {
    dispatch(setCreditorIds(values));
  };

  const onResetClicked = async () => {
    await dispatch(resetFilters());
    form.resetFields();
  };

  const defaultItems = [
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__STATUSES,
      visible: true,
      title: "Statuses",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeStatusExternalNames">
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onIncludeStatusChange}
              filterOption={filterOption}
              options={defaultWorkflowStates?.map((state) => ({
                label: state.externalName,
                value: state.externalName,
                description: state.externalName,
              }))}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeStatusExternalNames">
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onExcludeStatusChange}
              filterOption={filterOption}
              options={defaultWorkflowStates?.map((state) => ({
                label: state.externalName,
                value: state.externalName,
                description: state.externalName,
              }))}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__ACCOUNT_BALANCE,
      visible: true,
      title: "Account Balance",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Row>
            <Form.Item name="minAccountBalance">
              <InputNumber
                placeholder="Min"
                controls={false}
                min="0"
                prefix="$"
                step="0.01"
                onChange={onMinBalanceChange}
              />
            </Form.Item>
            <StyledMaxBalance name="maxAccountBalance">
              <InputNumber
                placeholder="Max"
                controls={false}
                min="0"
                prefix="$"
                step="0.01"
                onChange={onMaxBalanceChange}
              />
            </StyledMaxBalance>
          </Row>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__FOLLOW_UP_DATE,
      visible: true,
      title: "Follow-up Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="followUpDate">
            <AktDateRangePicker picker="date" onChange={onFollowUpChange} allowClear />
          </Form.Item>
          {/** new table filters */}
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__UPLOAD_DATE,
      visible: true,
      title: "Upload Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="uploadDate">
            <AktDateRangePicker picker="date" onChange={onUploadDateChange} allowClear />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__TURNOVER_DATE,
      visible: true,
      title: "Turnover date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeTurnoverDate">
            <AktDateRangePicker
              picker="date"
              onChange={onIncludeTurnoverDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeTurnoverDate">
            <AktDateRangePicker
              picker="date"
              onChange={onExcludeTurnoverDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__LAST_CALL_DATE,
      visible: true,
      title: "Last Call Date",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <StyledSubHeader>Include</StyledSubHeader>
          <Form.Item name="includeLastCallDate">
            <AktDateRangePicker
              picker="date"
              onChange={onIncludeLastCallDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledSubHeader>Exclude</StyledSubHeader>
          <Form.Item name="excludeLastCallDate">
            <AktDateRangePicker
              picker="date"
              onChange={onExcludeLastCallDateChange}
              allowClear
              allowEmpty={[true, true]}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__LAWSUIT,
      visible: true,
      title: "Lawsuit",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item>
            <StyledSwitchContainer justify="space-between">
              <span>Filed:</span>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={accountsTableSlice.filters.hasLawsuit}
                onChange={onLawsuitFiledChange}
              />
            </StyledSwitchContainer>
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__DEBT_TYPE,
      visible: true,
      title: "Debt Type",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="collectionDebtTypes">
            <Checkbox.Group
              onChange={onCollectionTypeChange}
              options={[
                { label: "Consumer", value: "consumer" },
                { label: "Commercial", value: "commercial" },
              ]}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__DEBTOR_LOCATION,
      visible: true,
      title: "Debtor Location",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>
            {item.newTitle ?? item.originalTitle}
            <Popover
              content="This filters by the primary address of the debtor"
              title=""
              trigger="hover"
              placement="right"
            >
              <StyledQuestionCircleOutlined />
            </Popover>
          </StyledFilterHeader>
          <Form.Item name="debtorStates">
            <Select
              mode="multiple"
              placeholder="Debtor states..."
              filterOption={filterOption}
              onChange={onDebtorStatesChange}
              options={constants?.states.map((state) => ({
                label: state.display,
                value: state.value,
              }))}
            />
          </Form.Item>
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.ACCOUNT_FILTERS__CLIENT,
      visible: true,
      title: "Client",
      render: (item) => (
        <span key={item.key}>
          {/** The creditors list includes the creditor and the child creditors. If the creditor has no child creditors,
           then the creditor will be the only one in the list. Otherwise, the list will contain the creditor and the child creditors.

           We only want to show the creditor filter if there are child creditor (i.e. more than one since the main creditor will be in the list by default) */}
          {creditors?.length > 1 && (
            <span key={item.key}>
              <Divider />
              <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
              <Form.Item name="creditorIds">
                <Select
                  maxTagTextLength={20}
                  mode="multiple"
                  placeholder="Select Clients..."
                  filterOption={filterOption}
                  onChange={onCreditorIdsChange}
                  options={creditors?.map((creditor) => ({
                    value: creditor.id,
                    label: creditor.code ? `${creditor.name} (${creditor.code})` : creditor.name,
                  }))}
                />
              </Form.Item>
            </span>
          )}

          <Divider />
        </span>
      ),
    },
  ];

  const reconciledItems = reconcileColumns(defaultItems, appearance?.items?.[SIDER_ID]).filter(
    (column) => column.visible,
  );

  if (appearance?.items?.[SIDER_ID]?.hidePanel || isAppearanceLoading) {
    return null;
  }

  return (
    <Col>
      <StyledRow justify="space-between">
        <h3>
          Filters
          {isAppearanceUpdateAuthorized && (
            <TriggerModal
              modal={TableAppearanceModal}
              tableId={SIDER_ID}
              defaultColumns={defaultItems}
              mode="sider"
              containerProps={{ style: { display: "inline" } }}
            >
              <Tooltip title="Customize Appearance">
                <Button shape="circle" icon={<SettingOutlined />} type="text" />
              </Tooltip>
            </TriggerModal>
          )}
        </h3>
        <Button type="link" onClick={onResetClicked}>
          Reset
        </Button>
      </StyledRow>
      <StyledContainer>
        <Form
          form={form}
          labelAlign="right"
          initialValues={initialValues}
          validateMessages={{ required: "This is a required field" }}
          layout="vertical"
        >
          {reconciledItems.map((item) => item.render(item))}
        </Form>
      </StyledContainer>
    </Col>
  );
}

export default CreditorAccountsTableFilters;
