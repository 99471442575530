import { Col, Row } from "antd";
import { CursorTable } from "components/cursorTable";
import { useGetPaymentAllocationsListQuery } from "features/payments/paymentsAllocations";
import PaymentAllocationBulkActions from "pages/agencyPortal/payments/paymentAllocationBulkActions";
import { useState } from "react";
import styled from "styled-components";
import { formatDate } from "common/utils";

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }

  & .ant-table-cell {
    padding: 4px 8px !important;
  }
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledActionContainer = styled(Row)`
  background: #fff;
  height: 48px;
  margin-top: 1px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  justify-content: space-between;
`;

const gotoDebtorProfile = (record) => {
  const url = `/debtors/${record.debtor.id}`;
  const newWindow = window.open(url, "_blank");
  if (newWindow) newWindow.opener = null;
};

const tableColumns = [
  {
    title: "Debtor",
    dataIndex: "debtor",
    key: "debtor",
    width: 150,
    render: (field) => (field ? `${field.id} - ${field.name}` : "N/A"),
  },
  {
    title: "Account",
    dataIndex: "accountId",
    key: "accountId",
    width: 80,
    render: (text) => text,
  },
  {
    title: "Scheduled Date",
    dataIndex: "scheduledDate",
    key: "scheduledDate",
    width: 120,
    render: (text) => formatDate(text),
  },
  {
    title: "Created at Date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 120,
    render: (text) => formatDate(text),
  },
  {
    title: "Allocation Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    width: 100,
    render: (text) => `$${text}`,
  },
  {
    title: "Credited Collector",
    dataIndex: "creditedCollector",
    key: "creditedCollector",
    width: 100,
    render: (field) => {
      return field ? `${field.fullName}` : "-";
    },
  },
  {
    title: "Creditor Fee Plan",
    dataIndex: "creditorFeePlan",
    key: "creditorFeePlan",
    width: 100,
    render: (text) => `${text?.name ? text.name : "N/A"}`,
  },
  {
    title: "Forwarded Entity Fee Plan",
    dataIndex: "forwardingEntityFeePlan",
    key: "forwardingEntityFeePlan",
    width: 100,
    render: (text) => `${text?.name ? text.name : "N/A"}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 80,
    key: "status",
  },
  {
    title: "Is Settlement?",
    dataIndex: "isSettlement",
    key: "isSettlement",
    width: 80,
    render: (text) => (text ? "Yes" : "No"),
  },
  {
    title: "Owed to Agency",
    dataIndex: "owedToAgency",
    key: "owedToAgency",
    width: 80,
    render: (text) => `$${text}`,
  },
  {
    title: "Owed to Creditor",
    dataIndex: "owedToCreditor",
    key: "owedToCreditor",
    width: 80,
    render: (text) => `$${text}`,
  },
  {
    title: "Owed to Forwarding Entity",
    dataIndex: "owedToForwardingEntity",
    key: "owedToForwardingEntity",
    width: 80,
    render: (text) => `$${text}`,
  },
  {
    title: "Paid to Principal",
    dataIndex: "paidToPrincipal",
    key: "paidToPrincipal",
    width: 80,
  },
  {
    title: "Paid to Interest",
    dataIndex: "paidToInterest",
    key: "paidToInterest",
    width: 80,
  },
  {
    title: "Paid to Fees",
    dataIndex: "paidToFees",
    key: "paidToFees",
    width: 80,
  },
  {
    title: "Overpayment",
    dataIndex: "overpayment",
    key: "overpayment",
    width: 80,
    render: (text) => (text ? `$${text}` : "-"),
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    width: 150,
    render: (text) => text ?? "-",
  },
];

export default function PaymentAllocationTable({ filterParams }) {
  const [pagination, setPagination] = useState({
    virtualPage: 1,
    pageSize: 100,
    prevToken: null,
    nextToken: null,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    data: paymentAllocations,
    isLoading,
    isFetching,
  } = useGetPaymentAllocationsListQuery({
    ...filterParams,
    nextToken: pagination.nextToken,
    prevToken: pagination.prevToken,
    pageSize: pagination.pageSize,
    includeResultCount: true,
  });

  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
  };

  const handleTableChange = (newPagination, _, sorter) => {
    setPagination(newPagination);
  };

  // Needed because the actions column is fixed and virtually rendered so that pointer-events: none does not work
  const isActionsColumn = (element) => {
    return (
      element.classList.contains("ant-table-cell-fix-right") ||
      element.classList.contains("ant-dropdown-menu-item") ||
      element.classList.contains("ant-popover") ||
      element.classList.contains("ant-popconfirm") ||
      element.nextSibling === null ||
      element.tagName === "svg" ||
      element.tagName === "path"
    );
  };

  return (
    <StyledCol>
      <StyledActionContainer>
        <StyledActions>
          {/* Add payment allocation options */}
          <PaymentAllocationBulkActions records={selectedRows} />
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        bordered
        sticky
        scroll={{ x: "max-content" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        pageSize={pagination.pageSize}
        prevToken={paymentAllocations?.meta?.prevToken}
        nextToken={paymentAllocations?.meta?.nextToken}
        resultCount={paymentAllocations?.meta?.resultCount}
        virtualPage={pagination.virtualPage}
        setVirtualPage={(page) => setPagination((prev) => ({ ...prev, virtualPage: page }))}
        columns={tableColumns}
        dataSource={paymentAllocations?.results}
        loading={isLoading || isFetching}
        onRow={(record) => ({
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            // allow users to select text so it does not trigger a click into the row
            const selection = window.getSelection().toString();
            if (selection.length <= 0 && !isLoading && !isActionsColumn(e.target)) {
              gotoDebtorProfile(record);
            }
          },
        })}
        onChange={handleTableChange}
      />
    </StyledCol>
  );
}
