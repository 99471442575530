import { useMemo, useRef, useState, useCallback } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Tooltip as AntDToolTip,
  Button,
  Dropdown,
  Input,
  Row,
  Space,
  Table,
  Popconfirm,
  message,
} from "antd";
import styled from "styled-components";

import { capitalizeFirstLetter } from "common/utils";
import { collectorFullName } from "features/home/utils";
import UserModal from "features/userManagementTable/components/userModal";
import {
  activeUsersSelector,
  useArchiveUserMutation,
  useFetchRolesQuery,
  useFetchUsersQuery,
} from "features/userManagementTable/userManagementAPI";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import ChangePasswordModal from "features/userManagementTable/components/changePasswordModal";
import { useFetchMeQuery } from "features/auth/authAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { getColumnSearchProps } from "common/antd-utils";

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledDiv = styled.div`
  padding: 8px;
`;

const StyledButton = styled(Button)`
  width: 90px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 8px;
  display: block;
`;

const StyledSearchOutlined = styled(SearchOutlined)`
  color: ${(props) =>
    // @ts-ignore
    props.filtered && "#1890ff"};
`;
const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 17px;
`;

export default function UsersTable() {
  const { data: roles } = useFetchRolesQuery();
  const { activeUsers: users, isLoading } = useFetchUsersQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeUsers: activeUsersSelector(result),
    }),
  });
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const [filteredInfo, setFilteredInfo] = useState({});
  const { data: me } = useFetchMeQuery();
  const [archiveUser] = useArchiveUserMutation();
  const {
    data: { [PERMISSIONS.USER__MANAGE]: isUserManagementAuthorized },
  } = useAuthorizations();

  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleAdd = () => {
    setActiveRecord(null);
    setUserModalVisible(true);
  };

  const handleEdit = (record) => {
    setActiveRecord(record);
    setUserModalVisible(true);
  };

  const handleArchiveUser = async ({ userId }) => {
    const result = await archiveUser({
      userId,
    });

    if ("data" in result) {
      message.success("User deleted successfully");
    }
  };

  const handleChange = (_, filters) => {
    setFilteredInfo(filters);
  };

  const handleMenuClick = useCallback(({ item, domEvent }) => {
    item.props.actionHandler();
  }, []);

  const actionItems = (record) => [
    {
      label: "Change Password",
      key: "password",
      actionHandler: () => {
        setActiveRecord(record);
        setPasswordModalVisible(true);
      },
    },
  ];

  const typeOptions = useMemo(() => {
    if (users) {
      return [...new Set(users.map((user) => user.type))].map((type) => ({
        text: capitalizeFirstLetter(type),
        value: type,
      }));
    }
  }, [users]);

  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
      render: (_, record) => collectorFullName(record),
      ...getColumnSearchProps("name", searchInput, handleSearch, handleReset),
      // @ts-ignore
      filteredValue: filteredInfo?.name ?? null,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Type",
      dataIndex: "type",
      key: "type",
      render: (type) => capitalizeFirstLetter(type),
      filters: typeOptions,
      // @ts-ignore
      filteredValue: filteredInfo?.type ?? null,
      onFilter: (value, record) => record?.type === value,
    },
    {
      title: "Role",
      dataIndex: "groupIds",
      key: "groupIds",
      render: (groupIds) => {
        const roleNames = roles?.filter((x) => groupIds?.includes(x.id)).map((x) => x.name) ?? [];
        return roleNames.length > 0 ? roleNames.join(", ") : "-";
      },
    },
    {
      title: "Client",
      render: (text, record) => {
        const selectedCreditor = creditors?.find((creditor) => creditor.id === record.creditorId);
        return selectedCreditor ? (
          <>
            <div>Name: {selectedCreditor.name}</div>
            <div>Code: {selectedCreditor.code}</div>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => email || "-",
      // @ts-ignore
      filteredValue: filteredInfo?.email ?? null,
      ...getColumnSearchProps("email", searchInput, handleSearch, handleReset),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber) => phoneNumber || "-",
    },
    {
      title: "Phone Extension",
      dataIndex: "phoneExtension",
      key: "phoneExtension",
      render: (phoneExtension) => phoneExtension || "-",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "actions",
      key: "actions",
      hidden: !isUserManagementAuthorized,
      render: (_, record) => (
        <Space size="middle">
          <AntDToolTip placement="bottom" title="Edit" key="edit">
            <EditOutlined key="edit" onClick={() => handleEdit(record)} />
          </AntDToolTip>
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleArchiveUser({ userId: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
          <Dropdown
            menu={{
              items: actionItems(record),
              onClick: handleMenuClick,
            }}
            trigger={["click"]}
          >
            <Button shape="circle" type="text">
              <StyledEllipsisOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <StyledRow align="middle">
        <span>Manage users and their roles.</span>
        {me?.isStaff && (
          <Button onClick={() => handleAdd()} icon={<PlusOutlined />} type="link">
            Add User
          </Button>
        )}
      </StyledRow>
      <Table
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        dataSource={users}
        onChange={handleChange}
      />
      {isUserModalVisible && (
        <UserModal
          title={activeRecord ? "Edit User" : "Add User"}
          selectedUser={activeRecord}
          roles={roles}
          open={isUserModalVisible}
          onOk={() => {
            setUserModalVisible(false);
            setActiveRecord(null);
          }}
          onCancel={() => {
            setUserModalVisible(false);
            setActiveRecord(null);
          }}
        />
      )}
      {isPasswordModalVisible && (
        <ChangePasswordModal
          title="Change Password"
          selectedUser={activeRecord}
          open={isPasswordModalVisible}
          onOk={() => {
            setPasswordModalVisible(false);
            setActiveRecord(null);
          }}
          onCancel={() => {
            setPasswordModalVisible(false);
            setActiveRecord(null);
          }}
        />
      )}
    </>
  );
}
