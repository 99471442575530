import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Row, Select, Skeleton, Switch, Tooltip } from "antd";
import { ORGANIZATION_STATUS } from "common/constants";
import { capitalizeFirstLetter } from "common/utils";
import { TriggerModal } from "components/triggerModal";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { SIDER_ITEMS } from "features/appearance/constants";
import { useUserType } from "features/auth";
import {
  activeCollectorsWithRolesSelector,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { useFetchCreditorGroupQuery } from "features/mergeGroups/debtorMergeGroupAPI";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useAppearance } from "providers/appearanceProvider";
import styled from "styled-components";
import { useFetchCreditorTagsQuery } from "../agencyPortal/creditorsAPI";

const StyledFilterHeader = styled.h4`
  font-weight: 600;
  color: #1677ff;
`;

const StyledSubHeader = styled.h5`
  margin-top: 0;
`;

const StyledRow = styled(Row)`
  align-items: baseline;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  min-width: 250px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0;
`;

const StyledContainer = styled.div`
  height: calc(100vh - 230px);
  overflow: scroll;
  padding: 12px;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  margin-bottom: 12px;
  min-width: 250px;
`;

const StyledSwitchContainer = styled(Row)`
  margin-top: 8px;
`;

const filterOption = (inputValue, option) => {
  const fullOptionText = option.label;
  return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
};

export const SIDER_ID = "creditorFilters";

function CreditorTableFilters({ filters, setFilters = (args) => undefined }) {
  const [form] = Form.useForm();
  const { isAgencyUserType } = useUserType();
  const { data: creditorGroups } = useFetchCreditorGroupQuery();
  const { data: creditorTags } = useFetchCreditorTagsQuery();
  const { activeCollectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeCollectorsWithRoles: activeCollectorsWithRolesSelector(result),
    }),
  });
  const { appearance, isLoading: isAppearanceLoading } = useAppearance();
  const {
    data: { [PERMISSIONS.APPEARANCE__UPDATE]: isAppearanceUpdateAuthorized },
  } = useAuthorizations();

  const initialValues = filters;

  const onIncludeStatusChange = async (values) => {
    setFilters((prev) => ({
      ...prev,
      includeStatuses: values && values.length > 0 ? values : undefined,
    }));
  };

  const onExcludeStatusChange = async (values) => {
    setFilters((prev) => ({
      ...prev,
      excludeStatuses: values && values.length > 0 ? values : undefined,
    }));
  };

  const onCbrEnabledChange = async (value) => {
    // if value === true, set cbrIsEnabled to true, else set it to undefined
    setFilters((prev) => ({ ...prev, cbrIsEnabled: value || undefined }));
  };

  const onCreditorTagChange = async (values) => {
    setFilters((prev) => ({ ...prev, tagIds: values && values.length > 0 ? values : undefined }));
  };
  const onMergeGroupChange = async (values) => {
    setFilters((prev) => ({
      ...prev,
      debtorMergeGroupIds: values && values.length > 0 ? values : undefined,
    }));
  };

  const onResetClicked = async () => {
    setFilters({});
    form.resetFields();
  };

  const defaultItems = [
    {
      key: SIDER_ITEMS.CREDITOR_FILTERS__STATUSES,
      visible: true,
      title: "Statuses",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="includeStatuses">
            <StyledSubHeader>Include</StyledSubHeader>
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onIncludeStatusChange}
              options={Object.entries(ORGANIZATION_STATUS).map(([value, label]) => ({
                label: capitalizeFirstLetter(label),
                value,
              }))}
            />
          </Form.Item>
          <Form.Item name="excludeStatuses">
            <StyledSubHeader>Exclude</StyledSubHeader>
            <Select
              mode="multiple"
              placeholder="Select Status..."
              onChange={onExcludeStatusChange}
              options={Object.entries(ORGANIZATION_STATUS).map(([value, label]) => ({
                label: capitalizeFirstLetter(label),
                value,
              }))}
            />
          </Form.Item>
          <StyledDivider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.CREDITOR_FILTERS__CREDIT_REPORTING,
      visible: true,
      title: "Credit Reporting",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="cbrIsEnabled" valuePropName="checked">
            <StyledSwitchContainer justify="space-between">
              <span>Reporting:</span>
              <Switch checkedChildren="On" unCheckedChildren="Off" onChange={onCbrEnabledChange} />
            </StyledSwitchContainer>
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.CREDITOR_FILTERS__CLIENT_TAGS,
      visible: true,
      title: "Client Tags",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="creditorTags">
            <Select
              mode="multiple"
              placeholder="Select Client Tags..."
              onChange={onCreditorTagChange}
              options={creditorTags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
                description: tag.notes,
              }))}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.CREDITOR_FILTERS__CLIENT_MERGE_GROUPS,
      visible: true,
      title: "Client Merge Groups",
      render: (item) => (
        <span key={item.key}>
          <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
          <Form.Item name="mergeGroups">
            <Select
              mode="multiple"
              placeholder="Select Merge Groups..."
              onChange={onMergeGroupChange}
              options={creditorGroups?.map((creditorGroup) => ({
                label: creditorGroup.groupName,
                value: creditorGroup.id,
              }))}
            />
          </Form.Item>
          <Divider />
        </span>
      ),
    },
    {
      key: SIDER_ITEMS.CREDITOR_FILTERS__SALES_REP,
      visible: true,
      title: "Sales Rep",
      render: (item) =>
        isAgencyUserType && (
          <span key={item.key}>
            <StyledFilterHeader>{item.newTitle ?? item.originalTitle}</StyledFilterHeader>
            <Form.Item name="salesRepId">
              <Select
                onChange={(value) => setFilters((prev) => ({ ...prev, salesRepId: value }))}
                popupMatchSelectWidth={false}
                allowClear
                showSearch
                filterOption={filterOption}
                placeholder="Select a sales rep..."
                options={collectors?.map((user) => ({
                  label: collectorFullName(user),
                  value: user.id,
                }))}
              />
            </Form.Item>
            <Divider />
          </span>
        ),
    },
  ];

  const reconciledItems = reconcileColumns(defaultItems, appearance?.items?.[SIDER_ID]).filter(
    (column) => column.visible,
  );

  if (appearance?.items?.[SIDER_ID]?.hidePanel || isAppearanceLoading) {
    return null;
  }

  return (
    <Col>
      <StyledRow justify="space-between">
        <h3>
          Filters
          {isAppearanceUpdateAuthorized && (
            <TriggerModal
              modal={TableAppearanceModal}
              tableId={SIDER_ID}
              defaultColumns={defaultItems}
              mode="sider"
              containerProps={{ style: { display: "inline" } }}
            >
              <Tooltip title="Customize Appearance">
                <Button shape="circle" icon={<SettingOutlined />} type="text" />
              </Tooltip>
            </TriggerModal>
          )}
        </h3>
        <Button type="link" onClick={onResetClicked}>
          Reset
        </Button>
      </StyledRow>
      <StyledContainer>
        {isAppearanceLoading ? (
          <Skeleton active />
        ) : (
          <Form
            form={form}
            labelAlign="right"
            initialValues={initialValues}
            validateMessages={{ required: "This is a required field" }}
            layout="vertical"
          >
            {reconciledItems.map((item) => item.render(item))}
          </Form>
        )}
      </StyledContainer>
    </Col>
  );
}

export default CreditorTableFilters;
