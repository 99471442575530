import { Col, Row, Tooltip } from "antd";
import { CursorTable } from "components/cursorTable";
import { useGetCommunicationTasksListQuery } from "features/communicationTasks/communicationTasksAPI";
import { useState, useMemo } from "react";
import CommunicationTasksActions from "pages/communications/communicationTaskActions";
import styled from "styled-components";
import { CommunicationTasksBulkActions } from "pages/communications/communicationTaskBulkActions";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import { formatDate, idToObjectMap } from "common/utils";
import { InfoCircleOutlined } from "@ant-design/icons";

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }

  & .ant-table-cell {
    padding: 4px 8px !important;
  }
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledActionContainer = styled(Row)`
  background: #fff;
  height: 48px;
  margin-top: 1px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledActions = styled(Row)`
  margin-left: 47px;
  height: 100%;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  justify-content: space-between;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: red;
  margin-left: 4px;
`;

const gotoDebtorProfile = (record) => {
  const url = `/debtors/${record.debtorId}`;
  const newWindow = window.open(url, "_blank");
  if (newWindow) newWindow.opener = null;
};

function CommunicationTasksTable({ filterParams }) {
  const [pagination, setPagination] = useState({
    virtualPage: 1,
    pageSize: 100,
    prevToken: null,
    nextToken: null,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const { data: communicationMethods } = useFetchCommunicationMethodsQuery();

  const communicationMethodIdToNameMap = useMemo(() => {
    if (!communicationMethods) return {};
    return idToObjectMap(communicationMethods);
  }, [communicationMethods]);

  const tableColumns = [
    {
      title: "Debtor ID",
      dataIndex: "debtorId",
      key: "debtorId",
      width: 100,
      render: (field) => field ?? "-",
    },
    {
      title: "Debtor Name",
      dataIndex: "debtorName",
      key: "debtorName",
      width: 100,
      render: (field) => field ?? "-",
    },
    {
      title: "Account ID(s)",
      dataIndex: "accountId",
      key: "accountId",
      width: 100,
      render: (text, record) => {
        if (record.accountIds && record.accountIds.length > 0) {
          return record.accountIds.join(", ");
        }
        return record.accountId ?? "-";
      },
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
      width: 100,
      render: (text) => formatDate(text),
    },
    {
      title: "Method",
      dataIndex: "communicationMethodName",
      key: "communicationMethodName",
      width: 100,
      render: (text, record) => {
        const communicationMethod = communicationMethodIdToNameMap[record.communicationMethodId];
        if (!communicationMethod) return "-";
        return communicationMethod.name;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "status",
      // capitalize the first letter of the status
      render: (text, record) => {
        if (text === "failed" && record.errorMessage !== null) {
          return (
            <Tooltip title={record.errorMessage} trigger="hover">
              {text.charAt(0).toUpperCase() + text.slice(1)}
              <span>
                <StyledInfoCircleOutlined />
              </span>
            </Tooltip>
          );
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
    },
    {
      title: "Actions",
      fixed: "right",
      align: "center",
      width: 50,
      dataIndex: "actions",
      render: (text, record) => <CommunicationTasksActions record={record} />,
    },
  ];

  const {
    data: communicationTasks,
    isLoading,
    isFetching,
  } = useGetCommunicationTasksListQuery({
    ...filterParams,
    nextToken: pagination.nextToken,
    prevToken: pagination.prevToken,
    pageSize: pagination.pageSize,
    includeResultCount: true,
  });

  const handleTableChange = (newPagination, _, sorter) => {
    setPagination(newPagination);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
  };

  // Needed because the actions column is fixed and virtually rendered so that pointer-events: none does not work
  const isActionsColumn = (element) => {
    return (
      element.classList.contains("ant-table-cell-fix-right") ||
      element.classList.contains("ant-dropdown-menu-item") ||
      element.classList.contains("ant-popover") ||
      element.classList.contains("ant-popconfirm") ||
      element.nextSibling === null ||
      element.tagName === "svg" ||
      element.tagName === "path"
    );
  };

  return (
    <StyledCol>
      <StyledActionContainer>
        <StyledActions>
          {/* Add button to delete */}
          <CommunicationTasksBulkActions dataSource={selectedRows} />
        </StyledActions>
      </StyledActionContainer>
      <StyledTable
        bordered
        sticky
        scroll={{ x: "max-content" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        pageSize={pagination.pageSize}
        prevToken={communicationTasks?.meta?.prevToken}
        nextToken={communicationTasks?.meta?.nextToken}
        resultCount={communicationTasks?.meta?.resultCount}
        virtualPage={pagination.virtualPage}
        setVirtualPage={(page) => setPagination((prev) => ({ ...prev, virtualPage: page }))}
        columns={tableColumns}
        dataSource={communicationTasks?.results}
        loading={isLoading || isFetching}
        onRow={(record) => ({
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            // allow users to select text so it does not trigger a click into the row
            const selection = window.getSelection().toString();
            if (selection.length <= 0 && !isLoading && !isActionsColumn(e.target)) {
              gotoDebtorProfile(record);
            }
          },
        })}
        onChange={handleTableChange}
      />
    </StyledCol>
  );
}
export default CommunicationTasksTable;
