import { Button, Checkbox, Form, message, Modal, Select } from "antd";
import { formatCurrency } from "common/utils";
import { getPaymentIntents } from "features/payments/paymentDefaults";
import {
  useCalculateConvenienceFeesMutation,
  useCreatePaymentIntentsMutation,
  useGetPaymentMethodOptionsQuery,
} from "features/payments/paymentsAPI";
import { selectPaymentsSlice } from "features/payments/paymentsSlice";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { useUserType } from "features/auth";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import currency from "currency.js";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useEffect, useState } from "react";
import { DATE_FORMAT } from "common/constants";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function SelectExistingPaymentMethodForm({ onOk, onCancel }) {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const [form] = Form.useForm();
  const [currentConvenienceFee, setCurrentConvenienceFee] = useState(null);
  const { data: isCanWaiveConvenienceFeeAuthorized } = useAuthorization(
    PERMISSIONS.PAYMENT__WAIVE_CONVENIENCE_FEES,
  );
  const [fetchConvenienceFees] = useCalculateConvenienceFeesMutation();

  const [createPaymentIntents, { isLoading: confirmPaymentLoading }] =
    useCreatePaymentIntentsMutation();
  const { data: paymentOptions, isLoading: isPaymentMethodOptionsLoading } =
    useGetPaymentMethodOptionsQuery(
      {
        debtorId,
      },
      { skip: !isAgencyUserType },
    );
  const paymentsSlice = useSelector(selectPaymentsSlice);

  useEffect(() => {
    (async () => {
      const result = await fetchConvenienceFees({
        accountIds: paymentsSlice.selectedAccounts,
        amounts: [paymentsSlice.paymentsSchedule[0].totalAmount],
        state: paymentOptions?.find(
          (paymentMethod) =>
            paymentMethod.id === form.getFieldValue("existingPaymentMethodOptionId"),
        )?.state,
        paymentMethodType: paymentOptions?.find(
          (paymentMethod) =>
            paymentMethod.id === form.getFieldValue("existingPaymentMethodOptionId"),
        )?.type,
      });
      if ("data" in result) {
        setCurrentConvenienceFee(result.data.convenienceFees[0]);
      }
    })();
  }, [
    fetchConvenienceFees,
    paymentsSlice.selectedAccounts,
    form,
    paymentsSlice.totalAmount,
    paymentsSlice.paymentsSchedule,
    paymentOptions,
  ]);

  const onExistingPaymentMethodFinish = async (values) => {
    const { existingPaymentMethodOptionId } = values;
    if (currency(currentConvenienceFee?.fee).value > 0 && !values.waiveConvenienceFees) {
      Modal.info({
        title: "Convenience Fee Disclosure",
        content: (
          <>
            <p>
              Attention:{" "}
              {currentConvenienceFee?.message ||
                "You are authorizing the payment service provider to charge your payment method for the principal amount and a convenience fee. For ACH payments, this will appear on your statement as two separate transactions. For card payments, this will appear as one transaction. You understand that you are paying a fee for the convenience of making this payment by phone and that you can avoid this fee by mailing a check or money order to our office."}
            </p>
            <strong>Convenience Fee: {formatCurrency(currentConvenienceFee?.fee)}</strong>
          </>
        ),
        onOk: async () => {
          try {
            const intents = getPaymentIntents({
              paymentMethodId: existingPaymentMethodOptionId,
              paymentsSchedule: paymentsSlice.paymentsSchedule,
              isPaymentPlan: paymentsSlice.isPaymentPlan,
            });
            const result = await createPaymentIntents({
              paymentMethodId: existingPaymentMethodOptionId,
              paymentIntentType: paymentsSlice.paymentIntentType,
              debtorId,
              intents: intents.map((intent) => ({
                ...intent,
                applyConvenienceFees: !values.waiveConvenienceFees,
              })),
            });
            if ("data" in result) {
              onOk();
            }
          } catch (e) {
            message.error("Failed to process the payment. Please refresh and try again.");
          }
        },
        okText: "Yes, I understand. Proceed with payment",
        okButtonProps: {
          block: true,
        },
        maskClosable: false,
        closable: true,
      });
    } else {
      try {
        const intents = getPaymentIntents({
          paymentMethodId: existingPaymentMethodOptionId,
          paymentsSchedule: paymentsSlice.paymentsSchedule,
          isPaymentPlan: paymentsSlice.isPaymentPlan,
        });
        const result = await createPaymentIntents({
          paymentMethodId: existingPaymentMethodOptionId,
          paymentIntentType: paymentsSlice.paymentIntentType,
          debtorId,
          intents: intents.map((intent) => ({
            ...intent,
            applyConvenienceFees: !values.waiveConvenienceFees,
          })),
        });
        if ("data" in result) {
          onOk();
        }
      } catch (e) {
        message.error("Failed to process the payment. Please refresh and try again.");
      }
    }
  };

  const getButtonText = (applyConvenienceFees) => {
    // For one-time payments
    if (!paymentsSlice.isPaymentPlan) {
      const amount = currency(paymentsSlice.totalAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(amount.add(currentConvenienceFee?.fee))})`;
      }
      return `Confirm Payment (${formatCurrency(amount)})`;
    }

    // For payment planss with down payments
    if (paymentsSlice.downPaymentAmount > 0) {
      // if there's a fee, we need to add it to the down payment
      const totalAmount = currency(paymentsSlice.downPaymentAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(
          totalAmount.add(currency(currentConvenienceFee?.fee)),
        )})`;
      }

      return `Confirm Payment (${formatCurrency(totalAmount)})`;
    }

    // For payment plans without a down payment (no payment today), it doesn't matter to show if there's a fee or not
    // unless there's a payment today.
    if (paymentsSlice.paymentsSchedule[0].scheduledDate === moment().format(DATE_FORMAT)) {
      const totalAmount = currency(paymentsSlice.paymentsSchedule[0].totalAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(
          currency(totalAmount).add(currency(currentConvenienceFee?.fee)),
        )})`;
      }
      return `Confirm Payment (${formatCurrency(totalAmount)})`;
    }

    return "Setup Payment Plan";
  };

  return (
    <StyledForm
      form={form}
      layout="vertical"
      validateMessages={{ required: "This is a required field" }}
      onFinish={onExistingPaymentMethodFinish}
    >
      <Form.Item
        name="existingPaymentMethodOptionId"
        label="Payment Method:"
        initialValue={
          paymentOptions?.length > 0 ? paymentOptions[paymentOptions.length - 1].id : ""
        }
        rules={[{ required: true, message: "Payment Method is required" }]}
      >
        <Select
          loading={isPaymentMethodOptionsLoading}
          options={paymentOptions?.map((paymentMethod) => ({
            value: paymentMethod.id,
            label:
              paymentMethod.type === "process_card"
                ? `${paymentMethod.firstName} ${paymentMethod.lastName} - card ending in ${paymentMethod.cardLast4Digits}`
                : `${paymentMethod.bankAccountName} - ACH ending in ${paymentMethod.bankAccountLast4Digits}`,
          }))}
        />
      </Form.Item>
      {form.getFieldValue("existingPaymentMethodOptionId") !== null &&
      isCanWaiveConvenienceFeeAuthorized ? (
        <Form.Item valuePropName="checked" name="waiveConvenienceFees" label="">
          <Checkbox>Waive convenience fee of {formatCurrency(currentConvenienceFee?.fee)}</Checkbox>
        </Form.Item>
      ) : (
        <Form.Item>
          <i>A convenience fee of {formatCurrency(currentConvenienceFee?.fee)} will be applied.</i>
        </Form.Item>
      )}

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.waiveConvenienceFees !== currentValues.waiveConvenienceFees
        }
      >
        {({ getFieldValue }) => {
          const applyConvenienceFees = !getFieldValue("waiveConvenienceFees");

          return (
            <Form.Item>
              <Button disabled={confirmPaymentLoading} onClick={onCancel}>
                Back
              </Button>
              <Button
                disabled={confirmPaymentLoading}
                loading={confirmPaymentLoading}
                style={{ marginLeft: 8 }}
                type="primary"
                htmlType="submit"
              >
                {getButtonText(applyConvenienceFees)}
              </Button>
            </Form.Item>
          );
        }}
      </Form.Item>
    </StyledForm>
  );
}

export default SelectExistingPaymentMethodForm;
