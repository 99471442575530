import { Form, Input, InputNumber, Modal, Select } from "antd";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

export default function PriorityAccountRuleModal({ title, open, onOk, onCancel }) {
  const [form] = Form.useForm();
  const { data: creditors } = useFetchCreditorSummariesQuery();
  const { data: constants } = useFetchBackendConstantsQuery();

  const onSubmit = async () => {
    const newRule = await form.validateFields();
    onOk(newRule);
    form.resetFields();
  };

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <Modal maskClosable={false} title={title} open={open} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm
        layout="vertical"
        form={form}
        validateMessages={{ required: "This is a required field" }}
      >
        <Form.Item name="cbrClassCodes" label="CBR Class Codes">
          <Select
            mode="multiple"
            options={constants?.cbrClassCodes.map((d) => ({ value: d.value, label: d.display }))}
          />
        </Form.Item>
        <Form.Item name="debtorStates" label="States">
          <Select
            mode="multiple"
            filterOption={filterOption}
            options={constants?.states.map((state) => ({
              label: `${state.display} (${state.value})`,
              value: state.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="alpha1"
          label="Alphabetical (From)"
          rules={[
            {
              pattern: /[A-Z]{1}/,
              message: "Must be a single character from A-Z",
            },
          ]}
        >
          <Input maxLength={1} />
        </Form.Item>
        <Form.Item
          name="alpha2"
          label="Alphabetical (To)"
          rules={[
            {
              pattern: /[A-Z]{1}/,
              message: "Must be a single character from A-Z",
            },
          ]}
        >
          <Input maxLength={1} />
        </Form.Item>
        <Form.Item name="dollars1" label="Account Balance (From)">
          <InputNumber />
        </Form.Item>
        <Form.Item name="dollars2" label="Account Balance (To)">
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="age1"
          label="Days Since (From)"
          rules={[
            {
              pattern: /[0-9]{2}/,
              message: "Enter a valid age",
            },
          ]}
          tooltip="# of days since account upload date"
        >
          <InputNumber maxLength={2} />
        </Form.Item>
        <Form.Item
          name="age2"
          label="Days Since (To)"
          rules={[
            {
              pattern: /[0-9]{2}/,
              message: "Enter a valid age",
            },
          ]}
          tooltip="# of days since account upload date"
        >
          <InputNumber maxLength={2} />
        </Form.Item>
        <Form.Item name="creditorIds" label="Clients">
          <Select
            mode="multiple"
            options={creditors?.map((c) => ({ value: c.id, label: c.name }))}
          />
        </Form.Item>
        <Form.Item name="languages" label="Languages">
          <Select
            mode="multiple"
            options={constants?.languages.map((l) => ({ value: l.value, label: l.display }))}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}
