import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";

export const PAYMENT_VIEW_SCREEN_ENUMS = {
  SelectAccountsForPayment: "selectAccountsForPayment",
  SetupPaymentPlanConfiguration: "setupPaymentPlanConfiguration",
  SetupPaymentSchedule: "setupPaymentSchedule",
  // NOTE: These currently point to the same component, but we need the separation to properly keep track of previous and next steps.
  SelectPaymentMethod: "selectPaymentMethod",
};

const initialState = {
  // Shared state fields
  // ------------------------------
  selectedAccounts: [],
  isInterestApplicable: false, // If any account accrues interest, this will be set to true. This is used to determine if we need to call the backend to recalculate the balance when dates/amounts are paid.
  originalTotalAmount: null,
  partialPaymentAmount: null, // If a partial payment is flagged, this will be non-empty
  remainingAmountToAllocate: null,
  totalAmount: null, // NOTE: This value is only available on and after the OneTimePaymentSchedule.
  paidTo: null, // NOTE: agency - Paid to Agency; creditor - Paid to creditor; forwarding - Paid to Forwarding Entity.
  isZeroBalanceAccountVisible: false,
  isPaymentPlan: false, // defaults to one time payment, else it's a payment plan
  isNewPaymentMethod: true,

  // One Time Payments state fields
  // ------------------------------

  // full - Payment covers all selected account balances.
  // partial - Payment was made, but not the entire full amount.
  // settled - Payment covers all selected account balance, but payment is a settled amount (less than the full).
  paymentIntentType: "full", // [full, partial, settled]

  // Payment Plan state fields
  // ------------------------------
  // NOTE: isRecurringPaymentAmountOrNumberOfPayments is used to determine if the 'recurring payment amount'
  // or the 'number of payments' has changed. Only one of these values can be used to generate
  // the Payment Schedule, as the balance may change due to interest accrual.
  // Initially it is set to false, which means the number of payments is fixed
  // and the recurring payment amount is allowed to change.
  isRecurringPaymentAmountOrNumberOfPayments: false,
  // The isPaymentDataEditable determines whether the payment data can be edited in the Payment Schedule screen.
  // This is currently only True if all the selected accounts do not accrue interest.
  isPaymentDataEditable: false,
  numberOfPayments: 1, // Only applicable if it's a payment plan
  recurringPaymentStartDate: moment().format(DATE_FORMAT),
  recurringPaymentInterval: "monthly", // ["monthly", "weekly, biweekly", "bimonthly"]
  recurringPaymentAmount: null,
  downPaymentAmount: 0,
  paymentsSchedule: [],
  isSkipBackdatingValidation: false,
  isIncludeRemainderToLastPayment: true,
  // Payment Method Screen
  paymentMethodOptions: undefined,
  currentView: PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment, // Defaults to first view in the Payments tab.
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    getNextView: (state) => {
      switch (state.currentView) {
        case PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment:
          if (!state.isPaymentPlan) {
            state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.OneTimePaymentSchedule;
          } else {
            state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration;
          }
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration:
          // Take the paymentSchedule, and add an index to each payment. This is used to match the index to the form fields.
          state.paymentsSchedule = state.paymentsSchedule.map((payment, index) => ({
            ...payment,
            index,
          }));
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentSchedule;
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentSchedule:
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentMethod;
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.OneTimePaymentSchedule:
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentMethod;
          break;
        default:
          break;
      }
    },
    getPreviousView: (state) => {
      switch (state.currentView) {
        case PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment:
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration:
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment;
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.OneTimePaymentSchedule:
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment;
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentSchedule:
          state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration;
          break;
        case PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentMethod:
          if (!state.isPaymentPlan) {
            state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.OneTimePaymentSchedule;
          } else {
            state.currentView = PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentSchedule;
          }
          break;
        default:
          break;
      }
    },
    // Shared Reducers
    // ------------------------------

    setPaidTo: (state, action) => {
      state.paidTo = action.payload;
      if (state.paidTo === "creditor" || state.paidTo === "forwarding_entity") {
        // If the payment is collected by the Creditor or a Forwarding entity, then we do not allow Payment plans, because
        // we should only be trying to log the payment.  Doesn't make sense to process a future payment
        // that has been collected by the Creditor.
        state.isPaymentPlan = false;
      }
    },
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
    },
    setPaymentIntentType: (state, action) => {
      state.paymentIntentType = action.payload;
    },

    // One Time Payment Reducers
    // ------------------------------
    setAsOneTimePayment: (state) => {
      state.isPaymentPlan = false;
    },
    setPaymentTotalAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setPaymentScheduleAmountChange: (state, action) => {
      //  TODO: Review this fully.
      // If a single payment amount is changed, need to update 3 things.
      // 1. The overall total remaining amount to distribute (across all payments)
      const { paymentIndex, newPaymentAmount, totalRemainingAmountToDistribute } = action.payload;
      // const individualPayment = [
      //   ...state.paymentPlan[`account-${individualPaymentIds.currentAccountId.toString()}`],
      // ][individualPaymentIds.singlePaymentIndex];
      const individualPayment = state.paymentsSchedule.find(
        (payment) => payment.index === paymentIndex,
      );
      individualPayment.totalAmount = newPaymentAmount;

      // // 2. The individual payment's totalAmount being paid
      // // Update the amount remaining to allocate for this payment.
      // let totalForPayment = 0;
      // let index = 0;
      // state.paymentsSchedule.forEach((accountKey) => {
      //   totalForPayment += state.paymentsSchedule[index].totalAmount;
      //   index += 1;
      // });
      // individualPayment.data.forEach((tableRow) => {
      //   totalForPayment += tableRow.total;
      // });
      // individualPayment.remainingToAllocate = individualPayment.totalAmount - totalForPayment;
      // 3. The individual payment's remaining amount to distribute
      state.remainingAmountToAllocate = totalRemainingAmountToDistribute;
    },
    setPaymentScheduleDateChange: (state, action) => {
      const { paymentIndex, scheduledDate } = action.payload;
      const index = state.paymentsSchedule.findIndex((payment) => payment.index === paymentIndex);
      state.paymentsSchedule[index] = {
        ...state.paymentsSchedule[index],
        scheduledDate,
      };
    },
    setOneTimePaymentsSchedule: (state, action) => {
      const {
        partialPaymentAmount,
        originalTotalAmount,
        totalAmount,
        downPaymentAmount,
        paymentsSchedule,
        isInterestApplicable,
      } = action.payload;
      state.paymentsSchedule = paymentsSchedule;
      state.partialPaymentAmount = partialPaymentAmount;
      state.originalTotalAmount = originalTotalAmount;
      state.totalAmount = totalAmount;
      state.downPaymentAmount = downPaymentAmount;
      state.remainingAmountToAllocate = 0;
      state.isInterestApplicable = isInterestApplicable;
      state.isPaymentDataEditable = true;
    },

    // Payment Plan Reducers
    // ------------------------------
    setAsPaymentPlan: (state) => {
      state.isPaymentPlan = true;
    },
    setupAsPaymentPlan: (state, action) => {
      const { partialPaymentAmount, originalTotalAmount, totalAmount, isInterestApplicable } =
        action.payload;
      state.partialPaymentAmount = partialPaymentAmount;
      state.originalTotalAmount = originalTotalAmount;
      state.totalAmount = totalAmount;
      // Reset defaults
      state.numberOfPayments = 1;
      state.downPaymentAmount = null;
      state.recurringPaymentStartDate = moment().format(DATE_FORMAT);
      state.recurringPaymentInterval = "monthly";
      state.recurringPaymentAmount = null;
      state.paymentsSchedule = [];
      state.isIncludeRemainderToLastPayment = true;
      state.isRecurringPaymentAmountOrNumberOfPayments = false;
      state.isInterestApplicable = isInterestApplicable;
    },
    setPaymentPlanConfiguration: (state, action) => {
      const {
        downPaymentAmount,
        numberOfPayments,
        recurringPaymentAmount,
        recurringPaymentStartDate,
        recurringPaymentInterval,
        isRecurringPaymentAmountOrNumberOfPayments,
        isIncludeRemainderToLastPayment,
        isPaymentDataEditable,
        paymentsSchedule,
      } = action.payload;
      state.downPaymentAmount = downPaymentAmount;
      state.numberOfPayments = numberOfPayments;
      state.recurringPaymentAmount = recurringPaymentAmount;
      state.recurringPaymentStartDate = recurringPaymentStartDate;
      state.recurringPaymentInterval = recurringPaymentInterval;
      state.isRecurringPaymentAmountOrNumberOfPayments = isRecurringPaymentAmountOrNumberOfPayments;
      state.isIncludeRemainderToLastPayment = isIncludeRemainderToLastPayment;
      state.isPaymentDataEditable = isPaymentDataEditable;
      state.paymentsSchedule = paymentsSchedule;

      // Initial values for Payment Schedule screen
      state.remainingAmountToAllocate = 0;
      state.totalAmount = paymentsSchedule.reduce(
        (total, payment) => total.add(payment.totalAmount),
        currency(0, { precision: 4 }),
      ).value;
    },
    setIsSkipBackdatingValidation: (state, action) => {
      state.isSkipBackdatingValidation = action.payload;
    },
    setRecurringPaymentAmount: (state, action) => {
      state.recurringPaymentAmount = action.payload;
    },
    setIsZeroBalanceAccountVisibleOption: (state, action) => {
      state.isZeroBalanceAccountVisible = action.payload;
    },
    setIsNewPaymentMethodOption: (state, action) => {
      state.isNewPaymentMethod = action.payload;
    },
    resetMakeAPayment: () => initialState,
  },

  extraReducers: () => {},
});

export const {
  setPaymentTotalAmount,
  getNextView,
  getPreviousView,
  setAsPaymentPlan,
  setAsOneTimePayment,
  setPaymentScheduleAmountChange,
  setPaymentScheduleDateChange,
  setPaidTo,
  setSelectedAccounts,
  setPaymentIntentType,
  setOneTimePaymentsSchedule,
  setupAsPaymentPlan,
  setPaymentPlanConfiguration,
  setIsNewPaymentMethodOption,
  resetMakeAPayment,
  setIsZeroBalanceAccountVisibleOption,
  setIsSkipBackdatingValidation,
} = paymentsSlice.actions;

export const selectPaymentsSlice = (state) => ({
  ...state.payments,
  isSettlement: state.payments.paymentIntentType === "settlement",
  isPartialPayment: state.payments.paymentIntentType === "partial",
  isFullPayment: state.payments.paymentIntentType === "full",
});

export default paymentsSlice.reducer;
