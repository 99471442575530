import { Alert, Button, Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDatePicker } from "components/aktDatePicker";
import { useFetchCommunicationMethodsQuery } from "features/communicationMethodsTable/communicationMethodsAPI";
import {
  useCreateTaskMutation,
  useFetchDebtorAccountsQuery,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 12px;
  white-space: pre-wrap;
`;

function CallAiModal({ title, open, onOk, onCancel }) {
  const [form] = useForm();
  const { debtorId } = useParams();
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const { data: communicationMethods, isLoading: isCommunicationMethodsLoading } =
    useFetchCommunicationMethodsQuery();
  const { data: accounts, isLoading: isAccountsLoading } = useFetchDebtorAccountsQuery({
    debtorId,
  });

  const [previousErrorMsg, setPreviousErrorMsg] = useState(null);

  const aiDialerOptions = communicationMethods?.reduce((acc, method) => {
    if (method.medium !== "ai_dialer" || method.isArchived) {
      return acc;
    }
    const isMultipleAccountsPerTask = method.useMultipleAccountsPerTask;
    return [
      ...acc,
      {
        label: isMultipleAccountsPerTask ? `${method.name} (Multiple Accounts)` : method.name,
        value: method.id,
      },
    ];
  }, []);

  const onSubmit = async () => {
    const values = await form.validateFields();

    const result = await createTask({ ...values, debtorId });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      return message.success(`Call(s) are scheduled to send on ${values.scheduledDate}`);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      const errors = result.error?.data?.error?.details?.errors;
      if (errors) {
        setPreviousErrorMsg(errors.join("\n"));
      }
    }
  };

  const disabledDate = (input) => {
    // Can not select days before today.
    return input && input < moment().startOf("day");
  };

  const onFormChange = () => {
    setPreviousErrorMsg(null);
  };

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          OK
        </Button>,
      ]}
    >
      {previousErrorMsg && <StyledAlert description={previousErrorMsg} type="error" />}

      <StyledForm
        layout="vertical"
        form={form}
        onValuesChange={onFormChange}
        initialValues={{ scheduledDate: moment().format(DATE_FORMAT) }}
      >
        <Form.Item
          label="AI Phone Agent Template"
          name="communicationMethodId"
          rules={[{ required: true, message: "Select a template for the AI Phone Agent" }]}
        >
          <Select
            popupMatchSelectWidth={false}
            placeholder="Select one..."
            loading={isCommunicationMethodsLoading}
            options={aiDialerOptions}
          />
        </Form.Item>
        <Form.Item
          label="Account(s)"
          name="accountIds"
          rules={[{ required: true, message: "Select an account" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select one..."
            loading={isAccountsLoading}
            options={accounts?.map((account) => ({
              value: account.id,
              label: `ID: ${account.id} - (${account.externalId})`,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="scheduledDate"
          label="Date to Send"
          rules={[{ required: true, message: "Select Date" }]}
        >
          <AktDatePicker disabledDate={disabledDate} />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default CallAiModal;
