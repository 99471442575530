import { Checkbox, Form, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { camelToWords } from "common/utils";
import {
  useCreateWorkflowStateMutation,
  useUpdateWorkflowStateMutation,
} from "features/workflows/workflowStatesAPI";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function WorkflowStateModal({ title, currentWorkflowState, open, onCancel, onOk }) {
  const { workflowId } = useParams();
  const [form] = useForm();
  const isNew = !currentWorkflowState;
  const [createWorkflowState, { isLoading }] = useCreateWorkflowStateMutation();
  const [updateWorkflowState] = useUpdateWorkflowStateMutation();
  const [externalNameChanged, setExternalNameChanged] = useState(false);

  const onSubmit = async () => {
    const values = await form.validateFields();
    const result = isNew
      ? await createWorkflowState({ ...values, workflowId })
      : await updateWorkflowState({ ...values, workflowId, stateId: currentWorkflowState.id });
    if ("data" in result) {
      message.success(
        isNew ? "Workflow state created successfully!" : "Workflow state updated successfully!",
      );
      onOk();
      form.resetFields();
    }

    if ("error" in result) {
      message.error(isNew ? "Failed to create workflow state" : "Failed to update workflow state");
    }
  };

  const workflowStateInternalStatuses = [
    "isPaidInFull",
    "isSettledInFull",
    "isClosed",
    "isSuppressFromCreditReporting",
    "isDeleteFromCreditReporting",
    "isExcludeFromConsumerPortal",
  ];

  useEffect(() => {
    if (currentWorkflowState) {
      form.setFieldsValue(currentWorkflowState);
    } else {
      form.resetFields();
    }
  }, [form, currentWorkflowState]);

  return (
    <Modal
      confirmLoading={isLoading}
      maskClosable={false}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={isNew ? "Create" : "Update"}
    >
      <StyledForm layout="vertical" form={form}>
        {["code", "name"].map((each) => (
          <Form.Item
            key={each}
            label={camelToWords(each)}
            name={each}
            rules={[{ required: true, message: "This field is required." }]}
          >
            <Input />
          </Form.Item>
        ))}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.name !== currentValues.name}
        >
          {({ getFieldValue, setFieldValue }) => {
            const name = getFieldValue("name");

            // When creating a new workflow, if the external name hasn't been changed, it is set to the name.
            if (!externalNameChanged && isNew) {
              setFieldValue("externalName", name);
            }
            return (
              <Form.Item
                key="externalName"
                label="External Name"
                name="externalName"
                rules={[{ required: true, message: "This field is required." }]}
              >
                <Input onChange={() => setExternalNameChanged(true)} />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        {workflowStateInternalStatuses.map((each) => (
          <Form.Item key={each} valuePropName="checked" name={each}>
            <Checkbox>{camelToWords(each)}</Checkbox>
          </Form.Item>
        ))}
      </StyledForm>
    </Modal>
  );
}

export default WorkflowStateModal;
