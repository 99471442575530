import { Form, Modal, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  useFetchLetterTemplatesQuery,
  useLazyDownloadLegalLettersQuery,
} from "features/letterTemplates/letterTemplatesAPI";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useFetchDebtorGarnishmentsQuery } from "features/garnishments/garnishmentsAPI";
import moment from "moment-timezone";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function LegalLettersModal({ title, open: lawsuit, onOk, onCancel }) {
  const { debtorId } = useParams();
  const [form] = useForm();
  const { data: letterTemplates, isLoading } = useFetchLetterTemplatesQuery();
  const { data: garnishments, isLoading: isGarnishmentLoading } = useFetchDebtorGarnishmentsQuery({
    debtorId,
  });

  const [downloadLegalLetters] = useLazyDownloadLegalLettersQuery();

  const onSubmit = async () => {
    const values = await form.validateFields();
    await downloadLegalLetters({
      debtorId,
      lawsuitId: lawsuit.id,
      data: values,
    });
    await onOk();
    form.resetFields();
    return message.success("Letters successfully downloaded");
  };

  return (
    <Modal maskClosable={false} title={title} open={lawsuit} onOk={onSubmit} onCancel={onCancel}>
      <StyledForm layout="vertical" form={form}>
        <Form.Item
          label="Letter Template"
          name="letterTemplateIds"
          rules={[{ required: true, message: "Select letter template" }]}
        >
          <Select
            disabled={isLoading}
            mode="multiple"
            placeholder="Select templates"
            showSearch
            allowClear
            popupMatchSelectWidth={false}
            options={letterTemplates
              ?.filter((template) => template.type === "legal_filings")
              .map((template) => ({
                label: template.filename,
                value: template.id,
              }))}
          />
        </Form.Item>
        <Form.Item label="Garnishment" name="garnishmentId">
          <Select
            showSearch
            popupMatchSelectWidth={false}
            disabled={isGarnishmentLoading}
            placeholder="Select garnishment"
            options={garnishments?.map((garnishment) => ({
              label: `${garnishment.id} - ${moment(garnishment.createdAt).format(
                "MM/DD/YY HH:mm",
              )}`,
              value: garnishment.id,
            }))}
          />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default LegalLettersModal;
