import api, { transformMultipart, transformRequest, transformResponse } from "services/api";

export const creditorProfileDocumentsApi = api
  .enhanceEndpoints({
    addTagTypes: ["CreditorDocuments"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCreditorPortalDocuments: build.query({
        query: () => `/api/v1/creditor/documents/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "CreditorDocuments", id })),
                { type: "CreditorDocuments", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'CreditorContacts', id: 'LIST' }` is invalidated
              [{ type: "CreditorDocuments", id: "LIST" }],
      }),
      deleteCreditorPortalDocument: build.mutation({
        query: (payload) => ({
          url: `/api/v1/creditor/documents/${payload.documentId}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "CreditorDocuments", id: "LIST" }],
      }),
      createCreditorPortalDocument: build.mutation({
        query: ({ file, ...values }) => ({
          url: `/api/v1/creditor/documents/create/`,
          method: "POST",
          body: transformMultipart(values, file),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all CreditorDocuments-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "CreditorDocuments", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useDeleteCreditorPortalDocumentMutation,
  useFetchCreditorPortalDocumentsQuery,
  useCreateCreditorPortalDocumentMutation,
} = creditorProfileDocumentsApi;
