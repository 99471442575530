import { Button, Checkbox, Col, Divider, Form, InputNumber, Layout, Row } from "antd";
import { formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useUserType } from "features/auth";
import { useFetchDebtorAccountsQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import { useGetPaymentMethodOptionsQuery } from "features/payments/paymentsAPI";
import {
  getNextView,
  getPreviousView,
  selectPaymentsSlice,
  setIsNewPaymentMethodOption,
  setPaymentScheduleAmountChange,
  setPaymentScheduleDateChange,
  setPaymentTotalAmount,
  setIsSkipBackdatingValidation,
} from "features/payments/paymentsSlice";
import { useFetchAccountSettlementPaymentRulesQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const BottomSpacedStrong = styled(Layout.Content)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledHeader = styled.h4`
  font-weight: 500;
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 550px;
`;

const StyledFormItem = styled(Form.Item)`
  max-width: 250px;
`;

const StyledWarningSpan = styled.span`
  color: red;
  margin-left: 20px;
  font-size: 13px;
`;

function SetupPaymentSchedule() {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { debtorId } = useParams();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: agencyAccounts, isLoading: isAgencyAccountsLoading } = useFetchDebtorAccountsQuery(
    { debtorId },
    { skip: !isAgencyUserType }, // skip unless we are an agency user
  );

  const { data: creditorAccounts, isLoading: isCreditorAccountsLoading } =
    useFetchCreditorPortalDebtorAccountsQuery(
      { debtorId },
      { skip: !isCreditorUserType }, // skip if we are an agency user
    );

  const accounts = isAgencyUserType ? agencyAccounts : creditorAccounts;

  const [form] = Form.useForm();
  const { data: paymentMethodOptions } = useGetPaymentMethodOptionsQuery(
    {
      debtorId,
    },
    { skip: !isAgencyUserType },
  );
  const { data: settlementPaymentRules } = useFetchAccountSettlementPaymentRulesQuery(
    {
      accountIds: paymentsSlice.selectedAccounts,
    },
    {
      skip: !paymentsSlice.selectedAccounts || paymentsSlice.selectedAccounts.length === 0,
    },
  );

  const minimumPaymentAmount = useMemo(
    () =>
      settlementPaymentRules?.reduce((max, rule) => {
        if (!rule.paymentRule?.minimumPaymentAmount) {
          return max;
        }
        const amount = currency(rule.paymentRule.minimumPaymentAmount, { precision: 4 }).value;
        return Math.max(max, amount);
      }, 0),
    [settlementPaymentRules],
  );

  const maximumPlanLengthInDays = useMemo(
    () =>
      settlementPaymentRules?.reduce((min, rule) => {
        if (!rule.paymentRule?.maximumPlanLengthInDays) {
          return min;
        }
        return Math.min(min, rule.paymentRule.maximumPlanLengthInDays);
      }, Infinity),
    [settlementPaymentRules],
  );

  const maximumDelayInDays = useMemo(
    () =>
      settlementPaymentRules?.reduce((min, rule) => {
        if (!rule.paymentRule?.maximumDelayInDays) {
          return min;
        }
        return Math.min(min, rule.paymentRule.maximumDelayInDays);
      }, Infinity),
    [settlementPaymentRules],
  );

  const initialValues = useMemo(
    () => ({
      ...paymentsSlice.paymentsSchedule?.reduce((acc, payment, index) => {
        return {
          ...acc,
          [index]: {
            totalAmount: payment.totalAmount,
            scheduledDate: payment.scheduledDate,
          },
        };
      }, {}),
      partialAmount: paymentsSlice.totalAmount,
    }),
    [paymentsSlice.totalAmount, paymentsSlice.paymentsSchedule],
  );

  const onFinish = async () => {
    await form.validateFields();
    if (paymentMethodOptions?.length > 0) {
      dispatch(setIsNewPaymentMethodOption(false));
    }
    dispatch(getNextView());
  };

  const onChangeIsSkipBackdatingValidation = async (checkbox) => {
    dispatch(setIsSkipBackdatingValidation(checkbox.target.checked));
  };

  const onChangePaymentAmount = (paymentIndex) => {
    // We use JS closure to capture the paymentIndex on initial creation of this function.
    return (newPaymentAmount) => {
      const allocatedAmount = paymentsSlice.paymentsSchedule.reduce((total, payment) => {
        return total.add(form.getFieldValue([payment.index, "totalAmount"]));
      }, currency(0, { precision: 4 }));

      const totalRemainingAmountToDistribute = currency(paymentsSlice.totalAmount, {
        precision: 4,
      }).subtract(allocatedAmount).value;

      dispatch(
        setPaymentScheduleAmountChange({
          paymentIndex,
          newPaymentAmount,
          totalRemainingAmountToDistribute:
            paymentsSlice.isPartialPayment || paymentsSlice.isSettlementPayment
              ? 0
              : totalRemainingAmountToDistribute,
        }),
      );
    };
  };

  const onDateChange = (paymentIndex) => {
    // We use JS closure to capture the paymentIndex on initial creation of this function.
    return (scheduledDate) => {
      // NOTE: We can use the following to print out variables in the form.
      // console.log("form values", form.getFieldsValue());
      // form.setFieldsValue({ recurringAmount });

      dispatch(
        setPaymentScheduleDateChange({
          paymentIndex,
          scheduledDate,
        }),
      );
    };
  };

  const doSelectedAccountsHaveInterest = useMemo(() => {
    if (!accounts?.length) return null;

    // Filter only the relevant accounts
    const relevantAccounts = accounts.filter((acct) =>
      paymentsSlice.selectedAccounts.includes(acct.id),
    );
    if (!relevantAccounts.length) return null;
    return relevantAccounts.some((acct) => acct.interestRate && parseFloat(acct.interestRate) > 0);
  }, [accounts, paymentsSlice.selectedAccounts]);

  const lastInterestCalculationDate = useMemo(() => {
    if (!accounts?.length) return null;

    // Filter only the relevant accounts
    const relevantAccounts = accounts.filter((acct) =>
      paymentsSlice.selectedAccounts.includes(acct.id),
    );
    if (!relevantAccounts.length) return null;

    // Returns a moment date or null if none is valid
    const maxDate = relevantAccounts.reduce((acc, acct) => {
      const acctDate = moment(acct.lastInterestCalculationDate);

      // Skip invalid dates
      if (!acctDate.isValid()) {
        return acc;
      }

      // If we haven’t stored anything yet or found a larger date, use the new one
      if (acc === null || acctDate.isAfter(acc)) {
        return acctDate;
      }
      return acc;
    }, null);

    return maxDate;
  }, [accounts, paymentsSlice.selectedAccounts]);

  const shouldDisableDate = (current) => {
    // We need to ensure that the paymentIntent.scheduledDate >= lastInterestCalculationDate when the following conditions are met:
    // 1. The validation is enabled (isSkipBackdatingValidation is false)
    // 2. At least one of selected accounts have interest
    // 3. At least one of the selected accounts has a lastInterestCalculationDate set
    // If the three conditions are met, we must disable the dates that are before the lastInterestCalculationDate
    if (
      !paymentsSlice?.isSkipBackdatingValidation &&
      doSelectedAccountsHaveInterest &&
      lastInterestCalculationDate &&
      lastInterestCalculationDate.isAfter(current)
    ) {
      return true;
    }
    if (!paymentsSlice.isPaymentPlan && paymentsSlice.paidTo === "creditor" && !isAgencyUserType) {
      // For one time creditor payment logged by creditor user, disallow future payments or backdating of payment past
      // the current month.
      const today = moment().endOf("day");
      const monthStart = moment().startOf("month");
      return current && (current > today || current < monthStart);
    }
    return false;
  };

  return (
    <div>
      <BottomSpacedStrong>Installments Breakdown</BottomSpacedStrong>
      <StyledForm
        form={form}
        layout="vertical"
        initialValues={initialValues}
        validateMessages={{ required: "This is a required field" }}
      >
        {paymentsSlice.paymentsSchedule.map((payment, index) => (
          <div key={payment.index}>
            <StyledHeader>{payment.title}</StyledHeader>
            <Row>
              <StyledFormItem
                label="Date of Payment"
                name={[payment.index, "scheduledDate"]}
                rules={[
                  { required: true },
                  {
                    validator: async (_, value) => {
                      const lastPaymentDate = moment(value).add(1, "d");
                      const maxDelay = paymentsSlice.isPaymentPlan
                        ? maximumPlanLengthInDays
                        : maximumDelayInDays;
                      if (moment().add(maxDelay, "d").isBefore(lastPaymentDate)) {
                        return Promise.reject(
                          new Error(`The payment date must be within ${maxDelay} days from today.`),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <AktDatePicker
                  onChange={onDateChange(payment.index)}
                  disabledDate={shouldDisableDate}
                  disabled={!paymentsSlice.isPaymentDataEditable}
                  loading={isAgencyAccountsLoading || isCreditorAccountsLoading}
                />
              </StyledFormItem>
              <StyledFormItem
                style={{ marginLeft: 24 }}
                label="Payment Amount"
                name={[payment.index, "totalAmount"]}
                rules={[
                  { required: false },
                  {
                    validator: async (_, value) => {
                      if (value < 0) {
                        return Promise.reject(new Error("Amount must be greater than 0"));
                      }
                      if (value < minimumPaymentAmount) {
                        return Promise.reject(
                          new Error(`Amount must be greater than ${minimumPaymentAmount}`),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: 138 }}
                  disabled={
                    !paymentsSlice.isPaymentDataEditable ||
                    // Or if it's not a plan
                    !paymentsSlice.isPaymentPlan
                  }
                  prefix="$"
                  controls={false}
                  precision={2}
                  onChange={onChangePaymentAmount(payment.index)}
                  min={0}
                  max={paymentsSlice.totalAmount}
                />
              </StyledFormItem>
            </Row>
          </div>
        ))}
        <Form.Item hidden name="partialAmount">
          <InputNumber />
        </Form.Item>
        {
          // NOTE: It doesn't make sense to create a payment plan that backdates payments.
          // If the selected accounts have interest, show the message.
          doSelectedAccountsHaveInterest && !paymentsSlice.isPaymentPlan && (
            <div>
              <span>
                A payment can be backdated only as far as the latest interest calculation date of
                the selected accounts ({lastInterestCalculationDate.format("M/D/YYYY")}).{" "}
                {isAgencyUserType ? "If you need to override this rule, check the box below." : ""}
              </span>
            </div>
          )
        }
        {
          // Enable Override rule opdation If the selected accounts have interest
          doSelectedAccountsHaveInterest && isAgencyUserType && !paymentsSlice.isPaymentPlan && (
            <Form.Item
              name="isSkipBackdatingValidation"
              valuePropName="checked"
              // Only show this warning as "help" text if the box is checked.
              help={
                paymentsSlice?.isSkipBackdatingValidation && (
                  <StyledWarningSpan>
                    This operation can affect interest calculations that may require manual
                    corrections.
                  </StyledWarningSpan>
                )
              }
            >
              <Checkbox onChange={onChangeIsSkipBackdatingValidation}>
                Skip backdating validation
              </Checkbox>
            </Form.Item>
          )
        }

        <Divider />
        {paymentsSlice.paymentIntentType === "partial" && (
          <Col>
            <div>
              <span>Amount Due Across Selected Accounts With Interest: </span>
              <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
            </div>
            <div>
              <span>Partial Payment Amount: </span>
              <strong>{`${formatCurrency(paymentsSlice.totalAmount)}`}</strong>
            </div>
          </Col>
        )}
        {paymentsSlice.isFullPayment && (
          <>
            <div>
              <span>Amount Due Across Selected Accounts Prior To Interest: </span>
              <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
            </div>
            <div>
              <span>Payment Amount: </span>
              <strong>{`${formatCurrency(paymentsSlice.totalAmount)}`}</strong>
            </div>
            {/*
                When we're creating a payment plan on any accounts with interest, we explicitly do
                not allow them to change the amounts/dates.
            */}
            {paymentsSlice.isPaymentPlan && !paymentsSlice.isInterestApplicable && (
              <div>
                <span>Remaining to Distribute: </span>
                <strong>{formatCurrency(paymentsSlice.remainingAmountToAllocate)}</strong>
              </div>
            )}
          </>
        )}
        {paymentsSlice.paymentIntentType === "settlement" && (
          <>
            <div>
              <span>Amount Due Across Selected Accounts: </span>
              <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
            </div>
            <div>
              <span>Payment Amount: </span>
              <strong>{`${formatCurrency(paymentsSlice.totalAmount)}`}</strong>
            </div>
          </>
        )}
        <br />
        <br />
        <Form.Item key="navigationButtons">
          <Button
            onClick={() => {
              dispatch(setPaymentTotalAmount(form.getFieldValue("partialAmount")));
              dispatch(getPreviousView());
            }}
            key="back"
          >
            Back
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            disabled={
              paymentsSlice.remainingAmountToAllocate !== 0 ||
              // Check if there are any errors in the form.
              form.getFieldsError().some(({ errors }) => errors.length)
            }
            onClick={onFinish}
            key="continue"
          >
            Continue
          </Button>
        </Form.Item>
      </StyledForm>
    </div>
  );
}

export default SetupPaymentSchedule;
