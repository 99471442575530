import { Radio, Select } from "antd";
import { booleanToYesNo } from "common/utils";
import { EditableCardComponent } from "components/editableCardComponent";
import { useFetchFeePlanRuleSetsQuery } from "features/feePlans/feePlansAPI";
import { useFetchTaxPlanRuleGroupsQuery } from "features/taxPlans/taxPlansAPI";
import { PERMISSIONS, useAuthorization } from "features/permissions";

const REMITTANCE_OPTIONS = [
  {
    label: "Gross",
    value: "gross",
  },
  {
    label: "Net",
    value: "net",
  },
];

const BILLING_OPTIONS = [
  {
    label: "No Balance Forward",
    value: "gross",
  },
  {
    label: "Balance Forward",
    value: "net",
  },
];

const PAYMENT_DELIVERY_METHOD_OPTIONS = [
  {
    label: "Checks",
    value: "checks",
  },
  {
    label: "ACH",
    value: "ach",
  },
];

export default function CreditorInvoiceSettingsCard({ currentCreditor, onSave }) {
  const { data: feePlanRuleSets } = useFetchFeePlanRuleSetsQuery();
  const { data: taxPlanRuleGroups } = useFetchTaxPlanRuleGroupsQuery();
  const { data: canEditClientProfileInvoiceSettings } = useAuthorization(
    PERMISSIONS.CLIENT_PROFILE__CAN_EDIT_INVOICES,
  );

  const rows = [
    {
      label: "Fee Plan Rule Set",
      name: ["invoiceConfig", "feePlanAssignmentRulesetId"],
      formElement: (
        <Select
          options={feePlanRuleSets?.map((ruleSet) => ({ label: ruleSet.name, value: ruleSet.id }))}
        />
      ),
      formItemProps: {
        rules: [{ required: true, message: "Fee plan rule set is required" }],
      },
      render: (_, record) => {
        const ruleSet = feePlanRuleSets?.find(
          (x) => x.id === record?.invoiceConfig?.feePlanAssignmentRulesetId,
        );
        return ruleSet?.name;
      },
    },
    {
      label: "Tax Plan Rule Group",
      name: ["invoiceConfig", "taxPlanRuleGroupId"],
      formElement: (
        <Select
          options={[
            {
              label: "Unassigned",
              value: null,
            },
            ...(taxPlanRuleGroups?.map((ruleGroup) => ({
              label: ruleGroup.name,
              value: ruleGroup.id,
            })) ?? []),
          ]}
        />
      ),
      render: (_, record) => {
        const ruleGroup = taxPlanRuleGroups?.find(
          (x) => x.id === record?.invoiceConfig?.taxPlanRuleGroupId,
        );
        return ruleGroup?.name;
      },
    },
    {
      label: "Agency Principal Payment Remit Type",
      name: ["invoiceConfig", "principalRemit", "agency"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.principalRemit?.agency,
        );
        return remitType?.label;
      },
    },
    {
      label: "Agency Interest Payment Remit Type",
      name: ["invoiceConfig", "interestRemit", "agency"],
      formElement: (
        <Select
          options={REMITTANCE_OPTIONS.map((each) => ({ label: each.label, value: each.value }))}
        />
      ),
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.interestRemit?.agency,
        );
        return remitType?.label;
      },
    },
    {
      label: "Agency Fees Remit Type",
      name: ["invoiceConfig", "feesRemit", "agency"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.feesRemit?.agency,
        );
        return remitType?.label;
      },
    },
    {
      label: "Client Principal Payment Remit Type",
      name: ["invoiceConfig", "principalRemit", "creditor"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.principalRemit?.creditor,
        );
        return remitType?.label;
      },
    },
    {
      label: "Client Interest Payment Remit Type",
      name: ["invoiceConfig", "interestRemit", "creditor"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.interestRemit?.creditor,
        );
        return remitType?.label;
      },
    },
    {
      label: "Creditor Fees Remit Type",
      name: ["invoiceConfig", "feesRemit", "creditor"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.feesRemit?.creditor,
        );
        return remitType?.label;
      },
    },
    {
      label: "Invoice Adjustment Remit Type",
      name: ["invoiceConfig", "adjustments"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Remit type is required" }],
      },
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.adjustments,
        );
        return remitType?.label;
      },
    },
    {
      label: "Balance Forward",
      name: ["invoiceConfig", "billing"],
      formElement: <Select options={BILLING_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Invoice billing type is required" }],
        tooltip: (
          <>
            <p>No Balance Forward - Bill any amount due agency for each invoice separately.</p>
            <p>
              Balance Forward - Deduct any prior invoice amount due agency from next remittance.
            </p>
          </>
        ),
      },
      render: (_, record) => {
        const billingType = BILLING_OPTIONS.find((x) => x.value === record?.invoiceConfig?.billing);
        return billingType?.label;
      },
    },
    {
      label: "Payment Method",
      name: ["invoiceConfig", "paymentMethodType"],
      formElement: <Select options={PAYMENT_DELIVERY_METHOD_OPTIONS} />,
      formItemProps: {
        rules: [{ required: true, message: "Invoice payment method type is required" }],
        tooltip: (
          <>
            <p>ACH - Amount due Client to be paid in ACH payments.</p>
            <p>Checks - Amount due Client to be paid via printing checks.</p>
          </>
        ),
      },
      render: (_, record) => {
        const paymentMethodType = PAYMENT_DELIVERY_METHOD_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.paymentMethodType,
        );
        return paymentMethodType?.label;
      },
    },
    {
      label: "Invoice Tax Remit Type",
      name: ["invoiceConfig", "taxRemit"],
      formElement: <Select options={REMITTANCE_OPTIONS} />,
      render: (_, record) => {
        const remitType = REMITTANCE_OPTIONS.find(
          (x) => x.value === record?.invoiceConfig?.taxRemit,
        );
        return remitType?.label;
      },
    },
    {
      label: "Include Children Creditors' Invoices",
      name: ["invoiceConfig", "includeChildrenCreditorsInvoices"],
      formElement: (
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      formItemProps: {
        tooltip: "Does this creditor want to receive invoices for all of their children creditors?",
      },
      render: (_, record) =>
        booleanToYesNo(record?.invoiceConfig?.includeChildrenCreditorsInvoices ?? false),
    },
  ];

  return (
    <EditableCardComponent
      hasEditPermission={canEditClientProfileInvoiceSettings}
      title="Invoice Settings"
      record={currentCreditor}
      rows={rows}
      onSave={onSave}
    />
  );
}
