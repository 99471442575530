import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Popconfirm, Row, Table, message } from "antd";
import config from "app/envConfig";
import { camelToWords, formatDate, snakeToCamelCase } from "common/utils";
import {
  useFetchCreditorPortalDocumentsQuery,
  useDeleteCreditorPortalDocumentMutation,
} from "features/creditorProfileDocumentsTable/creditorPortal/creditorProfileDocumentsAPI";
import UploadCreditorDocumentModal from "features/creditorProfileDocumentsTable/creditorPortal/uploadCreditorDocumentModal";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledDocumentButton = styled(Button)`
  padding-top: 0px;
  padding-bottom: 10px;
  line-height: 0px;
`;

const StyledHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 36px;
  text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  display: flex;
`;

const StyledDivider = styled(Divider)`
  position: absolute;
  top: 28px;
  left: 0;
  background-color: #f9f3f3;
`;

const StyledCard = styled(Card)`
  flex: 1;
  .ant-card-body {
    padding: 16px;
  }
`;

function CreditorProfileDocumentsTable() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [isCreditorPortalUploadDocumentModalVisible, setCreditorPortalUploadDocumentModalVisible] =
    useState(false);
  const { data: documents, isLoading } = useFetchCreditorPortalDocumentsQuery();
  const [deleteDocument] = useDeleteCreditorPortalDocumentMutation();
  const handleDelete = async (payload) => {
    const result = await deleteDocument(payload);
    if ("data" in result) {
      message.success("Document removed successfully!");
    }
  };

  const onUploadDocumentOk = () => {
    setCreditorPortalUploadDocumentModalVisible(false);
  };

  const onUploadDocumentCancel = () => {
    setCreditorPortalUploadDocumentModalVisible(false);
  };

  const columns = [
    {
      title: "Upload Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => formatDate(record.createdAt),
      sorter: {
        compare: (a, b) => {
          const dateA = a.createdAt;
          const dateB = b.createdAt;
          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        const prettifiedText = camelToWords(snakeToCamelCase(text));
        return <div>{prettifiedText}</div>;
      },
      sorter: (a, b) => {
        return a.type.toLowerCase() > b.type.toLowerCase();
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
      render: (text, record) => {
        return record.file ? (
          <a target="_blank" href={new URL(record.file, config.apiBaseUrl).href} rel="noreferrer">
            {text}
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: "External URL",
      dataIndex: "externalUrl",
      key: "externalUrl",
      width: 200,
      render: (externalUrl) => {
        return externalUrl ? (
          <a target="_blank" href={externalUrl} rel="noreferrer">
            {externalUrl}
          </a>
        ) : null;
      },
    },
    {
      title: "Created by",
      dataIndex: "createdByName",
      key: "createdByName",
      render: (createdByName) => createdByName ?? "",
    },
    {
      title: "Data Source",
      dataIndex: "dataSource",
      render: (text, record) =>
        constants?.editableDataSourceTypes?.find((x) => x.value === record.dataSource)?.display ??
        "-",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "actions",
      key: "actions",
      hidden: false,
      render: (text, record) => {
        return record.dataSource === "creditor_portal" ? (
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            title="Are you sure you want to delete this document?"
            onConfirm={() => handleDelete({ documentId: record.id })}
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        ) : null;
      },
    },
  ].filter((column) => !column.hidden);

  return (
    <>
      <StyledCard>
        <StyledRow>
          <StyledHeader>Documents</StyledHeader>
          <StyledDocumentButton
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setCreditorPortalUploadDocumentModalVisible(true)}
          >
            Add Document
          </StyledDocumentButton>
          <StyledDivider />
        </StyledRow>

        <Table
          // @ts-ignore
          columns={columns}
          loading={isLoading}
          bordered
          scroll={{ x: "max-content" }}
          dataSource={documents}
        />
      </StyledCard>
      {isCreditorPortalUploadDocumentModalVisible && (
        <UploadCreditorDocumentModal
          title="Add Document"
          open={isCreditorPortalUploadDocumentModalVisible}
          onOk={onUploadDocumentOk}
          onCancel={onUploadDocumentCancel}
        />
      )}
    </>
  );
}

export default CreditorProfileDocumentsTable;
