import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Row, Space, Table } from "antd";
import { camelToWords } from "common/utils";
import { TruncatedText } from "components/truncatedText";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import WorkflowStateModal from "features/workflows/components/workflowStateModal";
import {
  useDeleteWorkflowStateMutation,
  useFetchWorkflowStatesQuery,
} from "features/workflows/workflowStatesAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const booleanOptionMap = {
  true: "Yes",
  false: "No",
};

const StyledTable = styled(Table)`
  width: 100%;
`;

export default function WorkflowStatesTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const { data: workflowStates, isLoading } = useFetchWorkflowStatesQuery();
  const [selectedWorkflowState, setSelectedWorkflowState] = useState(null);
  const [deleteWorkflowState] = useDeleteWorkflowStateMutation();
  const {
    data: {
      [PERMISSIONS.WORKFLOW__GET]: isWorkflowGetAuthorized,
      [PERMISSIONS.WORKFLOW__CREATE]: isWorkflowCreateAuthorized,
      [PERMISSIONS.WORKFLOW__UPDATE]: isWorkflowUpdateAuthorized,
      [PERMISSIONS.WORKFLOW__DELETE]: isWorkflowDeleteAuthorized,
    },
  } = useAuthorizations();

  const onNewRule = async () => {
    setModalVisible(false);
  };

  const workflowStateFields = [
    "isPaidInFull",
    "isSettledInFull",
    "isClosed",
    "isSuppressFromCreditReporting",
    "isDeleteFromCreditReporting",
    "isExcludeFromConsumerPortal",
  ];

  const columns = [
    { title: "Code", dataIndex: "code", key: "code" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "External Name", dataIndex: "externalName", key: "externalName" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <TruncatedText text={description} maxLength={40} />,
    },
    ...workflowStateFields.map((title) => ({
      title: camelToWords(title),
      dataIndex: title,
      key: title,
      render: (text) => (typeof text === "boolean" ? booleanOptionMap[text] : text),
    })),
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isWorkflowUpdateAuthorized && !isWorkflowDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isWorkflowUpdateAuthorized && (
            <EditOutlined
              key="edit"
              onClick={() => {
                setSelectedWorkflowState(record);
                setModalVisible(true);
              }}
            />
          )}
          {isWorkflowDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this workflow state?"
              onConfirm={() =>
                deleteWorkflowState({ workflowId: record.workflowId, stateId: record.id })
              }
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
    // @ts-ignore
  ].filter((column) => !column.hidden);

  return isWorkflowGetAuthorized ? (
    <>
      <StyledRow align="middle">
        <span>Manage account status mappings to workflow state codes.</span>
        {isWorkflowCreateAuthorized && (
          <Button
            onClick={() => {
              setSelectedWorkflowState(null);
              setModalVisible(true);
            }}
            icon={<PlusOutlined />}
            type="link"
          >
            Add Workflow State
          </Button>
        )}
      </StyledRow>
      <StyledTable
        loading={isLoading}
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={columns}
        bordered
        dataSource={workflowStates}
      />
      {isModalVisible && (
        <WorkflowStateModal
          currentWorkflowState={selectedWorkflowState}
          title="Workflow States"
          open={isModalVisible}
          onOk={onNewRule}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </>
  ) : null;
}
