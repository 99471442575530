import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
} from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { formatCurrency, parseCardExpirationDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { useUserType } from "features/auth";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useCreateCreditorPortalLoggingPaymentMethodMutation } from "features/payments/creditorPortal/paymentsAPI";
import {
  useCalculateConvenienceFeesMutation,
  useCreateAchPaymentMethodMutation,
  useCreateCardPaymentMethodMutation,
  useCreateLoggingPaymentMethodMutation,
} from "features/payments/paymentsAPI";
import { useFetchDebtorProfileQuery } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useFetchCreditorPortalDebtorProfileQuery } from "features/accountsTable/creditorPortal/accountsTableAPI";
import { useFetchDebtorAddressesQuery } from "features/debtorSider/agencyPortal/debtorSiderAPI";
import { selectPaymentsSlice } from "features/payments/paymentsSlice";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import currency from "currency.js";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { DATE_FORMAT } from "common/constants";

const StyledInput = styled(Input)`
  border-bottom: 1px solid grey;
  width: 200px;
  border-radius: 0px;
  &:hover,
  &:focus {
    border-bottom: 1px solid grey;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-left: 4px;
  margin-right: 4px;
`;

const StyledDatePicker = styled(AktDatePicker)`
  &.ant-picker {
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid grey;
  }
`;

const StyledFormItemContainer = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledSelect = styled(Select)`
  border-bottom: 1px solid grey;
  & .ant-select-selector {
    margin-bottom: -2px;
  }
  &:hover,
  &:focus {
    border-bottom: 1px solid grey;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

function CreatePaymentMethodForm({ submitButtonText, confirmPaymentLoading, onOk, onCancel }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { debtorId } = useParams();
  const [currentConvenienceFee, setCurrentConvenienceFee] = useState(null);
  const { data: isCanWaiveConvenienceFeeAuthorized } = useAuthorization(
    PERMISSIONS.PAYMENT__WAIVE_CONVENIENCE_FEES,
  );

  const [form] = Form.useForm();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [fetchConvenienceFees] = useCalculateConvenienceFeesMutation();
  const { data: agencyDebtorPersonalInfo, isLoading: isDebtorPersonalInfoLoading } =
    useFetchDebtorProfileQuery({ debtorId }, { skip: !isAgencyUserType });

  const { data: debtorAddresses, isLoading: isDebtorAddressLoading } = useFetchDebtorAddressesQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const debtorAddress =
    debtorAddresses?.find((address) => address.isPrimary) ?? debtorAddresses?.[0];

  const { data: creditorPortalDebtorPersonalInfo } = useFetchCreditorPortalDebtorProfileQuery(
    { debtorId },
    { skip: isAgencyUserType },
  );
  const debtorPersonalInfo = isAgencyUserType
    ? agencyDebtorPersonalInfo
    : creditorPortalDebtorPersonalInfo;

  const [createAchPaymentMethod, { isLoading: isLoadingCreateAch }] =
    useCreateAchPaymentMethodMutation();
  const [createCardPaymentMethod, { isLoading: isLoadingCreateCard }] =
    useCreateCardPaymentMethodMutation();
  const [createAgencyLoggingPaymentMethod, { isLoading: isLoadingAgencyCreateLogging }] =
    useCreateLoggingPaymentMethodMutation();
  const [
    createCreditorPortalLoggingPaymentMethod,
    { isLoading: isLoadingCreditorPortalCreateLogging },
  ] = useCreateCreditorPortalLoggingPaymentMethodMutation();
  const [createLoggingPaymentMethod, isLoadingCreateLogging] = isAgencyUserType
    ? [createAgencyLoggingPaymentMethod, isLoadingAgencyCreateLogging]
    : [createCreditorPortalLoggingPaymentMethod, isLoadingCreditorPortalCreateLogging];

  const accountTypeOptions = [
    { value: "checking", label: "Checking" },
    { value: "savings", label: "Savings" },
  ];

  const { data: constants } = useFetchBackendConstantsQuery();

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const { data: me } = useFetchMeQuery();
  const defaults = useMemo(() => {
    const paymentMethodOptions = [];
    let initialPaymentMethodOption = me?.enabledPaymentMethods?.defaultPaymentMethod;
    let processPaymentOptions = me?.enabledPaymentMethods?.enabledProcessedPaymentOptions || [];
    let loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    if (paymentsSlice.paidTo === "creditor") {
      loggingPaymentOptions = isCreditorUserType
        ? me?.enabledPaymentMethods?.enabledPaidToCreditorPortalPaymentOptions
        : me?.enabledPaymentMethods?.enabledPaidToCreditorPaymentOptions;
      processPaymentOptions = []; // These payments were already paid to the client/creditor, so we don't need to process them again.
      initialPaymentMethodOption = "paid_to_creditor";
    } else if (paymentsSlice.paidTo === "forwarding_entity") {
      loggingPaymentOptions =
        me?.enabledPaymentMethods?.enabledPaidToForwardingEntityPaymentOptions;
      processPaymentOptions = []; // These payments were already paid to a forwarding entity, so we don't need to process them again.
      initialPaymentMethodOption = "forwarding_entity";
    } else if (paymentsSlice.isPaymentPlan) {
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    } else if (moment(paymentsSlice.paymentsSchedule[0].scheduledDate).unix() > moment().unix()) {
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    } else {
      // NOTE: This case should only happen when the payment is scheduled for today.
      // Right now they are unable to schedule payments in the past (see shouldDisableDate). This was intentionally
      // asked by CBY. We may choose to add permissioning around this in the future, so leaving this here.
      loggingPaymentOptions = me?.enabledPaymentMethods?.enabledPaidToAgencyLoggedPaymentOptions;
    }

    // Categorize payment methods into process and logging options.
    // e.g
    // paymentMethodOptions = [
    //   {
    //     label: "Process options",
    //     options: [
    //       { value: "process_card", label: "Card" },
    //       { value: "process_ach", label: "ACH" },
    //     ],
    //   },
    //   {
    //     label: "Logging options",
    //     options: [
    //       { value: "card", label: "Card" },
    //       { value: "ach", label: "ACH" },
    //       { value: "check", label: "Check" },
    //       { value: "cash", label: "Cash" },
    //       { value: "bankruptcy", label: "Bankruptcy Payment" },
    //       { value: "online_portal", label: "Online Portal" },
    //       { value: "legal", label: "Legal" },
    //       { value: "other", label: "Other" },
    //     ],
    //   },
    // ];
    if (processPaymentOptions.length > 0) {
      paymentMethodOptions.push({
        label: "Process options",
        options: processPaymentOptions,
      });
    }
    if (loggingPaymentOptions.length > 0) {
      paymentMethodOptions.push({
        label: "Logging options",
        options: loggingPaymentOptions,
      });
    }

    return { paymentMethodOptions, initialPaymentMethodOption };
  }, [
    paymentsSlice.paidTo,
    paymentsSlice.isPaymentPlan,
    paymentsSlice.paymentsSchedule,
    isCreditorUserType,
    me?.enabledPaymentMethods,
  ]);

  const initialValues = {
    paymentMethodType: defaults.initialPaymentMethodOption,
    firstName: debtorPersonalInfo?.firstName,
    lastName: debtorPersonalInfo?.lastName,
    address1: debtorAddress?.address1,
    address2: debtorAddress?.address2,
    city: debtorAddress?.city,
    state: debtorAddress?.state,
    zipCode: debtorAddress?.zipCode,
    emails: debtorPersonalInfo?.email,
    waiveConvenienceFees: false,
  };

  useEffect(() => {
    (async () => {
      const result = await fetchConvenienceFees({
        accountIds: paymentsSlice.selectedAccounts,
        amounts: [paymentsSlice.paymentsSchedule[0]?.totalAmount],
        state: form.getFieldValue("state"),
        paymentMethodType: form.getFieldValue("paymentMethodType"),
      });
      if ("data" in result) {
        setCurrentConvenienceFee(result.data.convenienceFees[0]);
      }
    })();
  }, [
    fetchConvenienceFees,
    paymentsSlice.selectedAccounts,
    form,
    paymentsSlice.totalAmount,
    paymentsSlice.paymentsSchedule,
    paymentsSlice.isPaymentPlan,
  ]);

  const onBillingStateChange = async (value) => {
    const result = await fetchConvenienceFees({
      accountIds: paymentsSlice.selectedAccounts,
      amounts: [paymentsSlice.totalAmount],
      state: form.getFieldValue("state"),
      paymentMethodType: form.getFieldValue("paymentMethodType"),
    });
    if ("data" in result) {
      setCurrentConvenienceFee(result.data.convenienceFees[0]);
    }
  };

  const onPaymentMethodChange = async (value) => {
    if (value === "process_card" || value === "process_ach") {
      const result = await fetchConvenienceFees({
        accountIds: paymentsSlice.selectedAccounts,
        amounts: [paymentsSlice.totalAmount],
        state: form.getFieldValue("state"),
        paymentMethodType: form.getFieldValue("paymentMethodType"),
      });
      if ("data" in result) {
        setCurrentConvenienceFee(result.data.convenienceFees[0]);
      }
    } else {
      setCurrentConvenienceFee({ message: null, fee: 0, amount: 0 });
    }
  };

  const onCreateNewPaymentMethodFinish = async (values) => {
    const {
      waiveConvenienceFees,
      paymentMethodType,
      emails,
      referenceNumber,
      promiseToPayDate,
      promiseToPayLastDate,
    } = values;
    const emailsArray = emails ? emails.split(",").map((email) => email.trim()) : [];

    const action = {
      process_card: () => {
        const { expirationDate } = values;
        const [cardExpMonth, cardExpYear] = parseCardExpirationDate(expirationDate);
        return createCardPaymentMethod({
          ...values,
          applyConvenienceFees: !waiveConvenienceFees,
          debtorId,
          cardExpMonth,
          cardExpYear,
          emails: emailsArray,
          // TODO make this configurable via agency settings
          paymentProvider: "repay",
        });
      },
      process_ach: () =>
        createAchPaymentMethod({
          ...values,
          applyConvenienceFees: !waiveConvenienceFees,
          debtorId,
          emails: emailsArray,
          // TODO make this configurable via agency settings
          paymentProvider: "repay",
        }),
      card: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      ach: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      check: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      cash: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      other: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      online_portal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      paid_to_creditor: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      paid_to_creditor_legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      // HACK: For now, we're just going to always create cash payment methods
      // Later, we will refactor this to either use the correct endpoint,
      // or retrieve the dynamic list of logging payment method options.
      forwarding_entity: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      forwarding_entity_legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      cashiers_check: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      money_order: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      third_party: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      bankruptcy: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      legal: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
      promise_to_pay: () =>
        createLoggingPaymentMethod({
          paymentMethodType,
          debtorId,
        }),
    }[paymentMethodType];

    if (currency(currentConvenienceFee?.fee).value > 0 && !values.waiveConvenienceFees) {
      Modal.info({
        title: "Convenience Fee Disclosure",
        content: (
          <>
            <p>
              Attention:{" "}
              {currentConvenienceFee?.message ||
                "You are authorizing the payment service provider to charge your payment method for the principal amount and a convenience fee. For ACH payments, this will appear on your statement as two separate transactions. For card payments, this will appear as one transaction. You understand that you are paying a fee for the convenience of making this payment by phone and that you can avoid this fee by mailing a check or money order to our office."}
            </p>
            <strong>Convenience Fee: {formatCurrency(currentConvenienceFee?.fee)}</strong>
          </>
        ),
        onOk: async () => {
          const result = await action();
          if ("data" in result) {
            return onOk(
              result.data.id,
              referenceNumber,
              promiseToPayDate,
              promiseToPayLastDate,
              waiveConvenienceFees,
            );
          }
          if ("error" in result) {
            form.setFields(formatError(result.error));
            message.error("Failed to process the payment. Please refresh and try again.");
          }
        },
        okText: "Yes, I understand. Proceed with payment",
        okButtonProps: {
          block: true,
        },
        maskClosable: false,
        closable: true,
      });
    } else {
      const result = await action();
      if ("data" in result) {
        return onOk(
          result.data.id,
          referenceNumber,
          promiseToPayDate,
          promiseToPayLastDate,
          waiveConvenienceFees,
        );
      }
      if ("error" in result) {
        form.setFields(formatError(result.error));
        message.error("Failed to process the payment. Please refresh and try again.");
      }
    }
  };

  const billingAddressFormFields = (
    <>
      <h3>Billing Details</h3>
      <StyledFormItemContainer>
        <Row>
          <StyledFormItem label="First Name" name="firstName" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="First Name" />
          </StyledFormItem>
          <StyledFormItem label="Last Name" name="lastName" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="Last Name" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <Row>
          <StyledFormItem label="Address1" name="address1" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="Address 1" />
          </StyledFormItem>
          <StyledFormItem label="Address2" name="address2">
            <StyledInput bordered={false} maxLength={100} placeholder="Address 2" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <Row wrap={false}>
          <StyledFormItem label="City" name="city" rules={[{ required: true }]}>
            <StyledInput bordered={false} maxLength={100} placeholder="City" />
          </StyledFormItem>
          <StyledFormItem label="State" name="state" rules={[{ required: true }]}>
            <StyledSelect
              popupMatchSelectWidth={false}
              bordered={false}
              placeholder="State"
              options={constants?.states.map(({ display, value }) => ({
                value,
                label: display,
              }))}
              filterOption={filterOption}
              onChange={onBillingStateChange}
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            name="zipCode"
            label="Zip Code"
            rules={[
              {
                required: true,
              },
              {
                pattern: /^\d{5}(-\d{4})?$/,
                message: "Must be a valid zip code",
              },
            ]}
          >
            <StyledInput bordered={false} maxLength={5} placeholder="Zip Code" />
          </StyledFormItem>
        </Row>
      </StyledFormItemContainer>
      <StyledFormItemContainer>
        <StyledFormItem label="Email(s)" name="emails">
          <StyledInput
            bordered={false}
            style={{ width: 250 }}
            maxLength={100}
            placeholder="Emails (comma separated)"
          />
        </StyledFormItem>
      </StyledFormItemContainer>
    </>
  );

  const getButtonText = (applyConvenienceFees) => {
    // For one-time payments
    if (!paymentsSlice.isPaymentPlan) {
      const amount = currency(paymentsSlice.totalAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(amount.add(currentConvenienceFee?.fee))})`;
      }
      return `Confirm Payment (${formatCurrency(amount)})`;
    }

    // For payment planss with down payments
    if (paymentsSlice.downPaymentAmount > 0) {
      // if there's a fee, we need to add it to the down payment
      const totalAmount = currency(paymentsSlice.downPaymentAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(
          totalAmount.add(currency(currentConvenienceFee?.fee)),
        )})`;
      }

      return `Confirm Payment (${formatCurrency(totalAmount)})`;
    }

    // For payment plans without a down payment (no payment today), it doesn't matter to show if there's a fee or not
    // unless there's a payment today.
    if (paymentsSlice.paymentsSchedule[0].scheduledDate === moment().format(DATE_FORMAT)) {
      const totalAmount = currency(paymentsSlice.paymentsSchedule[0].totalAmount);
      if (applyConvenienceFees) {
        return `Confirm Payment (${formatCurrency(
          currency(totalAmount).add(currency(currentConvenienceFee?.fee)),
        )})`;
      }
      return `Confirm Payment (${formatCurrency(totalAmount)})`;
    }

    return "Setup Payment Plan";
  };

  return isDebtorPersonalInfoLoading || isDebtorAddressLoading ? (
    <Skeleton active />
  ) : (
    <StyledForm
      form={form}
      layout="vertical"
      validateMessages={{ required: "This is a required field" }}
      initialValues={initialValues}
      onFinish={onCreateNewPaymentMethodFinish}
    >
      <Form.Item name="paymentMethodType" label="New Payment Method:">
        <Select onChange={onPaymentMethodChange} options={defaults.paymentMethodOptions} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.paymentMethodType !== currentValues.paymentMethodType
        }
      >
        {({ getFieldValue }) => {
          switch (getFieldValue("paymentMethodType")) {
            case "process_card":
              return (
                <>
                  <h3>Card Information</h3>
                  <StyledFormItem
                    name="cardNumber"
                    label="Card Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <StyledInput bordered={false} maxLength={16} placeholder="Card Number" />
                  </StyledFormItem>
                  <StyledFormItem rules={[{ required: true }]}>
                    <Input.Group compact>
                      <Form.Item
                        label="Expiration Date"
                        name="expirationDate"
                        rules={[{ required: true }]}
                      >
                        <StyledDatePicker placeholder="MM/YY" format="MM/YY" />
                      </Form.Item>
                      <StyledFormItem
                        label="CVV"
                        name="cvv"
                        rules={[
                          {
                            pattern: /^[0-9]{3,4}$/,
                            message: "Please enter a valid CVV.",
                          },
                        ]}
                      >
                        <StyledInput bordered={false} placeholder="CVV" maxLength={4} />
                      </StyledFormItem>
                    </Input.Group>
                  </StyledFormItem>
                  {billingAddressFormFields}
                </>
              );
            case "process_ach":
              return (
                <>
                  <Form.Item
                    label="Bank Account Name"
                    name="bankAccountName"
                    rules={[
                      { required: true },
                      {
                        type: "string",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="bankRoutingNumber"
                    label="Routing Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid routing number.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter routing number..."
                      style={{ width: 200 }}
                      maxLength={9}
                    />
                  </Form.Item>
                  <Form.Item
                    name="bankAccountNumber"
                    label="Account Number"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter a valid account number.",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter account number..."
                      style={{ width: 200 }}
                      maxLength={17}
                      controls={false}
                    />
                  </Form.Item>
                  <Form.Item name="bankAccountType" label="Account Type" initialValue="checking">
                    <Select options={accountTypeOptions} />
                  </Form.Item>
                  <Form.Item label="Description" name="description">
                    <Input />
                  </Form.Item>
                  {billingAddressFormFields}
                </>
              );
            case "check":
            case "money_order":
            case "cashiers_check":
              return (
                <Form.Item
                  label="Reference Number"
                  name="referenceNumber"
                  rules={[
                    { required: false },
                    {
                      type: "string",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.paymentMethodType !== currentValues.paymentMethodType
        }
      >
        {({ getFieldValue }) => {
          const isProcessPayment =
            getFieldValue("paymentMethodType") === "process_ach" ||
            getFieldValue("paymentMethodType") === "process_card";
          return (
            <>
              {/* Show the convenience fee waiver checkbox if process payment method */}
              {isProcessPayment &&
                (isCanWaiveConvenienceFeeAuthorized ? (
                  <Form.Item valuePropName="checked" name="waiveConvenienceFees" label="">
                    <Checkbox>
                      Waive convenience fee of {formatCurrency(currentConvenienceFee?.fee)}
                    </Checkbox>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <i>
                      A convenience fee of {formatCurrency(currentConvenienceFee?.fee)} will be
                      applied.
                    </i>
                  </Form.Item>
                ))}
            </>
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.waiveConvenienceFees !== currentValues.waiveConvenienceFees
        }
      >
        {({ getFieldValue }) => {
          const applyConvenienceFees = !getFieldValue("waiveConvenienceFees");

          return (
            <Form.Item>
              <Button onClick={onCancel}>Back</Button>
              <Button
                loading={
                  isLoadingCreateAch ||
                  isLoadingCreateCard ||
                  isLoadingCreateLogging ||
                  confirmPaymentLoading
                }
                style={{ marginLeft: 8 }}
                type="primary"
                htmlType="submit"
              >
                {getButtonText(applyConvenienceFees)}
              </Button>
            </Form.Item>
          );
        }}
      </Form.Item>
    </StyledForm>
  );
}

export default CreatePaymentMethodForm;
