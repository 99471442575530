import { Table } from "antd";
import { getColumnSearchProps } from "common/antd-utils";
import { DATE_TIME_FORMAT } from "common/constants";
import {
  useFetchDebtorAccountsQuery,
  useFetchDebtorSystemLogsQuery,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import moment from "moment-timezone";
import { useMemo, useRef } from "react";
import styled from "styled-components";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import { useUserType } from "features/auth";

const StyledSpan = styled.span`
  white-space: pre-wrap;
`;

const StyledDiv = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 400px;
`;

const StyledTable = styled(Table)`
  & .ant-table-cell:not(th) {
    padding: 4px 8px !important;
  }
`;

export default function SystemsLogTable({ debtorId }) {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { data: agencyDebtorAccounts } = useFetchDebtorAccountsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorDebtorAccounts } = useFetchCreditorPortalDebtorAccountsQuery(
    { debtorId },
    {
      skip: !isCreditorUserType,
    },
  );

  const debtorAccounts = isAgencyUserType ? agencyDebtorAccounts : creditorDebtorAccounts;
  const debtorAccountIds = useMemo(() => {
    return new Set(debtorAccounts?.map((account) => account.id));
  }, [debtorAccounts]);
  const { data: systemLogs, isLoading } = useFetchDebtorSystemLogsQuery({ debtorId });
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: "Timestamp",
      key: "createdAt",
      dataIndex: "createdAt",
      // TODO: Determine how we want to handle timezones
      // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
      // an application may want to use UTC and make that visible
      //   const options = { timeZone: "UTC", timeZoneName: "short" };
      //   console.log(date.toLocaleTimeString("en-US", options));
      // → "3:00:00 AM GMT"
      render: (text, _) => moment(text).format(DATE_TIME_FORMAT),
      sorter: {
        compare: (a, b) => {
          if (a.createdAt < b.createdAt) {
            return -1;
          }
          if (a.createdAt > b.createdAt) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      title: "Actor",
      key: "userEmail",
      dataIndex: "userEmail",
      ...getColumnSearchProps("userEmail", searchInput, handleSearch, handleReset),
    },
    {
      title: "Accounts Affected",
      key: "accountIds",
      dataIndex: "accountIds",
      ...getColumnSearchProps("accountIds", searchInput, handleSearch, handleReset),
      render: (accountIds) => {
        if (accountIds.length === 0) {
          return "ALL";
        }
        // Filters out accountIds that do not belong to the debtor. This is necessary for the client portal, where
        // system logs may reference accounts associated with both the current creditor and other unrelated creditors.
        // The logic first retrieves the debtor's visible accounts (whether on the agency portal or creditor portal)
        // and then excludes any accountId that are not part of the debtor's accounts.
        const filteredAccountIds = accountIds.filter((accountId) =>
          debtorAccountIds.has(accountId),
        );
        return filteredAccountIds.map((accountId) => {
          return <div key={accountId}>Account {accountId}</div>;
        });
      },
    },
    {
      title: "Event",
      key: "eventType",
      dataIndex: "eventType",
      render: (event) => {
        return event.display;
      },
    },
    {
      title: "Detail",
      key: "message",
      dataIndex: "message",
      ...getColumnSearchProps("message", searchInput, handleSearch, handleReset),
      render: (message, record) => {
        // This is wrapped in a StyledSpan tag to allow for line breaks
        if (
          record.eventType.value === "legacy_payment_history" ||
          record.eventType.value === "communication"
        ) {
          return <StyledSpan>{message}</StyledSpan>;
        }
        return <StyledDiv>{message}</StyledDiv>;
      },
    },
  ];

  return (
    <StyledTable
      loading={isLoading}
      bordered
      scroll={{ x: "max-content" }}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30, 100],
      }}
      // @ts-ignore
      columns={columns}
      dataSource={systemLogs}
    />
  );
}
