import { Modal, message } from "antd";
import { extractNonEmptyFormEntries } from "common/utils";
import { FormWizard } from "components/formWizard";
import {
  useCreateBulkActionMutation,
  useLazyFetchDebtorsQuery,
} from "features/bulkActions/bulkActionsAPI";
import { splitRangeDatePickerValue } from "features/bulkActions/utils";
import { DebtorsFiltersStep } from "features/bulkActions/wizardSteps/debtorFiltersStep";
import DebtorOperationsStep from "features/bulkActions/wizardSteps/debtorOperationsStep";
import DebtorsPreviewStep from "features/bulkActions/wizardSteps/debtorsPreviewStep";
import FinishStep from "features/bulkActions/wizardSteps/finishStep";
import moment from "moment-timezone";
import { useState } from "react";

function preTransformFilters(values) {
  if (values?.filters?.lastCallDate) {
    [values.filters.lastCallDateStart, values.filters.lastCallDateEnd] = splitRangeDatePickerValue(
      values.filters.lastCallDate,
    );
  }
  if (values?.filters?.excludeLastCallDate) {
    [values.filters.excludeLastCallDateStart, values.filters.excludeLastCallDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeLastCallDate);
  }
  if (values?.filters?.lastPaymentDate) {
    [values.filters.lastPaymentDateStart, values.filters.lastPaymentDateEnd] =
      splitRangeDatePickerValue(values.filters.lastPaymentDate);
  }
  if (values?.filters?.excludeLastPaymentDate) {
    [values.filters.excludeLastPaymentDateStart, values.filters.excludeLastPaymentDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeLastPaymentDate);
  }

  if (values?.filters?.turnoverDate) {
    [values.filters.turnoverDateStart, values.filters.turnoverDateEnd] = splitRangeDatePickerValue(
      values.filters.turnoverDate,
    );
  }
  if (values?.filters?.excludeTurnoverDate) {
    [values.filters.excludeTurnoverDateStart, values.filters.excludeTurnoverDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeTurnoverDate);
  }

  if (values?.filters?.followUpDate) {
    [values.filters.followUpDateStart, values.filters.followUpDateEnd] = splitRangeDatePickerValue(
      values.filters.followUpDate,
    );
  }
  if (values?.filters?.excludeFollowUpDate) {
    [values.filters.excludeFollowUpDateStart, values.filters.excludeFollowUpDateEnd] =
      splitRangeDatePickerValue(values.filters.excludeFollowUpDate);
  }

  return values;
}

function DebtorBulkActionsModal({ title, open, onOk, onCancel }) {
  const [hideParentModal, setHideParentModal] = useState(false);
  const [createBulkAction, { isLoading }] = useCreateBulkActionMutation();
  const [fetchDebtors, { data: debtors }] = useLazyFetchDebtorsQuery();

  const onFiltersDone = async (values) => {
    const hasAnyValueSet = Object.values(extractNonEmptyFormEntries(values.filters)).length > 0;

    if (!hasAnyValueSet) {
      return { error: { message: "Please select at least one filter." } };
    }

    // Split date range values into start and end dates
    const transformedValues = preTransformFilters(values);
    return fetchDebtors({
      ...transformedValues,
      includeResultCount: true, // Include the results count in the response
    });
  };

  const onOperationsDone = async (values, { step0 }) => {
    const hasAnyValueSet = Object.values(extractNonEmptyFormEntries(values.operations)).length > 0;

    if (!hasAnyValueSet) {
      return { error: { message: "Please select at least one operation." } };
    }

    // Validate operationType
    if (!values.operations.operationType) {
      return { error: { message: "Please select an operation." } };
    }

    // Validate form by 'operationType'
    switch (values.operations.operationType) {
      case "sendText":
        if (!values.operations.sendText?.communicationMethodId) {
          return { error: { message: "Please configure campaign" } };
        }
        break;
      case "sendVoicemail":
        if (!values.operations.sendVoicemail?.communicationMethodId) {
          return { error: { message: "Please configure campaign" } };
        }
        break;
      case "sendDialerCampaign":
        if (!values.operations.sendDialerCampaign.phoneConfigs) {
          return { error: { message: "Please configure dialer campaign" } };
        }
        break;
      case "sendEmailTemplate":
        // TODO: Implement email template configuration
        // if (!values.operations.sendEmailTemplate?.templateId) {
        //   return { error: { message: "Please configure email template" } };
        // }
        break;
      case "skipTrace":
        if (!values.operations.skipTrace?.providerConfig) {
          return { error: { message: "Please configure skip tracing" } };
        }
        break;
      default:
        break;
    }

    let payload = {
      ...(await step0.validateFields()),
      ...values,
    };

    // Split date range values into start and end dates
    payload = preTransformFilters(payload);

    // Remove entries that contain all empty values
    payload.operations = extractNonEmptyFormEntries(payload.operations);

    const result = await createBulkAction(payload);
    if ("data" in result) {
      message.success(`Bulk action job created successfully!`);
    }
    if ("error" in result) {
      message.error("Failed to create bulk action job.");
    }
    return result;
  };

  const onDone = async () => {
    onOk();
  };

  const steps = [
    /*
    {
      title: "Job Settings",
      form: <JobSettingsStep />,
      onNext: async (data) => onJobSettingsDone(data),
    },
    */
    {
      title: "Debtors",
      form: <DebtorsFiltersStep />,
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
      onNext: async (data) => onFiltersDone(data),
    },
    {
      title: "Preview",
      form: (
        <DebtorsPreviewStep
          previewDebtors={debtors?.results ?? []}
          previewDebtorsTotalCount={debtors?.meta?.resultCount ?? 0}
        />
      ),
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
    {
      title: "Operations",
      form: <DebtorOperationsStep setHideParentModal={setHideParentModal} />,
      wrapperProps: {
        style: {
          width: "100%",
          maxWidth: "100%",
        },
      },
      onNext: onOperationsDone,
    },
    {
      title: "Finish",
      form: <FinishStep />,
      wrapperProps: {
        style: {
          margin: "0 auto",
        },
      },
      previousButtonProps: {
        style: {
          display: "none",
        },
      },
    },
    // {
    //   title: "Select Date",
    //   subTitle: "(Recurring Schedule Coming Soon)",
    //   form: (
    //     <StyledCard>
    //       <StyledHeader>Select a date and time to schedule the job:</StyledHeader>
    //       <Form.Item
    //         rules={[{ required: true, message: "This field is required." }]}
    //         name="oneOffScheduledAt"
    //       >
    //         <DatePicker disabledDate={disabledDate} showSecond={false} showTime />
    //       </Form.Item>
    //     </StyledCard>
    //   ),
    // },
    // {
    //   title: "Job Identifier",
    //   form: (
    //     <Form.Item rules={[{ required: true, message: "This field is required." }]} name="name">
    //       <StyledCard>
    //         <StyledHeader>Enter job name:</StyledHeader>
    //         <Input placeholder="Enter name..." />
    //       </StyledCard>
    //     </Form.Item>
    //   ),
    // },
  ];

  const initialValues = {
    type: "debtor",
    cadence: "one-off",
    schedule: "asap",
    every: {
      time: moment().format("hh:mm A"),
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open && !hideParentModal}
      onCancel={onCancel}
      confirmLoading={isLoading}
      footer={null}
      width={800}
    >
      <FormWizard steps={steps} onCancel={onCancel} onDone={onDone} initialValues={initialValues} />
    </Modal>
  );
}

export default DebtorBulkActionsModal;
